//@flow
/* eslint-disable prefer-destructuring */
import {
  LOAD_ONE,
  UPDATE_ONE,
} from 'Store/Constants/generic';
import {
  EMPLOYEES_LOCK,
  EMPLOYEES_SUSPEND,
} from 'Store/Constants/employees';
import {
  LOAN_MANAGEMENT_ACT,
  LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE,
} from 'Store/Constants/loanManagement';
import { DEPOSIT_MANAGEMENT_ACT } from 'Store/Constants/depositManagement';
import {
  DEPOSITPRODUCTMANAGEMENT_EXPIRE_PRODUCT,
  DEPOSITPRODUCTMANAGEMENT_SUSPEND_PRODUCT,
  DEPOSITPRODUCTMANAGEMENT_LAUNCH_PRODUCT,
} from 'Store/Constants/depositProducts';
import {
  LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT,
  LOANPRODUCTMANAGEMENT_SUSPEND_PRODUCT,
  LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT,
} from 'Store/Constants/loanProducts';
import { INBOUND_PAYMENT_TRANSACTIONS_ACT } from 'Store/Constants/inboundPaymentTransactions';
import { OUTBOUND_PAYMENT_TRANSACTIONS_ACT } from 'Store/Constants/outboundPaymentTransactions';
import {
  PERSON_MANAGEMENT_UPDATE_FLAG,
  PERSON_MANAGEMENT_CLEAR_CACHE,
  PERSON_MANAGEMENT_UPLOAD_FILE,
  PERSON_MANAGEMENT_UPDATE_ADDRESS,
} from 'Store/Constants/personManagement';
import {
  FEESCONFIGURATION_SUSPEND,
  FEESCONFIGURATION_UNSUSPEND,
} from 'Store/Constants/feeBuilder';
import {
  TESTOUTBOUNDPAYMENT_APPROVE,
  TESTOUTBOUNDPAYMENT_CANCEL,
} from 'Store/Constants/testOutboundPayment';
import {
  IDENTITY_MANAGEMENT_BLOCK_IDENTITY,
  IDENTITY_MANAGEMENT_UNBLOCK_IDENTITY,
  IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY,
  IDENTITY_MANAGEMENT_LINK_CANDIDATE_TO_IDENTITY,
  IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL,
} from 'Store/Constants/identityManagement';
import {
  API_KEY_MANAGEMENT_CREATE_KEY,
  API_KEY_MANAGEMENT_REVOKE_KEY,
} from 'Store/Constants/apiKeyManagement';

import {
  SUCCESS,
  FAIL,
} from 'Store/Constants/axios';
//$FlowFixMe
import { rolesList } from 'GlobalConfigs';

import {
  loadOne,
  loadAll,
  loadFormData,
} from 'Store/Actions/generic';
import {
  enquireEvent as enquireLM,
  loadSelectedLoanSummary,
  loadSelectedLoanLogs,
  loadSelectedLoanEvents,
  loadSelectedLoanVersions,
  loadSelectedLoanCashflows,
  loadSelectedLoanMandates,
  loadSelectedLoanFlags,
} from 'Store/Actions/loanManagement';
import {
  enquireEvent as enquireDM,
  loadSelectedDepositEvents,
  loadSelectedDepositBalances,
  loadSelectedDepositTransactions,
  loadSelectedDepositRates,
  loadSelectedDepositLimits,
  loadSelectedDepositLogs,
} from 'Store/Actions/depositManagement';

import {
  loadPersonCache,
  findLegalDocument,
  loadSelectedPersonFlags,
  searchIdentityLinkCandidates,
  setChosenIdentityLinkCandidate,
} from 'Store/Actions/personManagement';

import { loadIdentities } from 'Store/Actions/identityManagement';

import { searchTransactions as searchOutboundTransactions } from 'Store/Actions/outboundPaymentTransactions';
import { searchTransactions as searchInboundTransactions } from 'Store/Actions/inboundPaymentTransactions';

import {
  loadActiveKeys,
  loadRevokedKeys,
} from 'Store/Actions/apiKeyManagement';

type Action = {
  type: string,
  payload: {
    data: {
      submit_to: string,
      task_status: string,
      state: string,
    },
  },
  meta: {
    previousAction: {
      payload: {
        redirectTo: string,
        requireFullRefresh: boolean,
        requireFormRefresh: boolean,
        employeeId: number,
        personId: number,
        loanId: number,
        depositId: number,
        itemId: number,
        depositProductId: number,
        loanProductId: number,
        eventCode: string,
        actionedTransactions: Array<string>,
        currentLiveVersion: number,
      },
    },
  },
};

export default (store: Object) => (next: Function) => (action: Action) => {
  switch (action.type) {
    // TODO: remove whenever server returns normal data
    case EMPLOYEES_SUSPEND + SUCCESS:
    case EMPLOYEES_LOCK + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        store.dispatch(loadOne(rolesList.EMPLOYEEMANAGEMENT.systemRoleName, action.meta.previousAction.payload.employeeId));
      }
      return next(action);

    case DEPOSITPRODUCTMANAGEMENT_EXPIRE_PRODUCT + SUCCESS:
    case DEPOSITPRODUCTMANAGEMENT_SUSPEND_PRODUCT + SUCCESS:
    case DEPOSITPRODUCTMANAGEMENT_LAUNCH_PRODUCT + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        store.dispatch(loadOne(rolesList.DEPOSITPRODUCTMANAGEMENT.systemRoleName, action.meta.previousAction.payload.depositProductId));
      }
      return next(action);

    case LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT + SUCCESS:
    case LOANPRODUCTMANAGEMENT_SUSPEND_PRODUCT + SUCCESS:
    case LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        store.dispatch(loadOne(rolesList.LOANPRODUCTMANAGEMENT.systemRoleName, action.meta.previousAction.payload.loanProductId));
      }
      return next(action);

    case LOAN_MANAGEMENT_ACT + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        if (action.meta.previousAction.payload.requireFullRefresh) {
          // Get fresh data
          store.dispatch(loadSelectedLoanSummary(action.meta.previousAction.payload.loanId));
          store.dispatch(loadSelectedLoanLogs(action.meta.previousAction.payload.loanId));
          store.dispatch(loadSelectedLoanEvents(action.meta.previousAction.payload.loanId));
          store.dispatch(loadSelectedLoanVersions(action.meta.previousAction.payload.loanId));
          store.dispatch(loadSelectedLoanCashflows(action.meta.previousAction.payload.loanId));
          store.dispatch(loadSelectedLoanFlags(action.meta.previousAction.payload.loanId));
        } else {
          // Get fresh events data
          store.dispatch(loadSelectedLoanEvents(action.meta.previousAction.payload.loanId));
        }

        if (action.meta.previousAction.payload.requireFormRefresh) {
          // reload event form
          store.dispatch(enquireLM(action.meta.previousAction.payload.loanId, action.meta.previousAction.payload.eventCode));
        }
      }
      return next(action);

    case DEPOSIT_MANAGEMENT_ACT + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        if (action.meta.previousAction.payload.requireFullRefresh) {
          // get fresh fruit
          store.dispatch(loadSelectedDepositEvents(action.meta.previousAction.payload.depositId));
          store.dispatch(loadSelectedDepositBalances(action.meta.previousAction.payload.depositId));
          store.dispatch(loadSelectedDepositTransactions(action.meta.previousAction.payload.depositId));
          store.dispatch(loadSelectedDepositRates(action.meta.previousAction.payload.depositId));
          store.dispatch(loadSelectedDepositLimits(action.meta.previousAction.payload.depositId));
          store.dispatch(loadSelectedDepositLogs(action.meta.previousAction.payload.depositId));
          // and cheese twists
          store.dispatch(enquireDM(action.meta.previousAction.payload.depositId, action.meta.previousAction.payload.eventCode));
        } else {
          // get fresh fruit
          store.dispatch(loadSelectedDepositEvents(action.meta.previousAction.payload.depositId));
          // and cheese twists
          store.dispatch(enquireDM(action.meta.previousAction.payload.depositId, action.meta.previousAction.payload.eventCode));
        }
      }
      return next(action);

    case INBOUND_PAYMENT_TRANSACTIONS_ACT + SUCCESS:
      if (action.payload && action.payload.data) {
        const search = {
          inbound_payment_transaction_ids: action.meta.previousAction.payload.actionedTransactions.join(','),
        };
        store.dispatch(searchInboundTransactions(search, 0));
      }
      return next(action);

    case OUTBOUND_PAYMENT_TRANSACTIONS_ACT + SUCCESS:
      if (action.payload && action.payload.data) {
        const search = {
          outbound_payment_transaction_ids: action.meta.previousAction.payload.actionedTransactions.join(','),
        };
        store.dispatch(searchOutboundTransactions(search, 0));
      }
      return next(action);

    // Person management actions
    case `${ rolesList.PERSONMANAGEMENT.systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        store.dispatch(loadOne(rolesList.PERSONMANAGEMENT.systemRoleName, action.meta.previousAction.payload.itemId));
      }
      return next(action);

    case PERSON_MANAGEMENT_UPDATE_ADDRESS + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        store.dispatch(loadOne(rolesList.PERSONMANAGEMENT.systemRoleName, action.meta.previousAction.payload.personId));
      }
      return next(action);

    case PERSON_MANAGEMENT_UPDATE_FLAG + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        store.dispatch(loadSelectedPersonFlags(action.meta.previousAction.payload.personId));
      }
      return next(action);

    case PERSON_MANAGEMENT_UPLOAD_FILE + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        store.dispatch(findLegalDocument(action.meta.previousAction.payload.personId));
      }
      return next(action);

    case PERSON_MANAGEMENT_CLEAR_CACHE + SUCCESS:
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        // add salt
        store.dispatch(loadPersonCache(action.meta.previousAction.payload.personId));
      }
      return next(action);

    case TESTOUTBOUNDPAYMENT_APPROVE + SUCCESS:
    case TESTOUTBOUNDPAYMENT_CANCEL + SUCCESS:
      store.dispatch(loadAll('TESTOUTBOUNDPAYMENT'));
      return next(action);

    case FEESCONFIGURATION_SUSPEND + SUCCESS:
    case FEESCONFIGURATION_UNSUSPEND + SUCCESS:
      {
        const {
          feeBuilder: {
            selected: { product_fee_definition_id: feeId },
          },
        } = store.getState();
        store.dispatch(loadOne(rolesList.FEECONFIGURATION.systemRoleName, feeId));
      }
      return next(action);
    case IDENTITY_MANAGEMENT_BLOCK_IDENTITY + SUCCESS:
    case IDENTITY_MANAGEMENT_UNBLOCK_IDENTITY + SUCCESS:
    case IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY + FAIL:
    case IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY + SUCCESS:
    case IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL + SUCCESS: {
      {
        const {
          personManagement: {
            // eslint-disable-next-line camelcase
            selectedPerson: { person_id },
          },
        } = store.getState();
        store.dispatch(loadIdentities(person_id));
      }
      return next(action);
    }
    case IDENTITY_MANAGEMENT_LINK_CANDIDATE_TO_IDENTITY + SUCCESS: {
      {
        const {
          personManagement: {
            // eslint-disable-next-line camelcase
            selectedPerson: { person_id },
          },
        } = store.getState();
        store.dispatch(loadIdentities(person_id));
      }
      store.dispatch(searchIdentityLinkCandidates());
      store.dispatch(setChosenIdentityLinkCandidate());
      return next(action);
    }
    case LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE + SUCCESS: {
      if (action.meta && action.meta.previousAction && action.meta.previousAction.payload) {
        store.dispatch(loadSelectedLoanMandates(action.meta.previousAction.payload.loanId, action.meta.previousAction.payload.currentLiveVersion));
      }
      return next(action);
    }
    case rolesList.ACCOUNTING.systemRoleName + LOAD_ONE + SUCCESS: {
      const hostOrgList = action?.payload?.data?.templates.reduce((acc: Array<Object>, current: Object) => {
        const newAcc = acc;
        if (!acc.includes(current.host_organisation_id)) {
          newAcc.push(current.host_organisation_id);
        }
        return newAcc;
      }, []);
      hostOrgList.forEach((each: any) => store.dispatch(
        loadFormData('ACCOUNTING', 'template', {
          host_organisation_id: each,
        })
      ));
      return next(action);
    }
    case API_KEY_MANAGEMENT_CREATE_KEY + SUCCESS: {
      store.dispatch(loadActiveKeys());
      return next(action);
    }
    case API_KEY_MANAGEMENT_REVOKE_KEY + SUCCESS: {
      store.dispatch(loadActiveKeys());
      store.dispatch(loadRevokedKeys());
      return next(action);
    }

    default:
      return next(action);
  }
};
