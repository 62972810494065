//@flow
import tokenMiddleware from './tokenMiddleware';
import notificationCenter from './notificationCenter';
import apiResponseProcessor from './apiResponseProcessor';
//$FlowFixMe
import unauthorisedMiddleware from './unauthorisedMiddleware';
import specialRequestsMiddleware from './specialRequestsMiddleware';
import creditDeclineOverturns from './creditDeclineOverturns';
import cardPayments from './cardPayments';
import updateAddress from './updateAddress';
import updateName from './updateName';
// Import middlewares here

export default {
  tokenMiddleware,
  notificationCenter,
  apiResponseProcessor,
  unauthorisedMiddleware,
  specialRequestsMiddleware,
  creditDeclineOverturns,
  cardPayments,
  updateAddress,
  updateName,
};
