// AUTH0 constants
export const token = "auth.token";
export const profile = "auth.profile";
export const refreshtoken = "yobota.refreshtoken";
export const jwt = "yobota.jwt";

export const AUTH_SIGNIN = "AUTH_SIGNIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";

// User data
export const AUTH_LOAD_USER_DATA = "AUTH_LOAD_USER_DATA";

// Token management
export const AUTH_PROCESS_ACCESS_TOKEN = "AUTH_PROCESS_ACCESS_TOKEN";
export const AUTH_RETRIEVE_ACCESS_TOKEN = "AUTH_RETRIEVE_ACCESS_TOKEN";
export const AUTH_RETRIEVE_ACCESS_TOKEN_SUCCESS =
  "AUTH_RETRIEVE_ACCESS_TOKEN_SUCCESS";
export const AUTH_RETRIEVE_ACCESS_TOKEN_FAIL =
  "AUTH_RETRIEVE_ACCESS_TOKEN_FAIL";

export const AUTH_REFRESH_ACCESS_TOKEN = "AUTH_REFRESH_ACCESS_TOKEN";
export const AUTH_REFRESH_ACCESS_TOKEN_SUCCESS =
  "AUTH_REFRESH_ACCESS_TOKEN_SUCCESS";
export const AUTH_REFRESH_ACCESS_TOKEN_FAIL = "AUTH_REFRESH_ACCESS_TOKEN_FAIL";
export const AUTH_LOAD_ACCESS_TOKEN_FROM_STORAGE =
  "AUTH_LOAD_ACCESS_TOKEN_FROM_STORAGE";

// Registration/ first time login
export const REGISTRATION_SAVE_DATA = "REGISTRATION_SAVE_DATA";
export const REGISTRATION_SAVE_DATA_SUCCESS = "REGISTRATION_SAVE_DATA_SUCCESS";
export const REGISTRATION_SAVE_DATA_FAIL = "REGISTRATION_SAVE_DATA_FAIL";

export const REGISTRATION_LOAD_SECURITY_QUESTIONS =
  "REGISTRATION_LOAD_SECURITY_QUESTIONS";
export const REGISTRATION_LOAD_SECURITY_QUESTIONS_SUCCESS =
  "REGISTRATION_LOAD_SECURITY_QUESTIONS_SUCCESS";

export const REGISTRATION_LOAD_SECURITY_QUESTIONS_FAIL =
  "REGISTRATION_LOAD_SECURITY_QUESTIONS_FAIL";

export const REGISTRATION_SAVE_SECURITY_QUESTIONS =
  "REGISTRATION_SAVE_SECURITY_QUESTIONS";
export const REGISTRATION_SAVE_SECURITY_QUESTIONS_SUCCESS =
  "REGISTRATION_SAVE_SECURITY_QUESTIONS_SUCCESS";

export const REGISTRATION_SAVE_SECURITY_QUESTIONS_FAIL =
  "REGISTRATION_SAVE_SECURITY_QUESTIONS_FAIL";

export const REGISTRATION_SAVE_SMS_VERIFICATION =
  "REGISTRATION_SAVE_SMS_VERIFICATION";

export const REGISTRATION_SAVE_SMS_VERIFICATION_SUCCESS =
  "REGISTRATION_SAVE_SMS_VERIFICATION_SUCCESS";
export const REGISTRATION_SAVE_SMS_VERIFICATION_FAIL =
  "REGISTRATION_SAVE_SMS_VERIFICATION_FAIL";

export const LOGOUT = "LOGOUT";
export const REDIRECT_TO_LOGOUT = "REDIRECT_TO_LOGOUT";

export const REGISTRATION_DATA_URL = "/api/staff_signup/";
export const SECURITY_QUESTIONS_URL = "/api/memorable_questions/";
export const SMS_VERIFICATION_URL = "/api/resync_profile/";
