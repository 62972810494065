//@flow
import ReactGA from 'react-ga';
//$FlowFixMe
import { rolesList } from 'GlobalConfigs';

import {
  LOAD_ALL,
  LOAD_FORM_DATA,
  LOAD_ONE,
  UPDATE_ONE,
  CREATE,
  DELETE,
} from 'Store/Constants/generic';


/**
 * loadAll - Load all elements for a particular role
 *
 * @param {string} role   System name for the role to be loaded
 *
 * @return {action}       Action creator which will resolve to an array of objects
 */
export const loadAll = (role: string, params: Object, extraUrl: string) => {
  // GA event tracking
  ReactGA.event({
    category: role,
    action: 'Load all',
  });

  return {
    type: role + LOAD_ALL,
    payload: {
      client: rolesList[role].client,
      request: {
        method: 'GET',
        url: extraUrl ? `${ rolesList[role].roleURL }${ extraUrl }` : `${ rolesList[role].roleURL }`,
        params,
      },
    },
  };
};


/**
 * loadFormData - Load any dropdown options required for a particular role (refdata)
 *
 * @param {string} role   System name for the role to be loaded
 *
 * @return {action}       Action creator which will resolve to an object with form builder information
 */
export const loadFormData = (role: string, extraUrl: string = '', params: Object) => ({
  type: role + LOAD_FORM_DATA,
  payload: {
    client: rolesList[role].client,
    request: {
      method: 'OPTIONS',
      url: `${ rolesList[role].roleURL }${ extraUrl }`,
      params,
    },
  },
});


/**
 * loadOne - Load one entry from a role
 *
 * @param {string} role     System name for the role to be loaded
 * @param {number} itemId   ID of the item to be loaded
 *
 * @return {action}         Action creator which will resolve to an object with detailed information
 */
export const loadOne = (role: string, itemId: number, params: Object, extraUrl: string) => {
  // GA event tracking
  ReactGA.event({
    category: role,
    action: 'Load one',
  });

  if (role === 'COMPANYMANAGEMENT') {
    return {
      type: role + LOAD_ONE,
      payload: {
        client: rolesList[role].client,
        request: {
          method: 'GET',
          url: extraUrl ? `${ rolesList[role].roleURL }${ extraUrl }/${ itemId }` : `${ rolesList[role].roleURL }${ itemId }`,
          params,
        },
      },
    };
  }

  return {
    type: role + LOAD_ONE,
    payload: {
      client: rolesList[role].client,
      request: {
        method: 'GET',
        url: extraUrl ? `${ rolesList[role].roleURL }${ extraUrl }/${ itemId }` : `${ rolesList[role].roleURL }${ itemId }/`,
        params,
      },
    },
  };
};


/**
 * createOne - Create one entry for a role
 *
 * @param {string} role           System name for the role to be loaded
 * @param {Object}   data         Form data to be valiated and inserted in the database
 * @param {string} [redirectTo=]  Optional url to be used for moving the user inside the app
 *
 * @return {action}               Action creator which will return the created object when resolved
 */
export const createOne = (role: string, data: Object, redirectTo: string = '', params: Object, extraUrl: string) => {
  // GA event tracking
  ReactGA.event({
    category: role,
    action: 'Create',
  });

  return {
    type: role + CREATE,
    payload: {
      client: rolesList[role].client,
      request: {
        method: 'POST',
        url: extraUrl ? `${ rolesList[role].roleURL }${ extraUrl }` : `${ rolesList[role].roleURL }`,
        data: {
          ...data,
        },
        params,
      },
      redirectTo,
    },
  };
};


/**
 * deleteOne - Delete one entry in the role
 *
 * @param {string} role                     System name for the role to be loaded
 * @param {number} itemId                   ID of the item to be loaded
 * @param {dateTime}   retrievealTimestamp  Time at which the object has been retreived
 * @param {string} [redirectTo=]            Optional url to be used for moving the user inside the app
 *
 * @return {action}                         Action creator
 */
export const deleteOne = (role: string, itemId: number, retrievealTimestamp: string, redirectTo: string = '', params: Object, extraUrl: string, data: Object) => {
  // GA event tracking
  ReactGA.event({
    category: role,
    action: 'Delete',
  });

  return {
    type: role + DELETE,
    payload: {
      client: rolesList[role].client,
      request: {
        method: 'DELETE',
        url: extraUrl ? `${ rolesList[role].roleURL }${ extraUrl }/${ itemId }` : `${ rolesList[role].roleURL }${ itemId }/`,
        data: {
          retrieval_timestamp: retrievealTimestamp,
          ...data,
        },
        params,
      },
      redirectTo,
    },
  };
};


/**
 * updateOne - Update one entry in the role
 *
 * @param {string} role           System name for the role to be loaded
 * @param {number} itemId         ID of the item to be loaded
 * @param {Object}   data         Form data to be valiated and inserted in the database
 * @param {string} [redirectTo=]  Optional url to be used for moving the user inside the app
 *
 * @return {action}               Action creator which will return the updated object when resolved
 */
export const updateOne = (role: string, itemId: number, data: Object, redirectTo: string = '', params: Object, extraUrl: string) => {
  // GA event tracking
  ReactGA.event({
    category: role,
    action: 'Update',
  });

  return {
    type: role + UPDATE_ONE,
    payload: {
      client: rolesList[role].client,
      request: {
        method: 'PATCH',
        url: extraUrl ? `${ rolesList[role].roleURL }${ extraUrl }/${ itemId }` : `${ rolesList[role].roleURL }${ itemId }/`,
        data: {
          ...data,
        },
        params,
      },
      itemId,
      redirectTo,
    },
  };
};
