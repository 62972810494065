import {
  NOTIFICATION_CREATE,
  NOTIFICATION_DELETE,
  POPUP,
} from 'Store/Constants/notificationCenter';

export const initialState = {
  notifications: [],
  popup: false,
  popupInfo: null,
};

import {Actions} from "../Actions";

type NotificationCenterState = {
  notifications: any[];
  popup: boolean;
  popupInfo: unknown;
}

const notificationMap = {
  [NOTIFICATION_CREATE]: (
      state: NotificationCenterState,
      action: any
  ) => {
    if (Array.isArray(action.payload)) {
      return ({ notifications: [action.payload, ...state.notifications] });
    }
    return state;
  },
  [NOTIFICATION_DELETE]: (
      state: NotificationCenterState,
      action: any
  ) => ({
    notifications: state.notifications.filter((notification: any) => notification.id !== action.payload),
  }),
};

const popupMap = {
  [POPUP]: (
      {popup}: any,
      action: any
  ) => ({
    popup: !popup,
    popupInfo: action.params,
  }),
};

const actionsMap = {
  ...notificationMap,
  ...popupMap,
};

export default function reducer(
    state: NotificationCenterState = initialState,
    action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
