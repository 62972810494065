import axios from "axios";
import jsFileDownload from "js-file-download";
import {Dispatch} from "redux";
import { Actions } from "Store/Actions";
import {processTokenData, refreshAccessToken} from "Store/Actions/auth";
import {RootState} from "Store/configureStore";
import {BASE_URL} from "Store/Constants/application";
import { AUTH_REFRESH_ACCESS_TOKEN } from "Store/Constants/auth";
import {AccountInfoType} from "Types/auth";
import {
  getLocalJWTToken,
  setLocalJWTToken,
  setLocalRefreshToken,
} from "./authentication";

enum Methods {
  GET = "get",
  OPTIONS = "options",
  DELETE = "delete"
}

type ReduxParams = {
  getState: () => RootState;
  dispatch: Dispatch;
};

type RegistrationClientResponse = {
  data: AccountInfoType;
  config: {
    reduxSourceAction: {
      type: Actions['type']
    }
  }
}

type ClientType = {
  [key in "legal_entity_id" | "host_organisation_id"]?: string;
};

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const apiURL = `${BASE_URL}`;
const authInstance = axios.create({baseURL: apiURL});
const defaultInstance = axios.create({baseURL: apiURL});
const fileUploaderInstance = axios.create({baseURL: apiURL});

const stubURL = "/json";
const stubsInstance = axios.create({baseURL: stubURL});

const fileuploadContentType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
const loanManagementEndpoint = {
  previewStatement: "/loan_management/preview_statement/",
  statements: "/loan_management/statements/",
};
const dpaReportEndpoint = "/dpa_report/";
const customerRecordEndpoint = "/customer_record/";
const downloadRateTable = "/download_rate_table/";
const downloadEndPoint = "/download/"

export const clients = {
  default: {
    client: stubsInstance,
  },
  loginClient: {
    client: authInstance,
    options: {
      interceptors: {
        response: [
          {
            success: (
              {dispatch}: ReduxParams,
              res: {data: AccountInfoType}
            ) => {
              if (res.data.access) setLocalJWTToken(res.data.access);
              if (res.data.refresh) setLocalRefreshToken(res.data.refresh);

              dispatch(processTokenData(res.data));
              return res;
            },
          },
        ],
      },
    },
  },
  registrationClient: {
    client: authInstance,
    options: {
      interceptors: {
        request: [
          {
            success: (
              _: ReduxParams,
              req: {headers: {Authorization: string}}
            ) => {
              if (typeof getLocalJWTToken() !== "string") {
                throw new TypeError();
              }

              req.headers = {
                Authorization: `Bearer ${getLocalJWTToken()}`,
              };
              return req;
            },
          },
        ],
        response: [
          {
            success: (
              {dispatch}: ReduxParams,
              res: RegistrationClientResponse
            ) => {
              if (
                !res.data.security_questions_required &&
                !res.data.signup_required &&
                !res.data.sms_required &&
                res.config.reduxSourceAction.type !== AUTH_REFRESH_ACCESS_TOKEN
              ) {
                dispatch(refreshAccessToken());
              }
              return res;
            },
          },
        ],
      },
    },
  },
  uploadFileCLient: {
    client: fileUploaderInstance,
    options: {
      interceptors: {
        request: [
          {
            success: (
              {getState}: ReduxParams,
              req: {
                headers: {Authorization: string; "Content-Type": string};
                params: {host_organisation_id: string};
              }
            ) => {
              const activeHostOrg = getState().application.active.id;

              if (typeof getLocalJWTToken() !== "string") {
                throw new TypeError();
              }

              req.headers = {
                Authorization: `Bearer ${getLocalJWTToken()}`,
                "Content-Type": fileuploadContentType,
              };

              req.params = {
                host_organisation_id: `${activeHostOrg}`,
              };

              return req;
            },
          },
        ],
      },
    },
  },
  uploadFileCLientviaLegalEntity: {
    client: fileUploaderInstance,
    options: {
      interceptors: {
        request: [
          {
            success: (
              {getState}: ReduxParams,
              req: {
                headers: {Authorization: string};
                params: {legal_entity_id: string};
              }
            ) => {
              const entityID = getState().application.entities[0]
                .legal_entity_id;

              if (typeof getLocalJWTToken() !== "string") {
                throw new TypeError();
              }

              req.headers = {
                Authorization: `Bearer ${getLocalJWTToken()}`,
              };

              req.params = {
                legal_entity_id: entityID,
              };
              return req;
            },
          },
        ],
      },
    },
  },
  // Generic client for LE/HO
  authenticatedClient: {
    client: defaultInstance,
    options: {
      interceptors: {
        request: [
          {
            success: (
              {getState}: ReduxParams,
              req: {
                method: Methods;
                params: ClientType;
                data: ClientType;
                headers: {Authorization: string};
              }
            ) => {
              const {active} = getState().application;

              if (typeof getLocalJWTToken() !== "string") {
                throw new TypeError();
              }

              if (active.objectType === "entity") {
                if (
                  req.method === Methods.GET ||
                  req.method === Methods.OPTIONS ||
                  req.method === Methods.DELETE
                ) {
                  req.params = {
                    ...req.params,
                    legal_entity_id: active.id,
                  };
                } else {
                  req.data = {
                    ...req.data,
                    legal_entity_id: active.id,
                  };
                }
              }

              if (active.objectType === "host") {
                if (req.method === Methods.GET || req.method === Methods.OPTIONS) {
                  req.params = {
                    ...req.params,
                    host_organisation_id: active.id,
                  };
                } else {
                  req.data = {
                    ...req.data,
                    host_organisation_id: active.id,
                  };
                }
              }

              req.headers = {
                Authorization: `Bearer ${getLocalJWTToken()}`,
              };

              return req;
            },
          },
        ],
        response: [
          {
            success: (
              {getState}: ReduxParams,
              res: {
                headers: {[x: string]: string};
                config: {method?: string; fileName?: string; url: string};
                data: string | ArrayBuffer | ArrayBufferView | Blob;
              }
            ) => {
              // handle statements
              if (
                res.headers["content-type"] === "application/pdf" &&
                res.config.method !== Methods.OPTIONS
              ) {
                let fileName = "file";
                const {url} = res.config;
                const {loanId} = getState().loanManagement.selectedLoan

                if (url.includes(loanManagementEndpoint.previewStatement)) {
                  fileName = `PreviewStatement${
                    loanId
                  }`;
                }
                if (url.includes(loanManagementEndpoint.statements)) {
                  fileName = `Statement${loanId}`;
                }
                // TODO: make the urls checks regex
                if (url.includes(downloadEndPoint)) {
                  const searchFileName = res.config.fileName || "";
                  fileName = `${searchFileName.replace(
                    / /g,
                    ""
                  )}_legal_document`;
                }
                jsFileDownload(res.data, `${fileName}.pdf`);
              }
              if (
                res.headers["content-type"] === fileuploadContentType &&
                res.config.method !== Methods.OPTIONS
              ) {
                let fileName = "file";
                // handle DPA report
                if (res.config.url.includes(dpaReportEndpoint)) {
                  const {
                    person_id,
                  } = getState().personManagement.selectedPerson;
                  fileName = `DSARReport${person_id}`;
                }
                // handle Customer report
                if (res.config.url.includes(customerRecordEndpoint)) {
                  const {
                    person_id,
                  } = getState().personManagement.selectedPerson;
                  fileName = `CustomerRecord${person_id}`;
                }
                // handle rate table download
                if (res.config.url.includes(downloadRateTable)) {
                  const {
                    loan_product_id,
                  } = getState().loanProducts.selectedLoanProduct;
                  fileName = `RateTable${loan_product_id}`;
                }
                jsFileDownload(res.data, `${fileName}.xlsx`);
              }
              return res;
            },
          },
        ],
      },
    },
  },
};
