import {ReferralStateType} from "Types/referrals";
import {LOAD_ALL, LOAD_ONE} from "Store/Constants/generic";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

export const initialState: ReferralStateType = {
  all: [],
  selectedReferral: {},
  loadingReferralData: false,
  selectedReferralPersonDetails: {},
  loadingPersonDetails: false,
  message: "",
};

let actionsMap: Record<
  string,
  (state: ReferralStateType, action: Actions) => ReferralStateType
> = {};

if (rolesList.FRAUDREFERS && rolesList.FRAUDREFERS.systemRoleName) {
  const referralsActionsMap = {
    [`${rolesList.FRAUDREFERS.systemRoleName}${LOAD_ALL}${PENDING}`]: (
      state: ReferralStateType
    ) => ({
      ...state,
      loadingReferralData: true,
    }),
    [`${rolesList.FRAUDREFERS.systemRoleName}${LOAD_ALL}${SUCCESS}`]: (
      state: ReferralStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      all: data || [],
      loadingReferralData: false,
    }),
    [`${rolesList.FRAUDREFERS.systemRoleName}${LOAD_ALL}${FAIL}`]: (
      state: ReferralStateType
    ) => ({
      ...state,
      loadingReferralData: false,
    }),
    [`${rolesList.FRAUDREFERS.systemRoleName}${LOAD_ONE}${PENDING}`]: (
      state: ReferralStateType
    ) => ({
      ...state,
      loadingReferralData: true,
    }),
    [`${rolesList.FRAUDREFERS.systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: ReferralStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      selectedReferral: data || {},
      loadingReferralData: false,
      message: "",
    }),
    [`${rolesList.FRAUDREFERS.systemRoleName}${LOAD_ONE}${FAIL}`]: (
      state: ReferralStateType
    ) => ({
      ...state,
      loadingReferralData: false,
    }),
  };

  actionsMap = {
    ...referralsActionsMap,
  };
}

export default function reducer(
  state: ReferralStateType = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
