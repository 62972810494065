//@flow
// LOANPRODUCTMANAGEMENT role constants
export const LOANPRODUCTMANAGEMENT_LOAD_BUILDER_DATA = 'LOANPRODUCTMANAGEMENT_LOAD_BUILDER_DATA';

export const LOANPRODUCTMANAGEMENT_URL = '/api/loan_product/';

// LOANPRODUCTMANAGEMENT helper actions constants
export const LOANPRODUCTMANAGEMENT_CONVERT_SPREADSHEET_TO_RATE_TABLE = 'LOANPRODUCTMANAGEMENT_CONVERT_SPREADSHEET_TO_RATE_TABLE';
export const LOANPRODUCTMANAGEMENT_DOWNLOAD_RATE_TABLE = 'LOANPRODUCTMANAGEMENT_DOWNLOAD_RATE_TABLE';
export const LOANPRODUCTMANAGEMENT_RESET_SPREADSHEET = 'LOANPRODUCTMANAGEMENT_RESET_SPREADSHEET';
export const LOANPRODUCTMANAGEMENT_SAMPLE_QUOTE = 'LOANPRODUCTMANAGEMENT_SAMPLE_QUOTE';
export const LOANPRODUCTMANAGEMENT_PRICING_SURFACE = 'LOANPRODUCTMANAGEMENT_PRICING_SURFACE';
export const LOANPRODUCTMANAGEMENT_CHECK_CONDITION = 'LOANPRODUCTMANAGEMENT_CHECK_CONDITION';
export const LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT = 'LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT';
export const LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT = 'LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT';
export const LOANPRODUCTMANAGEMENT_SUSPEND_PRODUCT = 'LOANPRODUCTMANAGEMENT_SUSPEND_PRODUCT';


export const sampleRateTable = [];
