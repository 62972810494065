//@flow

export const auth = {
  permissions: [
    {
      read: true,
      role_id: 'IDENTITYMANAGEMENT',
      write: true,
    },
  ],
};

export const application = {
  loadingHoLes: false,
  entities: [
    {
      legal_entity_id: 2,
      name: 'Chetwood',
      id: 2,
      shortCode: 'chetwood',
      title: 'Chetwood',
      logo: '/images/chetwood_logo.png',
      objectType: 'entity',
    },
  ],
  defaultEntity: 'chetwood',
  hostOrganisations: [
    {
      host_organisation_id: 1,
      name: 'LiveLend',
      legal_entity_id: 2,
      id: 1,
      shortCode: 'livelend',
      title: 'LiveLend',
      objectType: 'host',
    },
    {
      host_organisation_id: 2,
      name: 'SmartSave',
      legal_entity_id: 2,
      id: 2,
      shortCode: 'smartsave',
      title: 'SmartSave',
      objectType: 'host',
    },
    {
      host_organisation_id: 3,
      name: 'BetterBorrow',
      legal_entity_id: 2,
      id: 3,
      shortCode: 'betterborrow',
      title: 'BetterBorrow',
      objectType: 'host',
    },
  ],
  active: {
    host_organisation_id: 1,
    name: 'LiveLend',
    legal_entity_id: 2,
    id: 1,
    shortCode: 'livelend',
    title: 'LiveLend',
    objectType: 'host',
  },
  currentPage: [
    {
      title: 'Loan funding buckets',
      url: 'funding-buckets',
    },
  ],
  tourSteps: [
    {
      selector: '[data-tour="funding-buckets-main-container"]',
      content: {
        type: 'div',
        key: null,
        ref: null,
        props: {
          children: [
            'Here you can see existing funding buckets. You will need to have funding bucket(s) set up in the system to be able to set up loan products.',
            {
              type: 'br',
              key: null,
              ref: null,
              props: {},
              _owner: null,
              _store: {},
            },
            'If you update a funding bucket, any pending payments will be updated automatically. You will not be able to delete a funding bucket that has a loan product associated, however you can suspend the bucket using the update function.',
            {
              type: 'br',
              key: null,
              ref: null,
              props: {},
              _owner: null,
              _store: {},
            },
            'If a funding bucket is suspended, all loan products associated to that bucket will become unavailable.',
          ],
        },
        _owner: null,
        _store: {},
      },
    },
    {
      selector: '[data-tour="funding-buckets-search-bar"]',
      content: 'Use this search bar to search for a specific funding bucket by name or ID.',
    },
    {
      selector: '[data-tour="funding-buckets-add-new"]',
      content: 'You can click this button to create a new funding bucket.',
    },
    {
      selector: '[data-tour="funding-buckets-first-list-element"]',
      content: 'Click on a specific funding bucket in order to see more details.',
      stepInteraction: true,
    },
  ],
  pagination: {
    pageSize: 4,
    page: 0,
  },
};

export const personManagement = {
  searchResults: [
    {
      person_details: {
        first_name: 'HARPO',
        last_name: 'SUCCESS',
        person_title: 'Mr',
        date_of_birth: '1978-01-24',
        date_of_death: null,
      },
      person_id: 2092,
    },
  ],
  loadingSearchresults: false,
  selectedPerson: {
    addresses: [
      {
        address_json: {
          building: 'Yo-Building',
          county: 'yo-county',
          line_1: 'Yo-Street',
          line_2: '',
          post_town: 'Yo-Town',
          postcode: 'YO3 0TA',
          residential_status: 'Tenant',
          ptcabs: '11111111111',
        },
        address_from_date: '2020-07-12',
        address_to_date: null,
        address_is_validated: false,
        address_type_name: 'MAIN',
        time_at_address: 0,
      },
      {
        address_json: {
          building: 'Yo-Building',
          county: 'yo-county',
          line_1: 'Yo-Street',
          line_2: '',
          post_town: 'Yo-Town',
          postcode: 'YO3 0TA',
          residential_status: 'Mortgage',
          ptcabs: '11111111111',
        },
        address_from_date: '2020-07-06',
        address_to_date: '2020-07-12',
        address_is_validated: false,
        address_type_name: 'MAIN',
        time_at_address: 0,
      },
      {
        address_json: {
          building: '100',
          county: null,
          line_1: 'Yobota Street 1',
          line_2: 'Yobota Street 2',
          post_town: 'Yobota Town',
          postcode: 'YO3 0TA',
          residential_status: 'Mortgage',
          ptcabs: '11111111111',
        },
        address_from_date: '2010-02-01',
        address_to_date: '2020-07-06',
        address_is_validated: true,
        address_type_name: 'MAIN',
        time_at_address: 125,
      },
    ],
    communication_method_preferences: [
      {
        communication_method_name: 'phone',
        communication_method_opt_in: false,
        host_organisation_id: 1,
        host_organisation_name: 'LiveLend',
      },
      {
        communication_method_name: 'sms',
        communication_method_opt_in: false,
        host_organisation_id: 1,
        host_organisation_name: 'LiveLend',
      },
      {
        communication_method_name: 'email',
        communication_method_opt_in: true,
        host_organisation_id: 1,
        host_organisation_name: 'LiveLend',
      },
      {
        communication_method_name: 'phone',
        communication_method_opt_in: true,
        host_organisation_id: 2,
        host_organisation_name: 'SmartSave',
      },
      {
        communication_method_name: 'sms',
        communication_method_opt_in: true,
        host_organisation_id: 2,
        host_organisation_name: 'SmartSave',
      },
      {
        communication_method_name: 'email',
        communication_method_opt_in: true,
        host_organisation_id: 2,
        host_organisation_name: 'SmartSave',
      },
    ],
    communication_opt_in: [
      {
        communication_category_name: 'account_changes',
        communication_frequency_name: 'daily',
        host_organisation_id: 1,
        host_organisation_name: 'LiveLend',
      },
      {
        communication_category_name: 'product_offers',
        communication_frequency_name: 'daily',
        host_organisation_id: 2,
        host_organisation_name: 'SmartSave',
      },
      {
        communication_category_name: 'account_changes',
        communication_frequency_name: 'daily',
        host_organisation_id: 2,
        host_organisation_name: 'SmartSave',
      },
    ],
    email_addresses: [
      {
        email_address: 'anpasculli+ll+ss_09062020_harpo@gmail.com',
        email_address_is_primary: true,
        email_address_validation_datetime: '2020-07-02T15:19:23.893000Z',
      },
    ],
    freeform_category: [
      {
        freeform_category_name: 'Cat7',
        person_freeform_category_json: {
          documents: [
            {
              source: 'https://snowdon-j6aio7915vnhwct.s3.amazonaws.com',
              doc_name: 'fscs',
              doc_uuid: 'adcd2237-26bf-4252-a76e-3fec00daacb0',
              file_path: '/cw-s-staging/media/products/1dfw_dm/fscs_information_sheet.pdf',
              person_id: 2092,
              timestamp: '2020-07-02T17:22:51.303437+01:00',
              account_id: 120336,
              event_type: null,
              product_id: 63,
              account_type: 'deposit_account',
              display_name: 'SmartSave FSCS Information Sheet',
              event_log_id: null,
              journey_name: 'SMARTSAVE_JOURNEY',
              product_code: '1DFW_DM',
              quotestore_uuid: '2d30d26a-9916-47eb-aa86-da4226d8715f',
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
            {
              source: 'https://snowdon-j6aio7915vnhwct.s3.amazonaws.com',
              doc_name: 'privacy_policy',
              doc_uuid: '454337bc-ea91-4eff-b8d0-fb6afe4cc5c5',
              file_path: '/cw-s-staging/media/products/1dfw_dm/privacy_policy.pdf',
              person_id: 2092,
              timestamp: '2020-07-02T17:22:51.303437+01:00',
              account_id: 120336,
              event_type: null,
              product_id: 63,
              account_type: 'deposit_account',
              display_name: 'SmartSave Privacy policy',
              event_log_id: null,
              journey_name: 'SMARTSAVE_JOURNEY',
              product_code: '1DFW_DM',
              quotestore_uuid: '2d30d26a-9916-47eb-aa86-da4226d8715f',
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
            {
              source: 'https://snowdon-j6aio7915vnhwct.s3.amazonaws.com',
              doc_name: 'summary',
              doc_uuid: 'c645e633-bc49-48d4-a2bf-e8feabab0401',
              file_path: '/cw-s-staging/media/products/1dfw_dm/summary_box.pdf',
              person_id: 2092,
              timestamp: '2020-07-02T17:22:51.303437+01:00',
              account_id: 120336,
              event_type: null,
              product_id: 63,
              account_type: 'deposit_account',
              display_name: 'SmartSave Fixed Rate Saver Summary Box',
              event_log_id: null,
              journey_name: 'SMARTSAVE_JOURNEY',
              product_code: '1DFW_DM',
              quotestore_uuid: '2d30d26a-9916-47eb-aa86-da4226d8715f',
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
            {
              source: 'https://snowdon-j6aio7915vnhwct.s3.amazonaws.com',
              doc_name: 'terms',
              doc_uuid: '13ad1983-1ccf-464b-9495-618f597c692a',
              file_path: '/cw-s-staging/media/products/1dfw_dm/terms_conditions.pdf',
              person_id: 2092,
              timestamp: '2020-07-02T17:22:51.303437+01:00',
              account_id: 120336,
              event_type: null,
              product_id: 63,
              account_type: 'deposit_account',
              display_name: 'SmartSave Terms & Conditions',
              event_log_id: null,
              journey_name: 'SMARTSAVE_JOURNEY',
              product_code: '1DFW_DM',
              quotestore_uuid: '2d30d26a-9916-47eb-aa86-da4226d8715f',
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: '0de241fa-cf3f-4e25-becd-d47144f121cc',
              file_path: 'staff_upload/2020-07-10T15:32:48.741437+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T14:32:48.741437+00:00',
              account_id: 120336,
              event_type: null,
              product_id: 120336,
              account_type: 'deposit_account',
              display_name: 'Test Upload',
              event_log_id: null,
              journey_name: null,
              product_code: '1DFW_DM',
              quotestore_uuid: null,
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: '8d0c12e2-cbb5-4d8c-8cc7-e301ab351fad',
              file_path: 'staff_upload/2020-07-10T15:34:25.810553+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T14:34:25.810553+00:00',
              account_id: 120336,
              event_type: null,
              product_id: 120336,
              account_type: 'deposit_account',
              display_name: 'Chicken',
              event_log_id: null,
              journey_name: null,
              product_code: '1DFW_DM',
              quotestore_uuid: null,
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: '049d8562-03c0-46af-b671-145f8a631d90',
              file_path: 'staff_upload/2020-07-10T15:50:57.424489+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T14:50:57.424489+00:00',
              account_id: 120336,
              event_type: null,
              product_id: 120336,
              account_type: 'deposit_account',
              display_name: 'NOTWHATYOUTHINK',
              event_log_id: null,
              journey_name: null,
              product_code: '1DFW_DM',
              quotestore_uuid: null,
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: '29645452-69ca-4fe9-a22d-594844560e02',
              file_path: 'staff_upload/2020-07-10T16:07:00.481755+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T15:07:00.481755+00:00',
              account_id: 120336,
              event_type: null,
              product_id: 120336,
              account_type: 'deposit_account',
              display_name: 'NOTWHATYOUTHINK',
              event_log_id: null,
              journey_name: null,
              product_code: '1DFW_DM',
              quotestore_uuid: null,
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: '89efc8af-7929-46bd-8ff3-841a152ef314',
              file_path: 'staff_upload/2020-07-10T16:21:15.201940+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T15:21:15.201940+00:00',
              account_id: 120336,
              event_type: null,
              product_id: 120336,
              account_type: 'deposit_account',
              display_name: 'queeeeee',
              event_log_id: null,
              journey_name: null,
              product_code: '1DFW_DM',
              quotestore_uuid: null,
              account_reference: 'SMRTD4PHH',
              event_schedule_id: null,
              freeform_category: 'Cat7',
              host_organisation_id: 2,
              host_organisation_name: 'smartsave',
            },
          ],
          smartsave: null,
        },
      },
      {
        freeform_category_name: 'Cat2',
        person_freeform_category_json: {
          documents: [
            {
              source: 'development-session-cache',
              doc_name: 'direct_debit_mandate',
              doc_uuid: 'd74fbd40-f3a3-4ab2-bfc4-06943633d602',
              file_path: 'direct_debit_mandate/2020-06-09T14:44:03.066500+01:00_2092_121082.pdf',
              person_id: 2092,
              timestamp: '2020-06-09T14:44:05.728377+01:00',
              account_id: 121082,
              event_type: null,
              product_id: 9,
              account_type: 'loan',
              display_name: 'LiveLend - Direct Debit Mandate',
              event_log_id: null,
              journey_name: 'LOAN_APPLICATION',
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: '25f0d801-ebb9-48a9-94a7-e69f9088d755',
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
            {
              source: 'development-session-cache',
              doc_name: 'pci_secci',
              doc_uuid: '78656820-b1db-4c81-92a8-d4f7ae751868',
              file_path: 'pci_secci/2020-06-09T14:44:34.370991+01:00_2092_9.pdf',
              person_id: 2092,
              timestamp: '2020-06-09T14:44:36.061821+01:00',
              account_id: 121082,
              event_type: null,
              product_id: 9,
              account_type: 'loan',
              display_name: 'LiveLend - Pre-Contract Credit Information',
              event_log_id: null,
              journey_name: 'LOAN_APPLICATION',
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: '25f0d801-ebb9-48a9-94a7-e69f9088d755',
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
            {
              source: 'development-session-cache',
              doc_name: 'cca_document',
              doc_uuid: '668c9839-af14-410b-a040-60b3c1e76cce',
              file_path: 'cca_document/2020-06-09T14:44:34.370991+01:00_2092_9.pdf',
              person_id: 2092,
              timestamp: '2020-06-09T14:44:36.061821+01:00',
              account_id: 121082,
              event_type: null,
              product_id: 9,
              account_type: 'loan',
              display_name: 'LiveLend - Draft Loan Agreement',
              event_log_id: null,
              journey_name: 'LOAN_APPLICATION',
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: '25f0d801-ebb9-48a9-94a7-e69f9088d755',
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
            {
              source: 'development-session-cache',
              doc_name: 'adequate_explanation',
              doc_uuid: '65586c5d-46c3-4ee1-b97d-46b887a9caf4',
              file_path: 'adequate_explanation/2020-06-09T14:44:34.370991+01:00_2092_9.pdf',
              person_id: 2092,
              timestamp: '2020-06-09T14:44:36.061821+01:00',
              account_id: 121082,
              event_type: null,
              product_id: 9,
              account_type: 'loan',
              display_name: 'LiveLend - Important Information',
              event_log_id: null,
              journey_name: 'LOAN_APPLICATION',
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: '25f0d801-ebb9-48a9-94a7-e69f9088d755',
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
            {
              source: 'development-session-cache',
              doc_name: 'final_cca_document',
              doc_uuid: 'baf4da7d-59c4-40b5-ad52-8a4c04a1cc19',
              file_path: 'final_cca_document/2020-06-09T14:44:39.684751+01:00_2092_9.pdf',
              person_id: 2092,
              timestamp: '2020-06-09T14:44:40.390492+01:00',
              account_id: 121082,
              event_type: null,
              product_id: 9,
              account_type: 'loan',
              display_name: 'LiveLend - Loan Agreement',
              event_log_id: null,
              journey_name: 'LOAN_APPLICATION',
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: '25f0d801-ebb9-48a9-94a7-e69f9088d755',
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: '12eba33c-7908-4b79-9820-60b19cb46ed2',
              file_path: 'staff_upload/2020-07-10T15:31:28.380903+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T14:31:28.380903+00:00',
              account_id: 121082,
              event_type: null,
              product_id: 121082,
              account_type: 'loan',
              display_name: 'Upload Test',
              event_log_id: null,
              journey_name: null,
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: null,
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: 'fdf417c2-dc43-4a8d-8d91-b96f7c29f088',
              file_path: 'staff_upload/2020-07-10T15:37:22.857363+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T14:37:22.857363+00:00',
              account_id: 121082,
              event_type: null,
              product_id: 121082,
              account_type: 'loan',
              display_name: 'dont open',
              event_log_id: null,
              journey_name: null,
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: null,
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: 'db8b0570-7467-4b48-a78f-4248d1ded446',
              file_path: 'staff_upload/2020-07-10T16:09:31.823559+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T15:09:31.823559+00:00',
              account_id: 121082,
              event_type: null,
              product_id: 121082,
              account_type: 'loan',
              display_name: 'upload2electricbungaloo',
              event_log_id: null,
              journey_name: null,
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: null,
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
            {
              source: 'development-session-cache',
              doc_name: 'staff_upload',
              doc_uuid: 'cfdc7ac2-c410-4429-9c2c-efa7937f3f7e',
              file_path: 'staff_upload/2020-07-10T16:20:24.402983+01:00_2092_uploaded.pdf',
              person_id: 2092,
              timestamp: '2020-07-10T15:20:24.402983+00:00',
              account_id: 121082,
              event_type: null,
              product_id: 121082,
              account_type: 'loan',
              display_name: 'upload2electricbungaloo2',
              event_log_id: null,
              journey_name: null,
              product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
              quotestore_uuid: null,
              account_reference: 'LIVEL4Q8U',
              event_schedule_id: null,
              freeform_category: 'Cat2',
              host_organisation_id: 1,
              host_organisation_name: 'livelend',
            },
          ],
          file_locations: null,
        },
      },
      {
        freeform_category_name: 'DEPOSIT_COMMUNICATION_SENT',
        person_freeform_category_json: {
          DEPOSIT_COMMUNICATION_SENT: [
            {
              subject: 'Choose what to do next with your savings',
              email_cc: [],
              trace_id: 'd2c4e4d9-b3eb-4c9f-869f-2c946b6e957c',
              email_bcc: [],
              timestamp: '2020-07-03T06:25:45.486609+00:00',
              from_email: 'test@yobota.uk',
              recipients: ['anpasculli+ll+ss_09062020_harpo@gmail.com'],
              attachments: [],
              beneficiary: '/beneficiary/3/',
              template_id: '',
              product_type: 'deposit_account',
              email_subject: 'Choose what to do next with your savings',
              mobile_number: '',
              template_path: 'smartsave/email_templates/202-choose-eot.html',
              trace_span_id: 'eccd4838-339d-40ab-915c-264433f3d149',
              email_location: 'SmartSave/account_communications/1DFW_DM_120336_2020-07-03T07:25:45.078671+01:00.html',
              email_addresses: [],
              legal_entity_id: 2,
              template_version: 1,
              email_bucket_name: 'staging-chetwood-customer-documents',
              template_from_app: false,
              communication_type: 'email',
              deposit_account_id: 120336,
              template_bucket_name: 'staging-chetwood-templates',
              deposit_account_product_code: '1DFW_DM',
              deposit_account_event_schedule_id: 1453,
              deposit_account_event_schedule_log_id: 2252,
            },
            {
              subject: 'Don’t let your savings drop to 0%',
              email_cc: [],
              trace_id: 'fdd0ccb9-83f3-42b6-a348-b97593683f19',
              email_bcc: [],
              timestamp: '2020-07-03T06:27:54.602932+00:00',
              from_email: 'test@yobota.uk',
              recipients: ['anpasculli+ll+ss_09062020_harpo@gmail.com'],
              attachments: [],
              beneficiary: '/beneficiary/3/',
              template_id: '',
              product_type: 'deposit_account',
              email_subject: 'Don’t let your savings drop to 0%',
              mobile_number: '',
              template_path: 'smartsave/email_templates/214-no-decision-t10.html',
              trace_span_id: '8f14185c-76b9-418f-8ceb-6c867295d3d8',
              email_location: 'SmartSave/account_communications/1DFW_DM_120336_2020-07-03T07:27:54.208003+01:00.html',
              email_addresses: [],
              legal_entity_id: 2,
              template_version: 1,
              email_bucket_name: 'staging-chetwood-customer-documents',
              template_from_app: false,
              communication_type: 'email',
              deposit_account_id: 120336,
              template_bucket_name: 'staging-chetwood-templates',
              deposit_account_product_code: '1DFW_DM',
              deposit_account_event_schedule_id: 1452,
              deposit_account_event_schedule_log_id: 2253,
            },
            {
              subject: 'We’re sending your SmartSave savings back to you',
              email_cc: [],
              trace_id: 'dad5e468-ff77-4eb3-84f1-5f9a0f7f6a0b',
              email_bcc: [],
              timestamp: '2020-07-04T06:01:31.396543+00:00',
              from_email: 'test@yobota.uk',
              recipients: ['anpasculli+ll+ss_09062020_harpo@gmail.com'],
              attachments: [],
              beneficiary: '/beneficiary/3/',
              template_id: '',
              product_type: 'deposit_account',
              email_subject: 'We’re sending your SmartSave savings back to you',
              mobile_number: '',
              template_path: 'smartsave/email_templates/207-no-eot-chosen.html',
              trace_span_id: 'c47ac0ad-c825-4c49-a1a7-d934fcd8a27b',
              email_location: 'SmartSave/account_communications/1DFW_DM_120336_2020-07-04T07:01:31.129175+01:00.html',
              email_addresses: [],
              legal_entity_id: 2,
              template_version: 1,
              email_bucket_name: 'staging-chetwood-customer-documents',
              template_from_app: false,
              communication_type: 'email',
              deposit_account_id: 120336,
              template_bucket_name: 'staging-chetwood-templates',
              deposit_account_product_code: '1DFW_DM',
              deposit_account_event_schedule_id: 1468,
              deposit_account_event_schedule_log_id: 2305,
            },
          ],
        },
      },
      {
        freeform_category_name: 'Cat4',
        person_freeform_category_json: {
          COMMUNICATIONS_SENT: [
            {
              email_cc: [],
              email_bcc: [],
              timestamp: '2020-06-09T13:44:07.219120+00:00',
              attachments: [
                {
                  version: 1,
                  doc_name: 'direct_debit_mandate.pdf',
                  template_from_app: true,
                },
              ],
              template_id: 'direct_debit_confirmation',
              email_subject: "Here's your direct debit mandate",
              mobile_number: '',
              email_addresses: ['anpasculli+ll_09062020_harpo@gmail.com'],
              template_version: 1,
              template_from_app: true,
              communication_type: 'email',
              uploaded_file_locations: {
                direct_debit_mandate: {
                  status: 'success',
                  file_path: 'direct_debit_mandate/2020-06-09T14:44:03.066500+01:00_2092_121082.pdf',
                  bucket_name: 'development-session-cache',
                },
              },
            },
            {
              email_cc: [],
              email_bcc: [],
              timestamp: '2020-06-09T13:44:17.205820+00:00',
              attachments: [
                {
                  version: 1,
                  doc_name: 'pci_secci.pdf',
                  template_from_app: true,
                },
                {
                  version: 1,
                  doc_name: 'cca_document.pdf',
                  template_from_app: true,
                },
                {
                  version: 1,
                  doc_name: 'adequate_explanation.pdf',
                  template_from_app: true,
                },
              ],
              template_id: 'legal_documentation',
              email_subject: 'Congratulations! Your loan has been approved!',
              mobile_number: '',
              email_addresses: ['anpasculli+ll_09062020_harpo@gmail.com'],
              template_version: 1,
              template_from_app: true,
              communication_type: 'email',
              uploaded_file_locations: {},
            },
            {
              email_cc: [],
              email_bcc: [],
              timestamp: '2020-06-09T13:44:41.278338+00:00',
              attachments: [
                {
                  version: 1,
                  doc_name: 'final_cca_document.pdf',
                  template_from_app: true,
                },
              ],
              template_id: 'final_cca_document',
              email_subject: 'Thanks for choosing to take your loan with us.',
              mobile_number: '',
              email_addresses: ['anpasculli+ll_09062020_harpo@gmail.com'],
              template_version: 1,
              template_from_app: true,
              communication_type: 'email',
              uploaded_file_locations: {
                final_cca_document: {
                  status: 'success',
                  file_path: 'final_cca_document/2020-06-09T14:44:39.684751+01:00_2092_9.pdf',
                  bucket_name: 'development-session-cache',
                },
              },
            },
            {
              email_cc: [],
              email_bcc: [],
              timestamp: '2020-07-02T16:22:52.438105+00:00',
              attachments: [],
              template_id: 'smartsave_welcome_email',
              email_subject: 'Welcome to SmartSave',
              mobile_number: '',
              email_addresses: ['anpasculli+ll+ss_09062020_harpo@gmail.com'],
              template_version: 1,
              template_from_app: true,
              communication_type: 'email',
              uploaded_file_locations: {},
            },
          ],
        },
      },
      {
        freeform_category_name: 'Cat5',
        person_freeform_category_json: {
          fscs: {
            time_served: '2020-07-02T16:22:49.158730+00:00',
            session_unique_identifier: '7739291b-39ca-44a6-bb70-5a150245a54a',
          },
          terms: {
            time_served: '2020-07-02T16:22:49.158761+00:00',
            session_unique_identifier: '7739291b-39ca-44a6-bb70-5a150245a54a',
          },
          summary: {
            time_served: '2020-07-02T16:22:49.158789+00:00',
            session_unique_identifier: '7739291b-39ca-44a6-bb70-5a150245a54a',
          },
          pci_secci: {
            time_served: '2020-06-09T13:44:24.510050+00:00',
            session_unique_identifier: '7aa94278-f94f-4674-b96e-04926a67925a',
          },
          cca_document: {
            time_served: '2020-06-09T13:44:27.288202+00:00',
            session_unique_identifier: '7aa94278-f94f-4674-b96e-04926a67925a',
          },
          privacy_policy: {
            time_served: '2020-07-02T16:22:49.158677+00:00',
            session_unique_identifier: '7739291b-39ca-44a6-bb70-5a150245a54a',
          },
          adequate_explanation: {
            time_served: '2020-06-09T13:44:20.550445+00:00',
            session_unique_identifier: '7aa94278-f94f-4674-b96e-04926a67925a',
          },
        },
      },
      {
        freeform_category_name: 'Cat3',
        person_freeform_category_json: {
          auxilary_data: {
            preferences_set: true,
          },
        },
      },
      {
        freeform_category_name: 'Cat6',
        person_freeform_category_json: {
          disburse_consent: true,
          fulfilment_consent: true,
        },
      },
      {
        freeform_category_name: 'Cat9',
        person_freeform_category_json: {
          LiveLend: {
            prospect_ids: ['14c19ed6-6d81-4970-a566-8b394ed953e6'],
          },
        },
      },
    ],
    memorable_questions: [
      {
        security_topic_name: 'security_street',
        security_topic_answer: 'street',
        characters: [
          {
            position: 2,
            value: 't',
          },
          {
            position: 5,
            value: 'e',
          },
          {
            position: 6,
            value: 't',
          },
        ],
      },
      {
        security_topic_name: 'security_school',
        security_topic_answer: 'school',
        characters: [
          {
            position: 2,
            value: 'c',
          },
          {
            position: 3,
            value: 'h',
          },
          {
            position: 5,
            value: 'o',
          },
        ],
      },
      {
        security_topic_name: 'security_company',
        security_topic_answer: 'company',
        characters: [
          {
            position: 6,
            value: 'n',
          },
          {
            position: 7,
            value: 'y',
          },
          {
            position: 3,
            value: 'm',
          },
        ],
      },
    ],
    person_details: {
      first_name: 'HARPO',
      last_name: 'SUCCESS',
      person_title: 'Mr',
      date_of_birth: '1978-01-24',
      date_of_death: null,
    },
    person_id: 2092,
    person_is_validated: true,
    retrieval_timestamp: '2020-07-24T09:48:16.11834+00:00',
    telephone_numbers: [
      {
        telephone_number: '07767385180',
        telephone_number_is_mobile: true,
        telephone_number_is_primary: true,
        telephone_number_is_validated: false,
        telephone_number_validation_json: {
          validated: 'no',
        },
      },
    ],
    person_flags: [
      {
        flag_type_name: 'Suspected fraud',
        flag_type_application_code: 'SUSPECTEDFRAUD',
        person_flag_value: false,
        reason: null,
      },
    ],
    person_last_successful_login_datetime: '2020-07-02T16:22:28.295643+00:00',
    number_of_failed_login_attempts_since_last_successful_login: 0,
    number_of_successful_logins: 2,
  },
  loadingSelectedPerson: false,
  loadingPersonLoans: false,
  personProducts: {
    loans: [
      {
        loan_id: 121082,
        loan_reference: 'LIVEL4Q8U',
        loan_status_application_code: 'OPEN',
        loan_product_id: 9,
        loan_product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
        initial_loan_version_lend_date: '2020-06-09',
        initial_loan_version_initial_capital_balance: '5050.0000',
        host_organisation_id: 1,
      },
    ],
    deposit_accounts: [
      {
        deposit_account_id: 120336,
        deposit_account_reference: 'SMRTD4PHH',
        deposit_account_status_application_code: 'OPEN',
        deposit_product_id: 63,
        deposit_product_code: '1DFW_DM',
        deposit_account_insertion_datetime: '2020-07-02T16:22:49.972471+00:00',
        host_organisation_id: 2,
      },
    ],
  },
  loadingPersonProducts: false,
  personActiveJourneys: [],
  loadingPersonCache: false,
  personBankAccounts: [
    {
      account_number: '01234567',
      bank_name: 'BARCLAYS BANK PLC',
      branch_code: '000001',
      party_name: 'HARPO SUCCESS',
      person_bank_account_id: 1366,
      person_bank_account_insertion_datetime: '2020-06-09T13:43:44.252785+00:00',
      retrieval_timestamp: '2020-07-24T09:48:19.799302+00:00',
      validation_datetime: '2020-06-09T12:43:44.015708Z',
      loan_nominated_person_bank_accounts: [
        {
          loan_nominated_person_bank_account_id: 1108,
          loan_id: 121082,
          person_id: 2092,
          person_bank_account_id: 1366,
          effective_from_timestamptz: '2020-06-09T00:00:00+00:00',
          effective_to_timestamptz: null,
        },
      ],
      deposit_account_nominated_person_bank_accounts: [
        {
          deposit_account_nominated_person_bank_account_id: 347,
          deposit_account_id: 120336,
          person_id: 2092,
          person_bank_account_id: 1366,
          effective_from_timestamptz: '2020-07-02T00:00:00+00:00',
          effective_to_timestamptz: null,
        },
      ],
      account_type: 'Loan',
      account_id: 121082,
      nominated_bank_account_id: 1108,
      nominated_bank_account_is_active: true,
      effective_from_timestamptz: '2020-06-09T00:00:00+00:00',
      effective_to_timestamptz: null,
    },
  ],
  loadingPersonBankAccounts: false,
  legalDocuments: [
    {
      doc_uuid: 'd74fbd40-f3a3-4ab2-bfc4-06943633d602',
      doc_name: 'direct_debit_mandate',
      display_name: 'LiveLend - Direct Debit Mandate',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:05.728377+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: '78656820-b1db-4c81-92a8-d4f7ae751868',
      doc_name: 'pci_secci',
      display_name: 'LiveLend - Pre-Contract Credit Information',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:36.061821+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: '668c9839-af14-410b-a040-60b3c1e76cce',
      doc_name: 'cca_document',
      display_name: 'LiveLend - Draft Loan Agreement',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:36.061821+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: '65586c5d-46c3-4ee1-b97d-46b887a9caf4',
      doc_name: 'adequate_explanation',
      display_name: 'LiveLend - Important Information',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:36.061821+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: 'baf4da7d-59c4-40b5-ad52-8a4c04a1cc19',
      doc_name: 'final_cca_document',
      display_name: 'LiveLend - Loan Agreement',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:40.390492+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: '12eba33c-7908-4b79-9820-60b19cb46ed2',
      doc_name: 'staff_upload',
      display_name: 'Upload Test',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-07-10T14:31:28.380903Z',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: null,
    },
    {
      doc_uuid: 'fdf417c2-dc43-4a8d-8d91-b96f7c29f088',
      doc_name: 'staff_upload',
      display_name: 'dont open',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-07-10T14:37:22.857363Z',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: null,
    },
    {
      doc_uuid: 'db8b0570-7467-4b48-a78f-4248d1ded446',
      doc_name: 'staff_upload',
      display_name: 'upload2electricbungaloo',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-07-10T15:09:31.823559Z',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: null,
    },
    {
      doc_uuid: 'cfdc7ac2-c410-4429-9c2c-efa7937f3f7e',
      doc_name: 'staff_upload',
      display_name: 'upload2electricbungaloo2',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-07-10T15:20:24.402983Z',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: null,
    },
    {
      doc_uuid: 'adcd2237-26bf-4252-a76e-3fec00daacb0',
      doc_name: 'fscs',
      display_name: 'SmartSave FSCS Information Sheet',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-02T17:22:51.303437+01:00',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: 'SMARTSAVE_JOURNEY',
    },
    {
      doc_uuid: '454337bc-ea91-4eff-b8d0-fb6afe4cc5c5',
      doc_name: 'privacy_policy',
      display_name: 'SmartSave Privacy policy',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-02T17:22:51.303437+01:00',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: 'SMARTSAVE_JOURNEY',
    },
    {
      doc_uuid: 'c645e633-bc49-48d4-a2bf-e8feabab0401',
      doc_name: 'summary',
      display_name: 'SmartSave Fixed Rate Saver Summary Box',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-02T17:22:51.303437+01:00',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: 'SMARTSAVE_JOURNEY',
    },
    {
      doc_uuid: '13ad1983-1ccf-464b-9495-618f597c692a',
      doc_name: 'terms',
      display_name: 'SmartSave Terms & Conditions',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-02T17:22:51.303437+01:00',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: 'SMARTSAVE_JOURNEY',
    },
    {
      doc_uuid: '0de241fa-cf3f-4e25-becd-d47144f121cc',
      doc_name: 'staff_upload',
      display_name: 'Test Upload',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-10T14:32:48.741437Z',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: null,
    },
    {
      doc_uuid: '8d0c12e2-cbb5-4d8c-8cc7-e301ab351fad',
      doc_name: 'staff_upload',
      display_name: 'Chicken',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-10T14:34:25.810553Z',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: null,
    },
    {
      doc_uuid: '049d8562-03c0-46af-b671-145f8a631d90',
      doc_name: 'staff_upload',
      display_name: 'NOTWHATYOUTHINK',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-10T14:50:57.424489Z',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: null,
    },
    {
      doc_uuid: '29645452-69ca-4fe9-a22d-594844560e02',
      doc_name: 'staff_upload',
      display_name: 'NOTWHATYOUTHINK',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-10T15:07:00.481755Z',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: null,
    },
    {
      doc_uuid: '89efc8af-7929-46bd-8ff3-841a152ef314',
      doc_name: 'staff_upload',
      display_name: 'queeeeee',
      person_id: 2092,
      host_organisation_name: 'smartsave',
      host_organisation_id: 2,
      timestamp: '2020-07-10T15:21:15.201940Z',
      account_type: 'deposit_account',
      account_id: 120336,
      account_reference: 'SMRTD4PHH',
      product_code: '1DFW_DM',
      journey_name: null,
    },
  ],
  loadingExtraLegalDocument: false,
  personFlagsHistory: [
    {
      person_flag_id: 110,
      flag_type_id: 6,
      flag_type_name: 'Suspected fraud',
      person_flag_value: false,
      flag_type_application_code: 'SUSPECTEDFRAUD',
      person_flag_is_archived: false,
      person_flag_insertion_datetime: '2020-07-23T11:29:04.045839+00:00',
      person_flag_archived_datetime: null,
      reason: null,
    },
    {
      person_flag_id: 103,
      flag_type_id: 6,
      flag_type_name: 'Suspected fraud',
      person_flag_value: true,
      flag_type_application_code: 'SUSPECTEDFRAUD',
      person_flag_is_archived: true,
      person_flag_insertion_datetime: '2020-07-23T09:46:11.158424+00:00',
      person_flag_archived_datetime: '2020-07-23T11:29:04.045839+00:00',
      reason: null,
    },
  ],
  loadingPersonFlagsHistory: false,
  addressMatch: [],
  loadingAddressMatch: false,
  dynamicData: {
    person_id: {
      type: 'integer',
      required: true,
      read_only: false,
      label: 'Person id',
      min_value: -1,
    },
    new_address: {
      type: 'nested object',
      required: false,
      read_only: false,
      label: 'New address',
      children: {
        address_json: {
          type: 'nested object',
          required: true,
          read_only: false,
          label: 'Address json',
          children: {
            building: {
              type: 'string',
              required: true,
              read_only: false,
              label: 'Building',
            },
            county: {
              type: 'string',
              required: false,
              read_only: false,
              label: 'County',
            },
            line_1: {
              type: 'string',
              required: true,
              read_only: false,
              label: 'Line 1',
            },
            line_2: {
              type: 'string',
              required: true,
              read_only: false,
              label: 'Line 2',
            },
            post_town: {
              type: 'string',
              required: true,
              read_only: false,
              label: 'Post town',
            },
            postcode: {
              type: 'string',
              required: true,
              read_only: false,
              label: 'Postcode',
            },
            residential_status: {
              type: 'choice',
              required: false,
              read_only: false,
              label: 'Residential status',
              choices: [
                {
                  value: 'Mortgage',
                  display_name: 'Homeowner with Mortgage',
                },
                {
                  value: 'Homeowner',
                  display_name: 'Homeowner without Mortgage',
                },
                {
                  value: 'Counciltenant',
                  display_name: 'Council tenant',
                },
                {
                  value: 'Livingwithparents',
                  display_name: 'Living with parents',
                },
                {
                  value: 'Tenant',
                  display_name: 'Private tenant',
                },
                {
                  value: 'Other',
                  display_name: 'Other',
                },
              ],
            },
            ptcabs: {
              type: 'string',
              required: false,
              read_only: false,
              label: 'Ptcabs',
            },
          },
        },
        address_from_date: {
          type: 'date',
          required: true,
          read_only: false,
          label: 'Address from date',
        },
        address_to_date: {
          type: 'date',
          required: false,
          read_only: false,
          label: 'Address to date',
        },
        address_type_name: {
          type: 'string',
          required: true,
          read_only: false,
          label: 'Address type name',
        },
        address_is_validated: {
          type: 'boolean',
          required: false,
          read_only: false,
          label: 'Address is validated',
        },
      },
    },
    addresses: {
      type: 'list',
      required: false,
      read_only: false,
      label: 'Addresses',
      child: {
        type: 'nested object',
        required: false,
        read_only: false,
        children: {
          address_json: {
            type: 'nested object',
            required: true,
            read_only: false,
            label: 'Address json',
            children: {
              building: {
                type: 'string',
                required: true,
                read_only: false,
                label: 'Building',
              },
              county: {
                type: 'string',
                required: false,
                read_only: false,
                label: 'County',
              },
              line_1: {
                type: 'string',
                required: true,
                read_only: false,
                label: 'Line 1',
              },
              line_2: {
                type: 'string',
                required: true,
                read_only: false,
                label: 'Line 2',
              },
              post_town: {
                type: 'string',
                required: true,
                read_only: false,
                label: 'Post town',
              },
              postcode: {
                type: 'string',
                required: true,
                read_only: false,
                label: 'Postcode',
              },
              residential_status: {
                type: 'choice',
                required: false,
                read_only: false,
                label: 'Residential status',
                choices: [
                  {
                    value: 'Mortgage',
                    display_name: 'Homeowner with Mortgage',
                  },
                  {
                    value: 'Homeowner',
                    display_name: 'Homeowner without Mortgage',
                  },
                  {
                    value: 'Counciltenant',
                    display_name: 'Council tenant',
                  },
                  {
                    value: 'Livingwithparents',
                    display_name: 'Living with parents',
                  },
                  {
                    value: 'Tenant',
                    display_name: 'Private tenant',
                  },
                  {
                    value: 'Other',
                    display_name: 'Other',
                  },
                ],
              },
              ptcabs: {
                type: 'string',
                required: false,
                read_only: false,
                label: 'Ptcabs',
              },
            },
          },
          address_from_date: {
            type: 'date',
            required: true,
            read_only: false,
            label: 'Address from date',
          },
          address_to_date: {
            type: 'date',
            required: false,
            read_only: false,
            label: 'Address to date',
          },
          address_type_name: {
            type: 'string',
            required: true,
            read_only: false,
            label: 'Address type name',
          },
          address_is_validated: {
            type: 'boolean',
            required: false,
            read_only: false,
            label: 'Address is validated',
          },
        },
      },
    },
    person_flags: {
      type: 'list',
      required: false,
      read_only: false,
      label: 'Person flags',
      child: {
        type: 'nested object',
        required: false,
        read_only: false,
        children: {
          flag_type_application_code: {
            type: 'choice',
            required: true,
            read_only: false,
            label: 'Flag type application code',
            choices: [
              {
                value: 'GONEAWAY',
                display_name: 'Goneaway',
              },
              {
                value: 'POWEROFATTORNEY',
                display_name: 'Power of Attorney',
              },
              {
                value: 'DMP',
                display_name: 'Debt management plan',
              },
              {
                value: 'IVA',
                display_name: 'Individual voluntary arrangement',
              },
              {
                value: 'VULNERABLE',
                display_name: 'Vulnerable',
              },
              {
                value: 'SUSPECTEDFRAUD',
                display_name: 'Suspected fraud',
              },
              {
                value: 'CONFIRMEDFRAUD',
                display_name: 'Confirmed fraud',
              },
              {
                value: 'DECEASED',
                display_name: 'Deceased',
              },
              {
                value: 'PREDELINQUENCY',
                display_name: 'Pre-delinquency',
              },
            ],
          },
          person_flag_value: {
            type: 'boolean',
            required: true,
            read_only: false,
            label: 'Person flag value',
          },
          reason: {
            type: 'string',
            required: false,
            read_only: false,
            label: 'Reason',
          },
        },
      },
    },
    retrieval_timestamp: {
      type: 'datetime',
      required: true,
      read_only: false,
      label: 'Retrieval timestamp',
    },
  },
  loadingDynamicData: false,
  loadingDPAReport: false,
  loadingRegeneratePersonDocuments: false,
  fileUploadDynamicData: {},
  personLoans: [],
  extraLegalDocument: [],
};

export const personManagementAlt = {
  searchResults: [
    {
      person_details: {
        first_name: 'HARPO',
        last_name: 'SUCCESS',
        person_title: 'Mr',
        date_of_birth: '1978-01-24',
        date_of_death: null,
      },
      person_id: 2092,
    },
  ],
  loadingSearchresults: false,
  selectedPerson: {
    addresses: [
      {
        address_json: {
          building: 'Yo-Building',
          county: 'yo-county',
          line_1: 'Yo-Street',
          line_2: '',
          post_town: 'Yo-Town',
          postcode: 'YO3 0TA',
          residential_status: 'Tenant',
          ptcabs: '11111111111',
        },
        address_from_date: '2020-07-12',
        address_to_date: null,
        address_is_validated: false,
        address_type_name: 'MAIN',
        time_at_address: 0,
      },
    ],
    communication_method_preferences: [
      {
        communication_method_name: 'phone',
        communication_method_opt_in: false,
        host_organisation_id: 1,
        host_organisation_name: 'LiveLend',
      },
      {
        communication_method_name: 'sms',
        communication_method_opt_in: false,
        host_organisation_id: 1,
        host_organisation_name: 'LiveLend',
      },
      {
        communication_method_name: 'email',
        communication_method_opt_in: true,
        host_organisation_id: 1,
        host_organisation_name: 'LiveLend',
      },
    ],
    communication_opt_in: [
      {
        communication_category_name: 'account_changes',
        communication_frequency_name: 'daily',
        host_organisation_id: 1,
        host_organisation_name: 'LiveLend',
      },
    ],
    email_addresses: [
      {
        email_address: 'anpasculli+ll+ss_09062020_harpo@gmail.com',
        email_address_is_primary: true,
        email_address_validation_datetime: '2020-07-02T15:19:23.893000Z',
      },
    ],
    freeform_category: [],
    memorable_questions: [],
    person_details: {
      first_name: 'HARPO',
      last_name: 'SUCCESS',
      person_title: 'Mr',
      date_of_birth: '1978-01-24',
      date_of_death: null,
    },
    person_id: 2092,
    person_is_validated: true,
    retrieval_timestamp: '2020-07-24T09:48:16.11834+00:00',
    telephone_numbers: [
      {
        telephone_number: '07767385180',
        telephone_number_is_mobile: true,
        telephone_number_is_primary: true,
        telephone_number_is_validated: false,
        telephone_number_validation_json: {
          validated: 'no',
        },
      },
    ],
    person_flags: [
      {
        flag_type_name: 'Suspected fraud',
        flag_type_application_code: 'SUSPECTEDFRAUD',
        person_flag_value: false,
        reason: null,
      },
    ],
    person_last_successful_login_datetime: '2020-07-02T16:22:28.295643+00:00',
    number_of_failed_login_attempts_since_last_successful_login: 0,
    number_of_successful_logins: 2,
  },
  loadingSelectedPerson: false,
  loadingPersonLoans: false,
  personProducts: {
    loans: [
      {
        loan_id: 121082,
        loan_reference: 'LIVEL4Q8U',
        loan_status_application_code: 'OPEN',
        loan_product_id: 9,
        loan_product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
        initial_loan_version_lend_date: '2020-06-09',
        initial_loan_version_initial_capital_balance: '5050.0000',
        host_organisation_id: 1,
      },
      {
        loan_id: 121083,
        loan_reference: 'LIVEL4Q8I',
        loan_status_application_code: 'CLOSED',
        loan_product_id: 9,
        loan_product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
        initial_loan_version_lend_date: '2020-06-09',
        initial_loan_version_initial_capital_balance: '5050.0000',
        host_organisation_id: 1,
      },
    ],
    deposit_accounts: [],
  },
  loadingPersonProducts: false,
  personActiveJourneys: [],
  loadingPersonCache: false,
  personBankAccounts: [],
  loadingPersonBankAccounts: false,
  legalDocuments: [
    {
      doc_uuid: 'd74fbd40-f3a3-4ab2-bfc4-06943633d602',
      doc_name: 'direct_debit_mandate',
      display_name: 'LiveLend - Direct Debit Mandate',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:05.728377+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: '78656820-b1db-4c81-92a8-d4f7ae751868',
      doc_name: 'pci_secci',
      display_name: 'LiveLend - Pre-Contract Credit Information',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:36.061821+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: '668c9839-af14-410b-a040-60b3c1e76cce',
      doc_name: 'cca_document',
      display_name: 'LiveLend - Draft Loan Agreement',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:36.061821+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: '65586c5d-46c3-4ee1-b97d-46b887a9caf4',
      doc_name: 'adequate_explanation',
      display_name: 'LiveLend - Important Information',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:36.061821+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
    {
      doc_uuid: 'baf4da7d-59c4-40b5-ad52-8a4c04a1cc19',
      doc_name: 'final_cca_document',
      display_name: 'LiveLend - Loan Agreement',
      person_id: 2092,
      host_organisation_name: 'livelend',
      host_organisation_id: 1,
      timestamp: '2020-06-09T14:44:40.390492+01:00',
      account_type: 'loan',
      account_id: 121082,
      account_reference: 'LIVEL4Q8U',
      product_code: 'YOBOTA_TEST_BIG_RATE_TABLE',
      journey_name: 'LOAN_APPLICATION',
    },
  ],
  loadingExtraLegalDocument: false,
  personFlagsHistory: [],
  loadingPersonFlagsHistory: false,
  addressMatch: [],
  loadingAddressMatch: false,
  dynamicData: {},
  loadingDynamicData: false,
  loadingDPAReport: false,
  loadingRegeneratePersonDocuments: false,
  fileUploadDynamicData: {},
  personLoans: [],
  extraLegalDocument: [],
};

export const identityManagement = {
  identityPerson: [
    {
      identity_id: 'ABCD',
      identity_host_organisation_id: 11111,
      identity_host_organisation_name: 'Livelend',
      legal_entity_id: 1,
      identity_status: 1,
      identity_creation_date: '2021-02-11T15:36:16.313Z',
      identity_provider_id: 'Auth 0',
      successful_logins: ['2021-02-11T15:36:16.313Z'],
      failed_logins: ['2021-02-11T15:36:16.313Z'],
    },
    {
      identity_id: 'WXYZ',
      identity_host_organisation_id: 2222,
      identity_host_organisation_name: 'Smartsave',
      legal_entity_id: 2,
      identity_status: 2,
      identity_creation_date: '2021-02-11T15:36:16.313Z',
      identity_provider_id: 'YIP',
      successful_logins: ['2021-02-11T15:36:16.313Z'],
      failed_logins: ['2021-02-11T15:36:16.313Z'],
    },
  ],
  identitiesActivity: {
    successful_logins: 2346,
    failed_logins: 87,
    new_signups: 154,
    blocked_accounts: 137,
  },
};

export const activityHistoryMock = [
  {
    event_type: 'register',
    status_type: 'success',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Yobota Identity Provider',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'update-identity',
    status_type: 'failure',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Auth0',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'login',
    status_type: 'success',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Yobota Identity Provider',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'search',
    status_type: 'failure',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Auth0',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'identity-verification-token',
    status_type: 'failure',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Yobota Identity Provider',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'reset-password-request',
    status_type: 'success',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Auth0',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'password-reset-verify-token',
    status_type: 'failure',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Yobota Identity Provider',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'password-reset',
    status_type: 'success',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Auth0',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'password-changed',
    status_type: 'failure',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Yobota Identity Provider',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'change-password-token',
    status_type: 'success',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Auth0',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'login-failed',
    status_type: 'failure',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Yobota Identity Provider',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'user-blocked',
    status_type: 'success',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Auth0',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'user-unblocked',
    status_type: 'failure',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Yobota Identity Provider',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'identity-verified',
    status_type: 'success',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Auth0',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'change-password',
    status_type: 'failure',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Yobota Identity Provider',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
  {
    event_type: 'send-verification-email',
    status_type: 'success',
    created: '2021-02-26T09:56:55.812Z',
    identifier: 'identifier_string',
    identity_provider: 'Auth0',
    email_address: 'email@company.com',
    client: 'client_string',
    description: 'string',
  },
];
