import {getLocalJWTToken} from "Store/Api/authentication";
import {
  APPLICATION_CHANGE_PAGE,
  APPLICATION_TOGGLE_TOUR,
  APPLICATION_UPDATE_ACTIVE_HOLE,
  APPLICATION_UPDATE_LOCATION,
  APPLICATION_UPDATE_TOUR_STEPS,
} from "Store/Constants/application";
import {ActiveClient, StepObjectType} from "Types/application";

export const updateLocation = (
  newLocation: {title: string; url: string}[],
  tourSteps: StepObjectType[] = [],
  defaultPageSize = 6
) => {
  // TODO => this should be a presentation concern
  // Change page title for bookmarks
  let newTitle = "Staff";
  newLocation.forEach((page) => {
    newTitle += ` - ${page.title}`;
  });

  document.title = newTitle;

  return {
    type: APPLICATION_UPDATE_LOCATION as typeof APPLICATION_UPDATE_LOCATION,
    payload: {
      newLocation,
      tourSteps,
      defaultPageSize,
    },
  };
};
export type UpdateLocation = ReturnType<typeof updateLocation>;

export const updateTourSteps = (tourSteps: StepObjectType[] = []) => ({
  type: APPLICATION_UPDATE_TOUR_STEPS as typeof APPLICATION_UPDATE_TOUR_STEPS,
  payload: {
    tourSteps,
  },
});
export type UpdateTourSteps = ReturnType<typeof updateTourSteps>;

export const updatePage = (pageNumber: number) => ({
  type: APPLICATION_CHANGE_PAGE as typeof APPLICATION_CHANGE_PAGE,
  payload: {
    page: pageNumber,
  },
});
export type UpdatePage = ReturnType<typeof updatePage>;

export const updateActive = (newActive: ActiveClient) => {
  const token = getLocalJWTToken();
  return {
    type: APPLICATION_UPDATE_ACTIVE_HOLE as typeof APPLICATION_UPDATE_ACTIVE_HOLE,
    payload: {
      newActive,
      token,
    },
  };
};
export type UpdateActive = ReturnType<typeof updateActive>;

export const toggleTour = () => ({
  type: APPLICATION_TOGGLE_TOUR as typeof APPLICATION_TOGGLE_TOUR,
});
export type ToggleTour = ReturnType<typeof toggleTour>;

export type ApplicationActions =
  | UpdateLocation
  | UpdateTourSteps
  | UpdatePage
  | UpdateActive
  | ToggleTour;
