//@flow
import {
  IDENTITY_MANAGEMENT_LOAD_HISTORY,
  IDENTITY_MANAGEMENT_LOAD_IDENTITIES_ACTIVITY,
  IDENTITY_MANAGEMENT_LOAD_IDENTITIES,
  IDENTITY_MANAGEMENT_BLOCK_IDENTITY,
  IDENTITY_MANAGEMENT_UNBLOCK_IDENTITY,
  IDENTITY_MANAGEMENT_SET_IDENTITY_TO_ACT_ON,
  IDENTITY_MANAGEMENT_UPDATE_HISTORY,
  IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY,
  IDENTITY_MANAGEMENT_LOAD_ACTIVITIES_HISTORY,
  IDENTITY_MANAGEMENT_UPDATE_ACTIVITIES_HISTORY,
  IDENTITY_MANAGEMENT_LOAD_NEGATIVE_EVENTS,
  IDENTITY_MANAGEMENT_PERSON_SEARCH_RESULTS_DIALOG,
  IDENTITY_MANAGEMENT_IDENTITY_LINK_CANDIDATE,
  IDENTITY_MANAGEMENT_LINK_CANDIDATE_TO_IDENTITY,
  IDENTITY_MANAGEMENT_PREPARE_IDENTITY_FOR_LINK,
  IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL,
  IDENTITY_MANAGEMENT_VALIDATE_LINK_CANDIDATE,
  IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_DELETE,
  IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_SEARCH,
} from 'Store/Constants/identityManagement';

export const loadActivityHistory = (identityId: string | number) => ({
  type: IDENTITY_MANAGEMENT_LOAD_HISTORY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: '/api/identities/history/',
      params: {
        identity: identityId,
      },
    },
  },
});

// eslint-disable-next-line camelcase
export const loadFailedEventsHistory = (email_address: string, client_name: string) => ({
  type: IDENTITY_MANAGEMENT_LOAD_NEGATIVE_EVENTS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: '/api/identities/history/',
      params: {
        email_address,
        status_type: 'failure',
        client: client_name,
      },
    },
  },
});

export const loadIdentitiesActivity = (fromDate: string | number, toDate: string | number) => ({
  type: IDENTITY_MANAGEMENT_LOAD_IDENTITIES_ACTIVITY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `/api/identities/activity/?${ toDate ? `created_before=${ toDate }` : '' }${ fromDate ? `&created_after=${ fromDate }` : '' }`,
    },
  },
});

export const loadIdentities = (personId: string | number) => ({
  type: IDENTITY_MANAGEMENT_LOAD_IDENTITIES,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `/api/identities/person/${ personId }/`,
    },
  },
});

export const setIdentityToBeActedOn = (identityId: string | number) => ({
  type: IDENTITY_MANAGEMENT_SET_IDENTITY_TO_ACT_ON,
  payload: {
    identityId,
  },
});

export const blockIdentity = (identityId: string | number) => ({
  type: IDENTITY_MANAGEMENT_BLOCK_IDENTITY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'PATCH',
      url: `/api/identities/${ identityId }/`,
      data: {
        is_blocked: true,
      },
    },
  },
});

export const unBlockIdentity = (identityId: string | number) => ({
  type: IDENTITY_MANAGEMENT_UNBLOCK_IDENTITY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'PATCH',
      url: `/api/identities/${ identityId }/`,
      data: {
        is_blocked: false,
      },
    },
  },
});

export const updateIdentityEmail = (identityId: string | number, newEmail: string) => ({
  type: IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'PATCH',
      url: `/api/identities/${ identityId }/`,
      data: {
        email_address: newEmail,
      },
    },
  },
});

export const updateActivityHistory = (url: string) => ({
  type: IDENTITY_MANAGEMENT_UPDATE_HISTORY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url,
    },
  },
});

export const createNewIdentity = (formValues: Object) => ({
  type: IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: '/api/identities/',
      data: {
        ...formValues,
      },
    },
  },
});

export const loadIdentitiesHistory = (dates: Object) => ({
  type: IDENTITY_MANAGEMENT_LOAD_ACTIVITIES_HISTORY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: '/api/identities/history',
      params: {
        created_before: dates.toDate,
        created_after: dates.fromDate,
      },
    },
  },
});

export const updateActivitiesHistory = (url: string) => ({
  type: IDENTITY_MANAGEMENT_UPDATE_ACTIVITIES_HISTORY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url,
    },
  },
});

export const setPersonSearchDialogVisible = (isVisible: boolean) => ({
  type: IDENTITY_MANAGEMENT_PERSON_SEARCH_RESULTS_DIALOG,
  payload: { isVisible },
});

export const setIdentityLinkCandidate = (person: Object) => ({
  type: IDENTITY_MANAGEMENT_IDENTITY_LINK_CANDIDATE,
  payload: { person },
});

export const prepareIdentityForLink = (personId: string | number) => ({
  type: IDENTITY_MANAGEMENT_PREPARE_IDENTITY_FOR_LINK,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `/api/identities/person/${ personId }/`,
    },
  },
});

export const linkCandidateToIdentity = (identityId: number, userId: string, redirectTo: string) => ({
  type: IDENTITY_MANAGEMENT_LINK_CANDIDATE_TO_IDENTITY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'PATCH',
      url: `/api/identities/${ identityId }/`,
      data: {
        user: userId,
      },
    },
    redirectTo,
  },
});

export const validateLinkCandidate = (personId: string | number) => ({
  type: IDENTITY_MANAGEMENT_VALIDATE_LINK_CANDIDATE,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `/api/identities/person/${ personId }/`,
    },
  },
});

export const orphanIdentityDelete = (authId: string, reference: string, legalEntityId: string) => {
  return {
    type: IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_DELETE,
    payload: {
      client: 'authenticatedClient',
      request: {
        method: 'POST',
        url: `/api/identities/${ authId }/clear/`,
        params: {
          reference,
          legal_entity_id: legalEntityId,
        },
      },
    },
  };
};

export const searchOrphanIdentities = (formValues: Object) => {
  return {
    type: IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_SEARCH,
    payload: {
      client: 'authenticatedClient',
      request: {
        method: 'GET',
        url: '/api/identities/',
        params: {
          ...formValues,
        },
      },
    },
  };
};
