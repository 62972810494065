import { rolesList } from "GlobalConfigs";

import {
  SEARCH_PERSON_BY_DETAILS,
  SEARCH_PERSON_BY_LOAN,
  LOAD_PERSON_CUSTOMER_REPORT,
  LOAD_PERSON_DSAR_REPORT,
  LOAD_PERSON_BANK_ACCOUNTS,
  LOAD_PERSON_LEGAL_DOCUMENT,
  LOAD_SELECTED_PERSON_FLAG,
  FIND_PERSON_LEGAL_DOCUMENT,
  PERSON_MANAGEMENT_REGENERATE_DOCUMENTS,
  PERSON_MANAGEMENT_LOAD_CACHE,
  PERSON_MANAGEMENT_CLEAR_CACHE,
  PERSON_MANAGEMENT_UPDATE_FLAG,
  PERSON_MANAGEMENT_LOAD_FLAG_HISTORY,
  // File uploader
  PERSON_MANAGEMENT_UPLOADFILE_OPTIONS,
  PERSON_MANAGEMENT_UPLOAD_FILE,
  // Address update
  LOAD_ADDRESS_DYNAMIC_DATA,
  PERSON_MANAGEMENT_UPDATE_ADDRESS,
  ADDRESS_MATCH,
  POLL_ADDRESS_MATCH,
  ADDRESS_FINDER_CLEAN,
  IDENTITY_VERIFICATION,
  POLL_IDENTITY_VERIFICATION,
  NAME_IDENTITY_VERIFICATION,
  POLL_NAME_IDENTITY_VERIFICATION,
  CLEAR_NAME_IDENTITY_VERIFICATION,
  PERSON_MANAGEMENT_URL,
  PERSON_MANAGEMENT_LOAD_PRODUCTS,
  SEARCH_IDENTITY_LINK_CANDIDATES,
  CHOSEN_LINK_CANDIDATE,
  LOAD_CHOSEN_LINK_CANDIDATE,
  // Nationalities
  GET_NATIONALITIES_LIST,
  // Holding Account
  PERSON_MANAGEMENT_HOLDING_ACCOUNT_WITHDRAWAL
} from "Store/Constants/personManagement";

// SEARCH FUNCTIONS
export const searchPersonByDetails = (
  personDetails: Record<string, unknown>
) => ({
  type: SEARCH_PERSON_BY_DETAILS,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `${PERSON_MANAGEMENT_URL}search/`,
      data: {
        ...personDetails,
      },
    },
  },
});

export const searchPersonByLoan = () => ({
  type: SEARCH_PERSON_BY_LOAN,
  payload: {
    request: {
      method: "GET",
      url: "/PersonManagement/searchByName.json",
    },
  },
});

// Find candidates to link an identity to the
export const searchIdentityLinkCandidates = (
  personDetails: Record<string, unknown>
) => ({
  type: SEARCH_IDENTITY_LINK_CANDIDATES,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `${PERSON_MANAGEMENT_URL}search/`,
      data: {
        ...personDetails,
      },
    },
  },
});

// set the chosen candidate to link identity to
export const setChosenIdentityLinkCandidate = (personDetails: {
  person_id: string | number;
  person_details: {
    first_name: string;
    last_name: string;
    date_of_birth: string;
  };
}) => ({
  type: CHOSEN_LINK_CANDIDATE,
  payload: {
    personDetails,
  },
});

// load the chosen candidate data
export const loadChosenIdentityLinkCandidate = (personId: string | number) => ({
  type: LOAD_CHOSEN_LINK_CANDIDATE,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: `${PERSON_MANAGEMENT_URL}${personId}/`,
    },
  },
});

// MAIN DATA
export const loadProducts = (personId: string | number) => ({
  type: PERSON_MANAGEMENT_LOAD_PRODUCTS,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: `${PERSON_MANAGEMENT_URL}${personId}/financial_accounts/`,
    },
  },
});

export const loadCustomerRecord = (personId: string | number) => ({
  type: LOAD_PERSON_CUSTOMER_REPORT,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: `${PERSON_MANAGEMENT_URL}${personId}/customer_record/download/`,
      responseType: "blob",
    },
  },
});

export const loadDSARRecord = (personId: string | number) => ({
  type: LOAD_PERSON_DSAR_REPORT,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: `${PERSON_MANAGEMENT_URL}${personId}/dpa_report/download/`,
      responseType: "blob",
    },
  },
});

export const loadPersonBankAccounts = (personId: string | number) => ({
  type: LOAD_PERSON_BANK_ACCOUNTS,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: `${PERSON_MANAGEMENT_URL}${personId}/bank_accounts/`,
      params: {
        person_id: personId,
      },
    },
  },
});

export const regeneratePersonDocuments = (
  personId: string | number,
  loanId: string | number,
  hostOrgId: string | number
) => ({
  type: PERSON_MANAGEMENT_REGENERATE_DOCUMENTS,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `${PERSON_MANAGEMENT_URL}${personId}/documents/regenerate/`,
      data: {
        loan_id: loanId,
        host_organisation_id: hostOrgId,
      },
    },
  },
});

export const loadPersonCache = (personId: string | number) => ({
  type: PERSON_MANAGEMENT_LOAD_CACHE,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: `${PERSON_MANAGEMENT_URL}${personId}/person_cache/`,
    },
  },
});

export const clearPersonCache = (personId: string | number) => ({
  type: PERSON_MANAGEMENT_CLEAR_CACHE,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `${PERSON_MANAGEMENT_URL}${personId}/person_cache/clear/`,
    },
    personId,
  },
});

export const clearPersonJourney = (
  personId: string | number,
  journeyUUID: string | number
) => ({
  type: PERSON_MANAGEMENT_CLEAR_CACHE,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `${PERSON_MANAGEMENT_URL}${personId}/person_cache/${journeyUUID}/clear/`,
    },
    personId,
  },
});

export const loadLegalDocumentForPerson = (
  personId: string | number,
  documentUUID: string | number,
  fileName: string,
  legalEntity: string | number
) => ({
  type: LOAD_PERSON_LEGAL_DOCUMENT,
  payload: {
    client: "authenticatedClient",
    request: {
      fileName,
      method: "GET",
      url: `${PERSON_MANAGEMENT_URL}${personId}/documents/${documentUUID}/download/`,
      params: {
        legal_entity_id: legalEntity,
      },
      responseType: "blob",
    },
  },
});

export const findLegalDocument = (personId: string | number) => ({
  type: FIND_PERSON_LEGAL_DOCUMENT,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: `${PERSON_MANAGEMENT_URL}${personId}/documents/`,
    },
  },
});

// PERSON FLAGS
export const submitPersonFlag = (
  personId: string | number,
  retrievalTimestamp: string,
  flag: Record<string, unknown>
) => ({
  type: PERSON_MANAGEMENT_UPDATE_FLAG,
  payload: {
    personId,
    client: "authenticatedClient",
    request: {
      method: "PATCH",
      url: `${PERSON_MANAGEMENT_URL}${personId}/`,
      data: {
        retrieval_timestamp: retrievalTimestamp,
        // TODO: fix database please, this is ridiculous
        person_flags: [{ ...flag }],
      },
    },
  },
});

export const submitPersonFlagSpecific = (
  personId: string | number,
  retrievalTimestamp: string,
  flag: Record<string, unknown>,
  extraUrl: string
) => ({
  type: PERSON_MANAGEMENT_UPDATE_FLAG,
  payload: {
    personId,
    client: "authenticatedClient",
    request: {
      method: "PATCH",
      url: `${PERSON_MANAGEMENT_URL}${personId}/${extraUrl}/`,
      data: {
        retrieval_timestamp: retrievalTimestamp,
        ...flag,
      },
    },
  },
});

export const loadPersonFlagHistory = (personId: string | number) => ({
  type: PERSON_MANAGEMENT_LOAD_FLAG_HISTORY,
  payload: {
    personId,
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: `/api/person_flag_history/${personId}/`,
    },
  },
});

// PERSON ADDRESS
export const loadAddressDynamicData = (personId: string | number) => ({
  type: LOAD_ADDRESS_DYNAMIC_DATA,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "OPTIONS",
      url: `api/person/${personId}/address_match/`,
    },
  },
});

export const addressMatch = (
  personId: string | number,
  data: Record<string, unknown>,
  entityID: string | number
) => ({
  type: ADDRESS_MATCH,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `api/person/${personId}/address_match/?legal_entity_id=${entityID}`,
      data: {
        ...data,
      },
    },
  },
});

export const pollAddressMatch = (refreshURI: string) => ({
  type: POLL_ADDRESS_MATCH,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: refreshURI,
    },
  },
});

export const identityVerification = (
  personId: string | number,
  address: string,
  consent: boolean,
  entityID: string | number,
  hostOrgId: string | number,
  stubs_mode = false
) => ({
  type: IDENTITY_VERIFICATION,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `api/person/${personId}/identity_verification/?legal_entity_id=${entityID}`,
      data: {
        host_organisation_id: hostOrgId,
        new_address: address,
        consent_given: consent,
        person_id: personId,
        stubs_mode,
      },
    },
  },
});

export const getNationalitiesList = () => ({
  type: GET_NATIONALITIES_LIST,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: "reference/nationality/",
    },
  },
});

export const pollIdentityVerification = (refreshURI: string) => ({
  type: POLL_IDENTITY_VERIFICATION,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: refreshURI,
    },
  },
});

export const nameIdentityVerification = (
  personId: string | number,
  consent: boolean,
  entityID: string | number,
  hostOrgId: string | number,
  stubs_mode = false,
  personDetails: {
      date_of_birth: string;
      date_of_death: string;
      first_name: string;
      last_name: string;
      person_title: string;
    }
) => ({
  type: NAME_IDENTITY_VERIFICATION,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `api/person/${personId}/identity_verification/?legal_entity_id=${entityID}`,
      data: {
        consent_given: consent,
        host_organisation_id: hostOrgId,
        person_id: personId,
        stubs_mode,
        person_details: {
          ...personDetails,
        },
      },
    },
  },
});

export const pollNameIdentityVerification = (refreshURI: string) => ({
  type: POLL_NAME_IDENTITY_VERIFICATION,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "GET",
      url: refreshURI,
    },
  },
});

export const clearNameIdentityVerification = () => ({
  type: CLEAR_NAME_IDENTITY_VERIFICATION,
});

export const cleanAddressMatch = () => ({
  type: ADDRESS_FINDER_CLEAN,
});

export const updatePersonAddress = (
  personId: string | number,
  retrievalTimestamp: string,
  newAddress: string,
  identityVerificationTicketId: string
) => ({
  type: PERSON_MANAGEMENT_UPDATE_ADDRESS,
  payload: {
    personId,
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `api/person/${personId}/address/`,
      data: {
        new_address: newAddress,
        person_id: personId,
        retrieval_timestamp: retrievalTimestamp,
        ...(identityVerificationTicketId && {
          ticket_id: identityVerificationTicketId,
        }),
      },
    },
  },
});

// FILE UPLOADER
export const getUploadFileOptions = (personId: string | number) => ({
  type: PERSON_MANAGEMENT_UPLOADFILE_OPTIONS,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "OPTIONS",
      url: `/api/person/${personId}/documents/`,
    },
  },
});

export const uploadFile = (
  personId: number | string,
  form: Record<string, unknown>,
  uploadedFile: string
) => {
  const data = new FormData();
  data.append("file", uploadedFile);
  Object.keys(form).forEach((key) => data.append(key, form[key]));
  return {
    type: PERSON_MANAGEMENT_UPLOAD_FILE,
    payload: {
      personId,
      client: "uploadFileCLientviaLegalEntity",
      request: {
        method: "POST",
        url: `/api/person/${personId}/documents/`,
        data,
      },
    },
  };
};

export const loadSelectedPersonFlags = (itemId: number | string) => ({
  type: LOAD_SELECTED_PERSON_FLAG,
  payload: {
    client: rolesList.PERSONMANAGEMENT.client,
    request: {
      method: "GET",
      url: `${rolesList.PERSONMANAGEMENT.roleURL}${itemId}/`,
    },
  },
});

export const triggerHoldingAccountWithdrawal = (
  personId: string | number,
  depositAccountId: string | number,
  firstName: string,
  lastName: string,
  entityId: string,
) => ({
  type: PERSON_MANAGEMENT_HOLDING_ACCOUNT_WITHDRAWAL,
  payload: {
    client: "authenticatedClient",
    request: {
      method: "POST",
      url: `${PERSON_MANAGEMENT_URL}${personId}/withdraw_funds/?legal_entity_id=${entityId}`,
      data: {
        deposit_account_id: depositAccountId,
        first_name: firstName,
        last_name: lastName
      },
    },
  },
});
