import { CreditDeclineOverturnsObjectType } from 'Types/creditDeclineOverturns';
import { rolesList } from 'GlobalConfigs';


import {
  SEARCH_DECISION,
  REFRESH_RESULTS,
  APPLY_DECISION,
} from 'Store/Constants/creditDeclineOverturn';

import {
  PENDING,
  SUCCESS,
  FAIL,
} from 'Store/Constants/axios';
import { Actions } from 'Store/Actions';

export const initialState = {
  selectedDecision: {},
  loadingSearchDecision: false,
  ticketQueryUrl: '',
};

let actionsMap = {};

if (rolesList.CREDITDECLINEOVERTURN && rolesList.CREDITDECLINEOVERTURN.systemRoleName) {
  const searchDecisionActionMap = {
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ SEARCH_DECISION }${ PENDING }`]: (state: CreditDeclineOverturnsObjectType) => ({
      ...state,
      selectedDecision: {},
      loadingSearchDecision: true,
    }),
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ SEARCH_DECISION }${ SUCCESS }`]: (state: CreditDeclineOverturnsObjectType, { payload: { data } }: any) => ({
      ...state,
      selectedDecision: data.task_status === 'SUCCESS' ? data.result : {},
      loadingSearchDecision: data.task_status !== 'SUCCESS',
    }),
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ SEARCH_DECISION }${ FAIL }`]: (state: CreditDeclineOverturnsObjectType) => ({
      ...state,
      loadingSearchDecision: false,
    }),
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ REFRESH_RESULTS }${ PENDING }`]: (state: CreditDeclineOverturnsObjectType) => ({
      ...state,
      selectedDecision: {},
      loadingSearchDecision: true,
    }),
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ REFRESH_RESULTS }${ SUCCESS }`]: (state: CreditDeclineOverturnsObjectType, { payload: { data } }: any) => ({
      ...state,
      selectedDecision: data.task_status === 'SUCCESS' ? data.result : {},
      loadingSearchDecision: data.task_status !== 'SUCCESS',
      ticketQueryUrl: data.refresh_url,
    }),
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ REFRESH_RESULTS }${ FAIL }`]: (state: CreditDeclineOverturnsObjectType) => ({
      ...state,
      loadingSearchDecision: false,
    }),
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ APPLY_DECISION }${ PENDING }`]: (state: CreditDeclineOverturnsObjectType) => ({
      ...state,
      loadingSearchDecision: true,
    }),
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ APPLY_DECISION }${ FAIL }`]: (state: CreditDeclineOverturnsObjectType) => ({
      ...state,
      loadingSearchDecision: false,
    }),
    [`${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ APPLY_DECISION }${ SUCCESS }`]: (state: CreditDeclineOverturnsObjectType) => ({
      ...state,
      loadingSearchDecision: false,
      ticketQueryUrl: '',
      selectedDecision: {},
    }),
  };

  actionsMap = {
    ...searchDecisionActionMap,
  };
}

export default function reducer(state: CreditDeclineOverturnsObjectType = initialState, action: Actions) {
  const fn: (state: CreditDeclineOverturnsObjectType, action: Actions) => CreditDeclineOverturnsObjectType = actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
