import type { DepositManagementStateType } from 'Types/depositManagement';

import {
  DEPOSIT_MANAGEMENT_SEARCH_DEPOSIT,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_SUMMARY,
  DEPOSIT_MANAGEMENT_LOAD_PENDING_APPROVAL,
  DEPOSIT_MANAGEMENT_ENQUIRE,
  DEPOSIT_MANAGEMENT_ACT,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_EVENTS,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_BALANCES,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_TRANSACTIONS,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_RATES,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LIMITS,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LOGS,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_MATURITY,
} from 'Store/Constants/depositManagement';

import { APPLICATION_UPDATE_ACTIVE_HOLE } from 'Store/Constants/application';

import {
  PENDING,
  SUCCESS,
  FAIL,
} from 'Store/Constants/axios';
import { Actions } from 'Store/Actions';

export const initialState: DepositManagementStateType = {
  loadingSearchResults: false,
  allPendingApproval: [],
  loadingAllPendingApproval: false,
  selectedDeposit: {},
  loadingSelectedDeposit: false,
  availableEvents: [],
  selectedDepositEvents: [],
  loadingSelectedDepositEvents: false,
  selectedDepositBalances: [],
  loadingSelectedDepositBalances: false,
  selectedDepositTransactions: [],
  loadingSelectedDepositTransactions: false,
  selectedDepositRates: [],
  loadingSelectedDepositRates: false,
  selectedDepositLimits: [],
  loadingSelectedDepositLimits: false,
  selectedDepositLogs: [],
  loadingSelectedDepositLogs: false,
  selectedDepositMaturity: {},
  loadingSelectedDepositMaturity: false,
  loadingSelectedDepositInformation: false,
  enquireResults: {},
  loadingEnquireResults: false,
  sendingAct: false,
  proposedChanges: [],
};

const searchDepositActionMap = {
  [DEPOSIT_MANAGEMENT_LOAD_PENDING_APPROVAL + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    allPendingApproval: [],
    loadingAllPendingApproval: true,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_PENDING_APPROVAL + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    allPendingApproval: data || [],
    loadingAllPendingApproval: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_PENDING_APPROVAL + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    loadingAllPendingApproval: false,
  }),

  // TODO: remove from usage once APP-5108 is addressed, this really bad cross module function, but due to BE restrictions has to be done
  [APPLICATION_UPDATE_ACTIVE_HOLE]: (state: DepositManagementStateType) => ({
    ...state,
    allPendingApproval: [],
    loadingAllPendingApproval: false,
  }),
  [DEPOSIT_MANAGEMENT_SEARCH_DEPOSIT + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDeposit: {},
    loadingSearchResults: true,
  }),
  [DEPOSIT_MANAGEMENT_SEARCH_DEPOSIT + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDeposit: data || {},
    loadingSearchResults: false,
  }),
  [DEPOSIT_MANAGEMENT_SEARCH_DEPOSIT + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSearchResults: false,
  }),

  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_SUMMARY + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDeposit: {},
    enquireResults: {},
    loadingSelectedDeposit: true,
    proposedChanges: [],
    selectedDepositEvents: [],
    selectedDepositBalances: [],
    selectedDepositTransactions: [],
    selectedDepositRates: [],
    selectedDepositLimits: [],
    selectedDepositLogs: [],
    selectedDepositMaturity: {},
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_SUMMARY + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDeposit: data || {},
    availableEvents: (data || {}).allowed_events || [],
    loadingSelectedDeposit: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_SUMMARY + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSelectedDeposit: false,
  }),
};

const eventManagementActionsMap = {
  [DEPOSIT_MANAGEMENT_ENQUIRE + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    enquireResults: {},
    loadingEnquireResults: true,
    proposedChanges: [],
  }),
  [DEPOSIT_MANAGEMENT_ENQUIRE + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    enquireResults: data,
    loadingEnquireResults: false,
    proposedChanges: data.pending_events,
  }),
  [DEPOSIT_MANAGEMENT_ENQUIRE + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    loadingEnquireResults: false,
  }),
};

const depositInformationActionsMap = {
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_EVENTS + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSelectedDepositEvents: true,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_EVENTS + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDepositEvents: (data || [])
      .sort((l: any, r: any) => l.deposit_account_event_schedule_id - r.deposit_account_event_schedule_id) || [],
    loadingSelectedDepositEvents: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_EVENTS + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDepositEvents: [],
    loadingSelectedDepositEvents: false,
  }),

  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_BALANCES + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSelectedDepositBalances: true,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_BALANCES + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDepositBalances: data || [],
    loadingSelectedDepositBalances: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_BALANCES + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDepositBalances: [],
    loadingSelectedDepositBalances: false,
  }),

  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_TRANSACTIONS + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSelectedDepositTransactions: true,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_TRANSACTIONS + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDepositTransactions: data || [],
    loadingSelectedDepositTransactions: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_TRANSACTIONS + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDepositTransactions: [],
    loadingSelectedDepositTransactions: false,
  }),

  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_RATES + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSelectedDepositRates: true,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_RATES + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDepositRates: data || [],
    loadingSelectedDepositRates: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_RATES + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDepositRates: [],
    loadingSelectedDepositRates: false,
  }),

  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LIMITS + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSelectedDepositLimits: true,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LIMITS + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDepositLimits: data.deposit_account_limits || [],
    loadingSelectedDepositLimits: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LIMITS + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDepositLimits: [],
    loadingSelectedDepositLimits: false,
  }),

  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LOGS + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSelectedDepositLogs: true,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LOGS + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDepositLogs: data || [],
    loadingSelectedDepositLogs: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LOGS + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDepositLogs: [],
    loadingSelectedDepositLogs: false,
  }),

  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_MATURITY + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    loadingSelectedDepositMaturity: true,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_MATURITY + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDepositMaturity: data || {},
    loadingSelectedDepositMaturity: false,
  }),
  [DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_MATURITY + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    selectedDepositMaturity: {},
    loadingSelectedDepositMaturity: false,
  }),
};

const eventActActionsMap = {
  [DEPOSIT_MANAGEMENT_ACT + PENDING]: (state: DepositManagementStateType) => ({
    ...state,
    sendingAct: true,
  }),
  [DEPOSIT_MANAGEMENT_ACT + SUCCESS]: (state: DepositManagementStateType, { payload: { data } }: any) => ({
    ...state,
    selectedDeposit: data || {},
    sendingAct: false,
    proposedChanges: [],
  }),
  [DEPOSIT_MANAGEMENT_ACT + FAIL]: (state: DepositManagementStateType) => ({
    ...state,
    sendingAct: false,
  }),
};

const actionsMap = {
  ...searchDepositActionMap,
  ...eventManagementActionsMap,
  ...depositInformationActionsMap,
  ...eventActActionsMap,
};

export default function reducer(state: DepositManagementStateType = initialState, action: Actions): DepositManagementStateType {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
