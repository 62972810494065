//@flow
export const DEPOSIT_MANAGEMENT_SEARCH_DEPOSIT = 'DEPOSIT_MANAGEMENT_SEARCH_DEPOSIT';
export const DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_SUMMARY = 'DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_SUMMARY';
export const DEPOSIT_MANAGEMENT_LOAD_PENDING_APPROVAL = 'DEPOSIT_MANAGEMENT_LOAD_PENDING_APPROVAL';
export const DEPOSIT_MANAGEMENT_ENQUIRE = 'DEPOSIT_MANAGEMENT_ENQUIRE';
export const DEPOSIT_MANAGEMENT_LOAD_FORM_OPTIONS = 'DEPOSIT_MANAGEMENT_LOAD_FORM_OPTIONS';
export const DEPOSIT_MANAGEMENT_ACT = 'DEPOSIT_MANAGEMENT_ACT';
export const DEPOSIT_MANAGEMENT_DRY_RUN = 'DEPOSIT_MANAGEMENT_DRY_RUN';
export const DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_EVENTS = 'DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_EVENTS';
export const DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_BALANCES = 'DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_BALANCES';
export const DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_TRANSACTIONS = 'DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_TRANSACTIONS';
export const DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_RATES = 'DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_RATES';
export const DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LIMITS = 'DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LIMITS';
export const DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LOGS = 'DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LOGS';
export const DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_MATURITY = 'DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_MATURITY';
