import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// Middleware & Axios
import { multiClientMiddleware } from "redux-axios-middleware";
import { clients } from "Store/Api/axios";
import YobotaMiddleware from "Store/Middleware/";

import reducers from "./Reducers";

const middleware = applyMiddleware(
  thunk,
  // axios majic
  multiClientMiddleware(clients),
  // Yobota middleware
  YobotaMiddleware.tokenMiddleware,
  YobotaMiddleware.notificationCenter,
  YobotaMiddleware.apiResponseProcessor,
  YobotaMiddleware.specialRequestsMiddleware,
  YobotaMiddleware.unauthorisedMiddleware,
  YobotaMiddleware.creditDeclineOverturns,
  YobotaMiddleware.cardPayments,
  YobotaMiddleware.updateAddress,
  YobotaMiddleware.updateName
);

const store = createStore(reducers, composeWithDevTools(middleware));

// Store types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// REACT HOOKS
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// TODO => Legacy export configure store is no longer dinamically generated
export default () => store;
