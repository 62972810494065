import {LOAD_FORM_DATA} from "Store/Constants/generic";
import {INBOUND_PAYMENT_TRANSACTIONS_SEARCH} from "Store/Constants/inboundPaymentTransactions";

import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";

import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

type InboundPaymentTransactionsState = {
  allTransactions: any[];
  loadingTransactions: boolean;
  dynamicData: Record<string, unknown>;
  loadingDynamicData: boolean;
};

export const initialState: InboundPaymentTransactionsState = {
  allTransactions: [],
  loadingTransactions: false,
  dynamicData: {},
  loadingDynamicData: false,
};

let actionsMap: Record<
  string,
  (
    state: InboundPaymentTransactionsState,
    action: Actions
  ) => InboundPaymentTransactionsState
> = {};

if (
  rolesList.INBOUNDPAYMENTTRANSACTIONMANAGEMENT &&
  rolesList.INBOUNDPAYMENTTRANSACTIONMANAGEMENT.systemRoleName
) {
  const transactionsActionMap = {
    [INBOUND_PAYMENT_TRANSACTIONS_SEARCH + PENDING]: (
      state: InboundPaymentTransactionsState
    ) => ({
      ...state,
      allTransactions: [],
      loadingTransactions: true,
    }),
    [INBOUND_PAYMENT_TRANSACTIONS_SEARCH + SUCCESS]: (
      state: InboundPaymentTransactionsState,
      {payload: {data}}: any
    ) => ({
      ...state,
      allTransactions: data || [],
      loadingTransactions: false,
    }),
    [INBOUND_PAYMENT_TRANSACTIONS_SEARCH + FAIL]: (
      state: InboundPaymentTransactionsState
    ) => ({
      ...state,
      loadingTransactions: false,
    }),
  };
  const formDataActionMap = {
    [`${rolesList.INBOUNDPAYMENTTRANSACTIONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${PENDING}`]: (
      state: InboundPaymentTransactionsState
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${rolesList.INBOUNDPAYMENTTRANSACTIONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${SUCCESS}`]: (
      state: InboundPaymentTransactionsState,
      {payload: {data}}: any
    ) => ({
      ...state,
      dynamicData: {
        ...(data.actions || {}).POST,
      },
      loadingDynamicData: false,
    }),
    [`${rolesList.INBOUNDPAYMENTTRANSACTIONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${FAIL}`]: (
      state: InboundPaymentTransactionsState
    ) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
      },
      loadingDynamicData: false,
    }),
  };

  actionsMap = {
    ...transactionsActionMap,
    ...formDataActionMap,
  };
}

export default function reducer(
  state: InboundPaymentTransactionsState = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
