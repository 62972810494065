import {LOAD_ALL, LOAD_ONE, LOAD_FORM_DATA} from "Store/Constants/generic";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

type AccountingState = {
  overview: {
    all: Record<string, unknown>;
    loadingData: boolean;
  };
  chartOfAccounts: {
    all: any[];
    loadingData: boolean;
    dynamicData: Record<string, unknown>;
    loadingDynamicData: boolean;
  };
  template: {
    all: any[];
    dynamicData: Record<string, unknown>;
    dynamicDataChildList: {
      default: Record<string, unknown>;
    };
    loadingData: boolean;
    loadingDynamicData: boolean;
    selected: Record<string, unknown>;
  };
};

export const initialState: AccountingState = {
  overview: {
    all: {},
    loadingData: false,
  },
  chartOfAccounts: {
    all: [],
    dynamicData: {},
    loadingData: false,
    loadingDynamicData: false,
  },
  template: {
    all: [],
    dynamicData: {},
    dynamicDataChildList: {
      default: {},
    },
    loadingData: false,
    loadingDynamicData: false,
    selected: {},
  },
};

let actionsMap: Record<
  string,
  (state: AccountingState, action: Actions) => AccountingState
> = {};

if (rolesList.ACCOUNTING && rolesList.ACCOUNTING.systemRoleName) {
  const {systemRoleName} = rolesList.ACCOUNTING;

  const loadAllMap = {
    [systemRoleName + LOAD_ALL + PENDING]: (
      state: AccountingState,
      action: any
    ) => {
      if (action?.payload?.request?.url.includes("batch")) {
        return {
          ...state,
          overview: {
            ...state.overview,
            all: {},
            loadingData: true,
          },
        };
      }

      if (action?.payload?.request?.url.includes("chart_of_accounts")) {
        return {
          ...state,
          chartOfAccounts: {
            ...state.chartOfAccounts,
            all: [],
            loadingData: true,
          },
        };
      }

      if (action?.payload?.request?.url.includes("template")) {
        return {
          ...state,
          template: {
            ...state.template,
            all: [],
            loadingData: true,
          },
        };
      }

      return state;
    },
    [systemRoleName + LOAD_ALL + SUCCESS]: (
      state: AccountingState,
      {
        payload: {
          data,
          config: {url},
        },
      }: any
    ) => {
      if (url.includes("batch")) {
        return {
          ...state,
          overview: {
            ...state.overview,
            all: data || [],
            loadingData: false,
          },
        };
      }

      if (url.includes("chart_of_accounts")) {
        return {
          ...state,
          chartOfAccounts: {
            ...state.chartOfAccounts,
            all: data || [],
            loadingData: false,
          },
        };
      }

      if (url.includes("template")) {
        return {
          ...state,
          template: {
            ...state.template,
            all: data || [],
            loadingData: false,
          },
        };
      }
      return state;
    },
    [systemRoleName + LOAD_ALL + FAIL]: (
      state: AccountingState,
      action: any
    ) => {
      if (action?.error?.config?.url.includes("batch")) {
        return {
          ...state,
          overview: {
            ...state.overview,
            all: {},
            loadingData: false,
          },
        };
      }

      if (action?.error?.config?.url.includes("chart_of_accounts")) {
        return {
          ...state,
          chartOfAccounts: {
            ...state.chartOfAccounts,
            all: [],
            loadingData: false,
          },
        };
      }

      if (action?.error?.config?.url.includes("template")) {
        return {
          ...state,
          template: {
            ...state.template,
            all: [],
            loadingData: false,
          },
        };
      }
      return state;
    },
  };

  const loadOneMap = {
    [systemRoleName + LOAD_ONE + PENDING]: (
      state: AccountingState,
      action: any
    ) => {
      if (action?.payload?.request?.url.includes("template")) {
        return {
          ...state,
          template: {
            ...state.template,
            selected: {},
            loadingData: true,
          },
        };
      }
      return state;
    },
    [systemRoleName + LOAD_ONE + SUCCESS]: (
      state: AccountingState,
      {
        payload: {
          data,
          config: {url},
        },
      }: any
    ) => {
      if (url.includes("template")) {
        return {
          ...state,
          template: {
            ...state.template,
            selected: data,
            loadingData: false,
          },
        };
      }
      return state;
    },
    [systemRoleName + LOAD_ONE + FAIL]: (
      state: AccountingState,
      action: any
    ) => {
      if (action?.error?.config?.url.includes("template")) {
        return {
          ...state,
          template: {
            ...state.template,
            selected: {},
            loadingData: false,
          },
        };
      }
      return state;
    },
  };

  const formDataActionMap = {
    [systemRoleName + LOAD_FORM_DATA + PENDING]: (
      state: AccountingState,
      action: any
    ) => {
      if (action?.payload?.request?.url.includes("chart_of_accounts")) {
        return {
          ...state,
          chartOfAccounts: {
            ...state.chartOfAccounts,
            loadingDynamicData: true,
          },
        };
      }
      if (action?.payload?.request?.url.includes("template")) {
        return {
          ...state,
          template: {
            ...state.template,
            loadingDynamicData: true,
          },
        };
      }
      return state;
    },
    [systemRoleName + LOAD_FORM_DATA + SUCCESS]: (
      state: AccountingState,
      action: any
    ) => {
      const {
        payload: {
          data,
          config: {url},
        },
      } = action;
      if (url.includes("chart_of_accounts")) {
        return {
          ...state,
          chartOfAccounts: {
            ...state.chartOfAccounts,
            dynamicData: {
              ...(data.actions || {}).POST,
            },
            loadingDynamicData: false,
          },
        };
      }
      if (
        url.includes("template") &&
        !action?.payload?.config?.params?.host_organisation_id
      ) {
        return {
          ...state,
          template: {
            ...state.template,
            dynamicData: {
              ...(data.actions || {}).POST,
            },
            dynamicDataChildList: {
              ...state.template.dynamicDataChildList,
              default: {
                ...(data.actions || {}).POST,
              },
            },
            loadingDynamicData: false,
          },
        };
      }
      if (
        url.includes("template") &&
        action?.payload?.config?.params?.host_organisation_id
      ) {
        const newHostId = action?.payload?.config?.params?.host_organisation_id;
        return {
          ...state,
          template: {
            ...state.template,
            dynamicData: {
              ...(data.actions || {}).POST,
            },
            dynamicDataChildList: {
              ...state.template.dynamicDataChildList,
              [newHostId]: {
                ...(data.actions || {}).POST,
              },
            },
            loadingDynamicData: false,
          },
        };
      }
      return state;
    },
    [systemRoleName + LOAD_FORM_DATA + FAIL]: (
      state: AccountingState,
      action: any
    ) => {
      if (action?.error?.config?.url.includes("chart_of_accounts")) {
        return {
          ...state,
          chartOfAccounts: {
            ...state.chartOfAccounts,
            loadingDynamicData: false,
          },
        };
      }

      if (action?.error?.config?.url.includes("template")) {
        return {
          ...state,
          template: {
            ...state.template,
            loadingDynamicData: false,
          },
        };
      }
      return state;
    },
  };

  actionsMap = {
    ...loadAllMap,
    ...loadOneMap,
    ...formDataActionMap,
  };
}

export default function reducer(
  state: AccountingState = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
