//@flow
/* eslint-disable prefer-destructuring */
import {
  LOAN_MANAGEMENT_CARD_PAYMENTS,
  LOAN_MANAGEMENT_CARD_PAYMENTS_REFRESH_RESULTS,
} from 'Store/Constants/loanManagement';
import { SUCCESS } from 'Store/Constants/axios';
import { refreshLoanManagementCardPayment } from 'Store/Actions/loanManagement';

type Action = {
  type: string,
  payload: {
    data: {
      refresh_url: string,
      task_status: string,
      state: string,
    },
  },
  meta: {
    previousAction: {
      payload: {
        redirectTo: string,
      },
    },
  },
};

export default (store: Object) => (next: Function) => (action: Action) => {
  switch (action.type) {
    case LOAN_MANAGEMENT_CARD_PAYMENTS + SUCCESS:
    case LOAN_MANAGEMENT_CARD_PAYMENTS_REFRESH_RESULTS + SUCCESS:
      if (action.payload && action.payload.data && action.payload.data.refresh_url) {
        setTimeout(
          () => {
            store.dispatch(
              refreshLoanManagementCardPayment(
                action.payload.data.refresh_url,
                action.payload.data.task_status,
                action.payload.data.state,
                action.meta.previousAction.payload.redirectTo
              )
            );
          },
          2000
        );
      }
      return next(action);

    default:
      return next(action);
  }
};
