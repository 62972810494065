import appSettings from 'GlobalConfigs/settings';

const baseUrl = appSettings.REACT_APP_BASE_URL;
const jwtExpiry = appSettings.REACT_APP_JWT_EXPIRY_MINUTES;

export const APPLICATION_UPDATE_LOCATION = 'APPLICATION_UPDATE_LOCATION';
export const APPLICATION_UPDATE_TOUR_STEPS = 'APPLICATION_UPDATE_TOUR_STEPS';
export const APPLICATION_UPDATE_ACTIVE_HOLE = 'APPLICATION_UPDATE_ACTIVE_HOLE';
export const APPLICATION_CHANGE_PAGE = 'APPLICATION_CHANGE_PAGE';

export const APPLICATION_TOGGLE_TOUR = 'APPLICATION_TOGGLE_TOUR';

export const BASE_URL = baseUrl || 'https://chetwood-staging-staff-api.herokuapp.com';
export const JWT_EXPIRY_MINUTES = Number(jwtExpiry) || 30;
