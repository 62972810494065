import { env } from "../env";

const appSettings = {
  REACT_APP_BASE_URL: env.REACT_APP_BASE_URL,
  REACT_APP_GIT_COMMIT: env.REACT_APP_GIT_COMMIT,
  REACT_APP_ENVIRONMENT: env.REACT_APP_ENVIRONMENT,
  REACT_APP_SUPRESS_ERRORS: env.REACT_APP_SUPRESS_ERRORS,
  REACT_APP_JWT_EXPIRY_MINUTES: env.REACT_APP_JWT_EXPIRY_MINUTES,
  REACT_APP_AUTH0_CLIENTID: env.REACT_APP_AUTH0_CLIENTID,
  REACT_APP_AUTH0_DOMAIN: env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_ALLOWED_CONNECTIONS: env.REACT_APP_AUTH0_ALLOWED_CONNECTIONS,
  REACT_APP_SENTRY_DSN: env.REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_RELEASE: env.REACT_APP_SENTRY_RELEASE,
  REACT_APP_GA_TRACKING: env.REACT_APP_GA_TRACKING,
  HOTJAR_ID: env.REACT_APP_HOTJAR_ID,
  REACT_APP_WORLDPAY_KEY: env.REACT_APP_WORLDPAY_KEY,
  REACT_APP_LENDING_DISABLED_HO: env.REACT_APP_LENDING_DISABLED_HO,
  REACT_APP_SAVINGS_DISABLED_HO: env.REACT_APP_SAVINGS_DISABLED_HO,
  REACT_APP_HOLDING_ACCOUNT_DOCUMENTS_BASE_URL: env.REACT_APP_HOLDING_ACCOUNT_DOCUMENTS_BASE_URL,
  REACT_APP_MAMBU_BASE_URL: env.REACT_APP_MAMBU_BASE_URL,
  REACT_APP_SENTRY_IGNORED_STATUS_CODES: (
    env.REACT_APP_SENTRY_IGNORED_STATUS_CODES || ""
  )
    .replace(/ /g, "")
    .split(","),
  REACT_APP_SOMETHING_COOL: env.REACT_APP_SOMETHING_COOL,
  REACT_APP_ENABLED_COMS_BUSINESS_RULES:
    env.REACT_APP_ENABLED_COMS_BUSINESS_RULES === "true",
  REACT_APP_OPEN_ENDED_DEPOSIT: env.REACT_APP_OPEN_ENDED_DEPOSIT === "true",
  REACT_APP_NOTICE_PERIOD_DEPOSIT:
    env.REACT_APP_NOTICE_PERIOD_DEPOSIT === "true",
  // TODO: remove from env once done
  REACT_APP_UPLOAD_FILE_PERSONMANAGEMENT:
    env.REACT_APP_UPLOAD_FILE_PERSONMANAGEMENT === "true",
  REACT_APP_UPDATE_ADDRESS_PERSONMANAGEMENT:
    env.REACT_APP_UPDATE_ADDRESS_PERSONMANAGEMENT === "true",
  REACT_APP_DISPLAY_LOAN_SEGMENTS:
    env.REACT_APP_DISPLAY_LOAN_SEGMENTS === "true",
  REACT_APP_IDV_REQUIRED: env.REACT_APP_IDV_REQUIRED === "true",
  REACT_APP_APP_CONFIG: env.REACT_APP_APP_CONFIG,
  REACT_APP_MOCK_SERVER: env.REACT_APP_MOCK_SERVER === "true",
  REACT_APP_TROLL: env.REACT_APP_TROLL,
  environment: env.NODE_ENV,
};

export default appSettings;
