import {
  LOAD_ALL,
  LOAD_FORM_DATA,
  LOAD_ONE,
  UPDATE_ONE,
  CREATE,
} from "Store/Constants/generic";
import {ProfilesStateType} from "Types/profiles";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

export const initialState: ProfilesStateType = {
  all: [],
  selectedProfile: {},
  dynamicData: {
    profile_owner_legal_entity_id: [],
    profile_recipient_legal_entity_id: [],
    permissions: {},
  },
  loadingDynamicData: false,
  loadingProfileData: false,
  sendingProfileData: false,
  message: "",
};

let actionsMap: Record<
  string,
  (state: ProfilesStateType, action: Actions) => ProfilesStateType
> = {};

if (rolesList.PROFILEMANAGEMENT && rolesList.PROFILEMANAGEMENT.systemRoleName) {
  const profilesActionsMap = {
    // Load all profiles
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_ALL}${PENDING}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      all: [],
      loadingProfileData: true,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_ALL}${SUCCESS}`]: (
      state: ProfilesStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      all: data || [],
      loadingProfileData: false,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_ALL}${FAIL}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      all: [],
      loadingProfileData: false,
    }),

    // Load one profile
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_ONE}${PENDING}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      selectedProfile: {},
      loadingProfileData: true,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: ProfilesStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      selectedProfile: data || [],
      loadingProfileData: false,
      message: "",
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_ONE}${FAIL}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      selectedProfile: {},
      loadingProfileData: false,
    }),

    // Update one profile
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${CREATE}${PENDING}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      sendingProfileData: true,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${CREATE}${SUCCESS}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      sendingProfileData: false,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${CREATE}${FAIL}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      sendingProfileData: false,
    }),

    // Update one profile
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${UPDATE_ONE}${PENDING}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      sendingProfileData: true,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${UPDATE_ONE}${SUCCESS}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      sendingProfileData: false,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${UPDATE_ONE}${FAIL}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      sendingProfileData: false,
    }),
  };

  const formDataActionMap = {
    // Load all features
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${PENDING}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${SUCCESS}`]: (
      state: ProfilesStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      dynamicData: (data.actions || {}).POST,
      loadingDynamicData: false,
    }),
    [`${rolesList.PROFILEMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${FAIL}`]: (
      state: ProfilesStateType
    ) => ({
      ...state,
      dynamicData: {},
      loadingDynamicData: false,
    }),
  };

  actionsMap = {
    ...profilesActionsMap,
    ...formDataActionMap,
  };
}

export default function reducer(
  state: ProfilesStateType = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
