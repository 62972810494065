import {
  LOAD_ALL,
  LOAD_ONE,
  UPDATE_ONE,
  CREATE,
  LOAD_FORM_DATA,
} from "Store/Constants/generic";
import { PENDING, SUCCESS, FAIL } from "Store/Constants/axios";
import { FeeStateType } from "Types/feeBuilder";
import { rolesList } from "GlobalConfigs";
import { Actions } from "Store/Actions";

export const initialState: FeeStateType = {
  all: [],
  selected: {},
  loadingData: false,
  sendingData: false,
  loadingDynamicData: false,
  dynamicData: {},
  message: "",
};

let actionsMap = {};

if (rolesList.FEECONFIGURATION && rolesList.FEECONFIGURATION.systemRoleName) {
  const { systemRoleName } = rolesList.FEECONFIGURATION;
  const feesMap = {
    [`${systemRoleName}${LOAD_ALL}${PENDING}`]: (state: FeeStateType) => ({
      ...state,
      all: [],
      loadingData: true,
    }),
    [`${systemRoleName}${LOAD_ALL}${SUCCESS}`]: (
      state: FeeStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      all: data || [],
      loadingData: false,
    }),
    [`${systemRoleName}${LOAD_ALL}${FAIL}`]: (state: FeeStateType) => ({
      ...state,
      all: [],
      loadingData: false,
    }),
    [`${systemRoleName}${LOAD_ONE}${PENDING}`]: (state: FeeStateType) => ({
      ...state,
      selected: {},
      loadingData: true,
    }),
    [`${systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: FeeStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      selected: data || {},
      loadingData: false,
      message: "",
    }),
    [`${systemRoleName}${LOAD_ONE}${FAIL}`]: (state: FeeStateType) => ({
      ...state,
      selected: {},
      loadingData: false,
    }),
    [`${systemRoleName}${CREATE}${PENDING}`]: (state: FeeStateType) => ({
      ...state,
      sendingData: true,
    }),
    [`${systemRoleName}${CREATE}${SUCCESS}`]: (state: FeeStateType) => ({
      ...state,
      sendingData: false,
    }),
    [`${systemRoleName}${CREATE}${FAIL}`]: (state: FeeStateType) => ({
      ...state,
      sendingData: false,
    }),
    [`${systemRoleName}${UPDATE_ONE}${PENDING}`]: (state: FeeStateType) => ({
      ...state,
      sendingData: true,
    }),
    [`${systemRoleName}${UPDATE_ONE}${SUCCESS}`]: (state: FeeStateType) => ({
      ...state,
      sendingData: false,
    }),
    [`${systemRoleName}${UPDATE_ONE}${FAIL}`]: (state: FeeStateType) => ({
      ...state,
      sendingData: false,
    }),
  };

  const formDataActionMap = {
    [`${systemRoleName}${LOAD_FORM_DATA}${PENDING}`]: (
      state: FeeStateType
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${systemRoleName}${LOAD_FORM_DATA}${SUCCESS}`]: (
      state: FeeStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      dynamicData: {
        ...(data.actions || {}).POST,
      },
      loadingDynamicData: false,
    }),
    [`${systemRoleName}${LOAD_FORM_DATA}${FAIL}`]: (state: FeeStateType) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
      },
      loadingDynamicData: false,
    }),
  };

  actionsMap = {
    ...feesMap,
    ...formDataActionMap,
  };
}

export default function reducer(
  state: FeeStateType = initialState,
  action: Actions
) {
  const fn: (state: FeeStateType, action: Actions) => FeeStateType =
    actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
