//@flow
import {
  NOTIFICATION_CREATE,
  NOTIFICATION_DELETE,
  POPUP,
} from 'Store/Constants/notificationCenter';
import { toast } from 'react-toastify';
//$FlowFixMe
import history from 'RouterHistory';
import type { Node } from 'react';

let count = 0;
/**
 * createNotification - Add a new notification to the array and increment total number of displayed notifications
 *
 * @param {Object} options  Notification details
 *
 * @return {action}         Action creator
 */
const createNotification = (options: Object) => ({
  ...options,
  id: count++,
});

/**
 * addNotification - Display a new notification on the screen
 *
 * @param {object} [options={}]   Notification details
 *
 * @return {action}               Action creator
 */
export const addNotification = (options: Object = {}) => ({
  type: NOTIFICATION_CREATE,
  payload: createNotification(options),
});

/**
 * removeNotification - Remove a particular notification from the screen
 *
 * @param {number | string} id  Notification ID for the notification to be removed
 *
 * @return {action}             Action creator
 */
export const removeNotification = (id: number | string) => ({
  type: NOTIFICATION_DELETE,
  payload: id,
});

/**
 * addToast - Display a new toast on the screen
 *
 * @param type
 * @param message
 * @param {object} [options={}]   Notification details
 *
 * @return {action}               Action creator
 */
export const addToast = (type: 'success' | 'warning' | 'error' | 'info' = 'success', message: string | Node = '', options: Object = {}) => {
  // If router redirect is required, push correct route
  if (options.redirectURL) {
    history.push(options.redirectURL);
  }
  if (type === 'success') {
    toast.success(message, options);
  }
  if (type === 'warning') {
    toast.warn(message, options);
  }
  if (type === 'error') {
    toast.error(message, options);
  }
  if (type === 'info') {
    toast.info(message, options);
  }
};

export const showPopup = (handleCancel: Function, params: any) => ({
  type: POPUP,
  handleCancel,
  params,
});
