import JWTDecode from "jwt-decode";
import moment from "moment";
import { jwt, profile, refreshtoken, token } from "Store/Constants/auth";
import { LocalAuthProfile, LocalAuthTokens } from "Types/auth";

type DecodedJWTToken = {
  exp: number;
};

export const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

export const getLocalJWTToken = (): string | null => {
  return localStorage.getItem(jwt);
};

export const getLocalRefreshToken = (): string | null => {
  return localStorage.getItem(refreshtoken);
};

export const getLocalUserInfo = (): LocalAuthProfile => {
  return JSON.parse(localStorage.getItem(profile) || "{}");
};

export const setLocalAuth0Info = (
  profileData: LocalAuthProfile,
  tokenData: LocalAuthTokens
): void => {
  localStorage.setItem(token, JSON.stringify(tokenData));
  localStorage.setItem(profile, JSON.stringify(profileData));
};

export const setLocalJWTToken = (jwtToken: string) => {
  localStorage.setItem(jwt, jwtToken)
}

export const setLocalRefreshToken = (refreshToken: string) => {
  localStorage.setItem(refreshtoken, refreshToken);

}

export const checkIfTokensExist = () => {
  const jwtToken = getLocalJWTToken();
  const refreshToken = getLocalRefreshToken();

  if (jwtToken && refreshToken) {
    const jwtExpiryDate = moment.unix(JWTDecode<DecodedJWTToken>(jwtToken).exp);
    const refreshExpiryDate = moment.unix(
      JWTDecode<DecodedJWTToken>(refreshToken).exp
    );
    const now = moment();

    const timeToExpirationJWT = moment
      .duration(jwtExpiryDate.diff(now))
      .asSeconds();
    const timeToExpirationRefresh = moment
      .duration(refreshExpiryDate.diff(now))
      .asSeconds();
    if (timeToExpirationJWT < 0 && timeToExpirationRefresh < 0) return false;

    return true;
  }

  return false;
};

export const retreiveTokensFromStorage = () => {
  if (getLocalJWTToken()) {
    return {
      jwt: getLocalJWTToken(),
      refreshToken: getLocalRefreshToken(),
    };
  }
  return {
    jwt: "",
    refreshToken: "",
  };
};

export const shouldRefreshAccessToken = () => {
  const jwtToken = getLocalJWTToken();
  const refreshToken = getLocalRefreshToken();

  if (refreshToken && jwtToken) {
    const jwtExpiryDate = moment.unix(JWTDecode<DecodedJWTToken>(jwtToken).exp);
    const refreshExpiryDate = moment.unix(
      JWTDecode<DecodedJWTToken>(refreshToken).exp
    );
    const now = moment();

    const timeToExpirationJWT = moment
      .duration(jwtExpiryDate.diff(now))
      .asSeconds();
    const timeToExpirationRefresh = moment
      .duration(refreshExpiryDate.diff(now))
      .asSeconds();
    if (timeToExpirationJWT < 600 && timeToExpirationRefresh > 0) return true; // if less than 10 minutes are left
  }

  return false;
};
