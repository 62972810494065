//@flow
import { find } from 'fpx';

type HoLe = {
  host_organisation_id: string | number,
  legal_entity_id: string | number,
  name: string,
};

export const formatEntity = (entity: HoLe) => {
  return {
    ...entity,
    id: entity.legal_entity_id,
    shortCode: entity.name.toLocaleLowerCase().replace(/ /g, '_'),
    title: entity.name,
    logo: `/images/${ entity.name.toLocaleLowerCase().replace(/ /g, '_') }_logo.png`,
    objectType: 'entity',
  };
};

export const formatHost = (host: HoLe) => {
  return {
    ...host,
    id: host.host_organisation_id,
    shortCode: host.name.toLocaleLowerCase().replace(/ /g, '_'),
    title: host.name,
    objectType: 'host',
  };
};

export const formatHostsForSelect = (hostOrgs: Object) => {
  return hostOrgs.reduce((acc: Object, cur: Object) => {
    acc.push({
      id: cur.host_organisation_id,
      value: cur.name,
    });

    return acc;
  }, []);
};

type Profile = {
  permissions: Array<Object>,
  profile_recipient_legal_entity_id: number,
};
// Cleans up redux form data for profiles object before submit
export const formatProfilesBeforeSubmit = (profile: Profile) => {
  const newPermissions = [];

  profile.permissions.forEach((permission: Object) => {
    if (permission.read_access || permission.write_access) {
      newPermissions.push({
        system_role_id: permission.system_role_id,
        access_type_is_read: permission.read_access,
        access_type_is_write: permission.write_access,
        permission_legal_entity_id: profile.profile_recipient_legal_entity_id,
      });
    }
  });

  const newValues = {
    ...profile,
    permissions: [...newPermissions],
  };

  return newValues;
};

// Cleans up incoming api data
export const removeSets = (object: any) => {
  let newObject = object;
  let keys = null;

  if (object !== null && typeof object === 'object' && !Array.isArray(object)) {
    newObject = { ...object };
    keys = Object.keys(newObject);
  } else if (object !== null && Array.isArray(object)) {
    newObject = [...object];
    keys = [...object];
  }

  (keys || []).forEach((key: string) => {
    if (newObject[key] !== null && typeof newObject[key] === 'object' && !Array.isArray(newObject[key])) {
      if (newObject[key].sets) {
        newObject[key] = [...newObject[key].sets];
      }
      newObject[key] = removeSets(newObject[key]);
    }

    if (newObject[key] !== null && Array.isArray(newObject[key])) {
      let newArray = [];
      newObject[key].forEach((arrayElem: any, index: number) => {
        newArray = [...newArray, removeSets(newObject[key][index])];
      });
      newObject[key] = [...newArray];
    }
  });

  return newObject;
};

// Removes sensitive data from sentry logs
const ALLOWED_DATA_OBJECTS = [
  // app reducers
  'application',
  'permissions',
  'allPermissions',
  'employeeId',
  // LE roles
  'profiles',
  // HO roles
  'fundingBuckets',
  'portfolios',
  'loanManagement',
  'loanProducts',
  'depositManagement',
  'depositProducts',
  // specific keys
  'person_id',
  // action keys
  'type',
  'error',
  'meta',
];

export const removeDataValues = (object: any) => {
  let newObject = object;
  let keys = null;

  if (object !== null && typeof object === 'object' && !Array.isArray(object)) {
    newObject = { ...object };
    keys = Object.keys(newObject);
  } else if (object !== null && Array.isArray(object)) {
    newObject = [...object];
    keys = [...object];
  }

  (keys || [])
    .filter((key: string) => !ALLOWED_DATA_OBJECTS.includes(key))
    .forEach((key: string) => {
      if (newObject[key] !== null) {
        if (typeof newObject[key] === 'object' && !Array.isArray(newObject[key])) {
          // If is object
          newObject[key] = removeDataValues(newObject[key]);
        } else if (Array.isArray(newObject[key])) {
          // If is array
          let newArray = [];
          newObject[key].forEach((arrayElem: any, index: number) => {
            newArray = [...newArray, removeDataValues(newObject[key][index])];
          });
          newObject[key] = [...newArray];
        } else if (!Array.isArray(newObject[key]) && typeof newObject[key] !== 'object') {
          // If is something else
          newObject[key] = typeof newObject[key];
        }
      }
    });

  return newObject;
};

// Parse OPTIONS request for dynamic data
export const parseDynamicData = (object: any) => {
  if (object.actions && object.actions.POST) {
    const fields = object.actions.POST;
    const fieldsList = Object.keys(fields);
    let dynamicData = {};

    fieldsList.forEach((fieldName: string) => {
      if (fields[fieldName] && fields[fieldName].type && fields[fieldName].type === 'choice') {
        if (fields[fieldName].choices && fields[fieldName].choices.length) {
          dynamicData = {
            ...dynamicData,
            [fieldName]: fields[fieldName].choices.map((choice: Object) => ({
              id: choice.value,
              value: choice.display_name,
            })),
          };
        }
      }
    });
    return dynamicData;
  }
  return {};
};

export const convertDynamicData = (options: Object): Array<Object> => {
  return ((options || {}).choices || []).map((option: Object) => {
    if (option.type) {
      return ({
        id: option.value,
        value: option.display_name,
        type: option.type,
      });
    }
    return ({
      id: option.value,
      value: option.display_name,
    });
  });
};

export const convertMultiSelectDynamicData = (options: Object): Array<Object> => {
  return ((options || {}).choices || []).map((option: Object) => ({
    label: option.value,
    value: option.display_name,
  }));
};

export const findOptions = (options: Object = {}, key: string | number): string => {
  let searchKey;
  if (typeof key === 'object') {
    [searchKey] = key;
  } else {
    searchKey = key;
  }
  return (convertDynamicData(options).find((f: Object) => f.id === searchKey) || {}).value;
};

// Remove undefined props from objects
export const removeUndefinedFromObject = (object: any): Object => {
  const newObject = { ...object };

  Object.keys(newObject).forEach((key: string) => (newObject[key] === undefined ? delete newObject[key] : ''));

  return newObject;
};

export const getUnique = (array: Array<Object>, key: string): Array<Object> => {
  const unique = array
    .map((e: Object) => e[key])
    .map((e: Object, i: number, final: Array<Object>) => final.indexOf(e) === i && i)
    .filter((e: any) => array[e])
    .map((e: any) => array[e]);

  return unique;
};

export const removeDuplicates = (array: Array<Object>, prop: string): Array<Object> => {
  return array.filter((obj: Object, pos: number, arr: Array<Object>) => {
    return arr.map((mapObj: Object) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const formatMonetary = (value: number | string = 0, digits: number = 2, locale: string = 'en-GB', currency: string = 'GBP'): string => {
  return Number(value).toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
  });
};

export const formatWholeNumber = (value: number | string = 0, locale: string = 'en-GB'): string => {
  return Number(value).toLocaleString(locale);
};

export const extractCount = (DataSet: Object, requiredCount: number | string) => {
  if (DataSet.length === 0) return 0;
  const activity = DataSet.filter((datum: Object) => datum.id === requiredCount);
  // const activity = DataSet[requiredCount];
  if (activity.length > 0) {
    return activity[0].value;
  }
  return 0;
};

export const findById = (item: any, value: string) => {
  return find(convertDynamicData(item), (f: any) => f.id === value);
}
