import React from 'react'
import appSettings from 'GlobalConfigs/settings';


const GitCommit = appSettings.REACT_APP_GIT_COMMIT;
const environment = appSettings.REACT_APP_ENVIRONMENT;

const hideEnv = environment && (environment.includes('prod') || environment.includes('Prod') || environment.includes('local'));

const SidebarFooter = () => {
  return (
    <>
      {GitCommit ? (
        <div
          style={{
            position: 'fixed',
            left: 0,
            bottom: 20,
            zIndex: 999999,
            padding: '5px 20px',
          }}
        >
          <p
            style={{
              fontSize: 10,
              lineHeight: '1em',
            }}
            className='white'
          >
                Version: {GitCommit}
          </p>
        </div>
      ) : null}
      {!hideEnv ? (
        <div
          style={{
            position: 'fixed',
            top: 23,
            right: 'calc(50% - 200px)',
            width: 200,
            zIndex: 999999,
            padding: '5px 20px',
          }}
        >
          <p
            className='white text-center'
            style={{ fontSize: 16 }}
          >
            <i className='material-icons white'>engineering</i> test environment
          </p>
        </div>
      ) : null}
      <div
        style={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          zIndex: 999999,
          padding: '5px 20px',
        }}
      >
        <p
          style={{
            fontSize: 10,
            lineHeight: '1em',
          }}
          className='white'
        >
          <i
            className='material-icons white'
            style={{
              fontSize: 12,
            }}
          >
                copyright
          </i>{' '}
              Yobota Limited {new Date().getFullYear()}
        </p>
      </div>
    </>
  )
}

export default SidebarFooter