import {LoadingSpinner} from "@chetwoodfinancial/staff-app-visual-components";
import {env} from "env";
import appSettings from "GlobalConfigs/settings";
import React, {lazy, useEffect} from "react";
import ReactGA from "react-ga";
import {useDispatch} from "react-redux";
import {Route} from "react-router-dom";
import {processTokenData} from "Store/Actions/auth";
import {checkIfTokensExist} from "Store/Api/authentication";
import {useAppSelector} from "Store/configureStore";
import App from "./App";
import AppContainer from "./AppContainer";

const GAtracking = appSettings.REACT_APP_GA_TRACKING;
const environment = appSettings.REACT_APP_ENVIRONMENT;

const SecurityQuestions = lazy(() => import("../SecurityQuestions"));
const Registration = lazy(() => import("../Registration"));
const Login = lazy(() => import("../Login"));

const AppLoader = () => {
  const isThereTokens = checkIfTokensExist();
  const loadingToken = useAppSelector((state) => state.auth.loadingToken);
  const loadingSignupData = useAppSelector(
    (state) => state.auth.loadingSignupData
  );
  const accountInfo = useAppSelector((state) => state.auth.accountInfo);
  // TODO => this email_address is a hack to check if user has a valid session
  const email_address = useAppSelector(
    (state) => state.auth.userInfo.email_address
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (GAtracking && env.NODE_ENV !== "test") {
      ReactGA.initialize(GAtracking, {
        titleCase: false,
      });
      ReactGA.set({environement: environment});
    }

    if (isThereTokens) {
      dispatch(processTokenData());
    }
  }, []);

  if (loadingToken || loadingSignupData) {
    return (
      <AppContainer>
        <LoadingSpinner />
      </AppContainer>
    );
  }

  if (!isThereTokens || !email_address) {
    return (
      <AppContainer>
        <Route path='*'
          component={ Login } />
      </AppContainer>
    );
  }

  if (accountInfo.signup_required) {
    return (
      <AppContainer>
        <Route path='*'
          component={ Registration } />
      </AppContainer>
    );
  }

  if (accountInfo.security_questions_required) {
    return (
      <AppContainer>
        <Route path='*'
          component={ SecurityQuestions } />
      </AppContainer>
    );
  }

  return <App />;
};

export default AppLoader;
