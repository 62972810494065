import {LOAD_FORM_DATA} from "Store/Constants/generic";
import {OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH, LOAD_OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH_DATA} from "Store/Constants/outboundPaymentTransactions";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";

import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

type OutboundPaymentTransactionsState = {
  allTransactions: Record<string, unknown>[];
  loadingTransactions: boolean;
  loadingDynamicData: boolean;
  dynamicData: Record<string, unknown>;
};

export const initialState: OutboundPaymentTransactionsState = {
  allTransactions: [],
  loadingTransactions: false,
  dynamicData: {},
  loadingDynamicData: false,
};

let actionsMap: Record<string,
(
    state: OutboundPaymentTransactionsState,
    action: Actions
) => OutboundPaymentTransactionsState
> = {};

if (
  rolesList.OUTBOUNDPAYMENTTRANSACTIONMANAGEMENT &&
  rolesList.OUTBOUNDPAYMENTTRANSACTIONMANAGEMENT.systemRoleName
) {
  const transactionsActionMap = {
    [LOAD_OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH_DATA + PENDING]: (
      state: OutboundPaymentTransactionsState
    ) => ({
      ...state,
      transactionData: [],
      loadingTransactionData: true,
    }),
    [LOAD_OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH_DATA + SUCCESS]: (
      state: OutboundPaymentTransactionsState,
      {payload: {data}}: any
    ) => ({
      ...state,
      transactionData: 
      {
        TRANSACTION_TYPES: data.actions.POST.search.children.outbound_payment_transaction_type_ids.choices,
        TRANSACTION_STATUSES: data.actions.POST.search.children.outbound_payment_transaction_status_ids.choices,
        PAYMENT_METHODS: data.actions.POST.search.children.outbound_payment_method_ids.choices,
      },
      loadingTransactionData: false,
    }),
    [LOAD_OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH_DATA + FAIL]: (
      state: OutboundPaymentTransactionsState
    ) => ({
      ...state,
      loadingTransactionData: false,
    }),
    [OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH + PENDING]: (
      state: OutboundPaymentTransactionsState
    ) => ({
      ...state,
      allTransactions: [],
      loadingTransactions: true,
    }),
    [OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH + SUCCESS]: (
      state: OutboundPaymentTransactionsState,
      {payload: {data}}: any
    ) => ({
      ...state,
      allTransactions: data || [],
      loadingTransactions: false,
    }),
    [OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH + FAIL]: (
      state: OutboundPaymentTransactionsState
    ) => ({
      ...state,
      loadingTransactions: false,
    }),
  };

  const formDataActionMap = {
    [`${rolesList.OUTBOUNDPAYMENTTRANSACTIONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${PENDING}`]: (
      state: OutboundPaymentTransactionsState
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${rolesList.OUTBOUNDPAYMENTTRANSACTIONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${SUCCESS}`]: (
      state: OutboundPaymentTransactionsState,
      {payload: {data}}: any
    ) => ({
      ...state,
      dynamicData: {
        ...(data.actions || {}).POST,
      },
      loadingDynamicData: false,
    }),
    [`${rolesList.OUTBOUNDPAYMENTTRANSACTIONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${FAIL}`]: (
      state: OutboundPaymentTransactionsState
    ) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
      },
      loadingDynamicData: false,
    }),
  };

  actionsMap = {
    ...transactionsActionMap,
    ...formDataActionMap,
  };
}

export default function reducer(
  state: OutboundPaymentTransactionsState = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}