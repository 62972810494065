import {Middleware} from "redux";
import {Actions} from "Store/Actions";
import {processTokenData, refreshAccessToken} from "Store/Actions/auth";
import {shouldRefreshAccessToken} from "Store/Api/authentication";
import {AUTH_REFRESH_ACCESS_TOKEN} from "Store/Constants/auth";
import {RootReducer} from "Store/Reducers";

const tokenMiddleware: Middleware<Record<string, never>, RootReducer> = (
  store
) => (next) => (action: Actions) => {
  // TODO => this should be reviewed, currently is refreshing access token after API call being executed
  // maybe this should be moved to a store subscription instead
  if (
    action.payload &&
    action.payload.client &&
    action.payload.client !== "loginClient" &&
    action.type !== AUTH_REFRESH_ACCESS_TOKEN &&
    shouldRefreshAccessToken()
  ) {
    store.dispatch(refreshAccessToken());
  }

  if(action.type === AUTH_REFRESH_ACCESS_TOKEN) {
    store.dispatch(processTokenData())
  }

  return next(action);
};

export default tokenMiddleware;
