import {HOMEPAGE_LOAD_HOST_DATA} from "Store/Constants/homePage";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {Actions} from "Store/Actions";

type HomePageState = {
  hostData: Record<string, unknown>;
  entityData: Record<string, unknown>;
  loadingHomePageData: boolean;
};

export const initialState: HomePageState = {
  hostData: {},
  entityData: {},
  loadingHomePageData: false,
};

const hostDataActionmap = {
  [HOMEPAGE_LOAD_HOST_DATA + PENDING]: (state: HomePageState) => ({
    ...state,
    hostData: {},
    loadingHomePageData: true,
  }),
  [HOMEPAGE_LOAD_HOST_DATA + SUCCESS]: (
    state: HomePageState,
    {payload: {data}}: any
  ) => ({
    ...state,
    hostData: data || {},
    loadingHomePageData: false,
  }),
  [HOMEPAGE_LOAD_HOST_DATA + FAIL]: (state: HomePageState) => ({
    ...state,
    loadingHomePageData: false,
  }),
};

const actionsMap = {
  ...hostDataActionmap,
};

export default function reducer(
  state: HomePageState = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
