//@flow
/* eslint-disable prefer-destructuring */
import {
  NAME_IDENTITY_VERIFICATION,
  POLL_NAME_IDENTITY_VERIFICATION,
} from 'Store/Constants/personManagement';
import { SUCCESS } from 'Store/Constants/axios';
import { pollNameIdentityVerification } from 'Store/Actions/personManagement';

const responseTaskStatus = [
  "SUCCESS",
  "FAILURE",
  "ERROR"
]

type Action = {
  type: string,
  payload: {
    data: {
      refresh_url: string,
      task_status: string,
      state: string,
      matched_addresses: string,
    },
  },
  meta: {
    previousAction: {
      payload: {
        redirectTo: string,
      },
    },
  },
};

export default (store: Object) => (next: Function) => (action: Action) => {
  switch (action.type) {
    case NAME_IDENTITY_VERIFICATION + SUCCESS:
    case POLL_NAME_IDENTITY_VERIFICATION + SUCCESS:
      if (action.payload && action.payload.data && responseTaskStatus.includes(action.payload.data.task_status)) {
        return next(action);
      }
      setTimeout(() => {
        store.dispatch(pollNameIdentityVerification(action.payload.data.refresh_url));
      }, 2000);
      return next(action);
    default:
      return next(action);
  }
};
