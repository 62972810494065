import {rolesList, holeConfig} from "GlobalConfigs";
import {Entities, HostOrganisations, ActiveClient} from "Types/application";
import {Permission} from "Types/auth";
import {HoleDef} from "Types/holeConfig";

/*
  Generates view links/sidebar items based on account permissions
 */
type MenuOption = {
  type: string;
  title: string;
  url: string;
  icon?: string;
  isActive: boolean;
  order: number;
  disabledForHost?: string[];
};

export const generateMenuItemsOnPermissions = (
  permissionsArray: Permission[] = [],
  currentPage: string,
  type: string
): MenuOption[] => {
  const menuItems = permissionsArray
    .filter((permission) => {
      const mappedItem = rolesList[permission.role_id];
      const isSameMappedItemType =
        !mappedItem?.type || mappedItem?.type === type;
      if (
        permission.read &&
        mappedItem &&
        mappedItem.url &&
        isSameMappedItemType &&
        !mappedItem.disabled
      ) {
        return true;
      }

      return false;
    })
    .map((permission) => {
      const mappedItem = rolesList[permission.role_id];
      return {
        ...mappedItem,
        isActive: currentPage.indexOf(mappedItem.shortCode) !== -1,
        disabledForHost: mappedItem.disabledForHost,
      };
    });

  // also inlcude home page which has special isActive check as the / is contained in all urls
  return [
    {
      type: "menu_item",
      title: "Home",
      url: "/",
      icon: "home",
      isActive: currentPage.split('/')[3] === "",
      order: 0,
    },
    ...menuItems,
  ].sort((a, b) => a.order - b.order);
};

/*
  Builds the sidebar items
  entities: array of entities user has access to
  activeEntity: the short code for the legal entity passed from reducer
  hostOrganisation: array of organisations the user has access to, if only one it does not display this part of the sidebar
  activeHost: short code of the active host for highlighting and divider text
  menuItems: sidebar items based on user permissions, generated with generateMenuItemsOnPermissions
  updateActiveHandler: for changing host organisation in the reducer, passed from connected component
 */
type Menu =
  | {
      type: string;
      title: string;
      url: string;
      isActive: boolean;
      shortCode?: string;
      logo?: string;
      onClick?: () => void;
      extraConfig?: HoleDef;
      disabledForHost?: string[];
      icon?: string;
    }
  | {
      type: "divider";
      title: "Legal entities" | "Host Organisations";
    }
  | {
      type: "line-divider";
      title: "Actions";
    };

const buildMenuItem = (
  clientType: "entity" | "host",
  client: ActiveClient,
  active: ActiveClient | Record<string, never>,
  updateActiveHandler: (client: ActiveClient) => void
) => ({
  type: `menu_item_${clientType}`,
  title: client.title,
  url: `/${clientType}/${client.shortCode}`,
  shortCode: client.shortCode,
  logo: client.logo ?? undefined,
  isActive: client.shortCode === active.shortCode,
  onClick: () => updateActiveHandler(client),
  extraConfig: {
    ...holeConfig[client.shortCode],
  },
});

export const menuBuilder = (
  entities: Array<Entities>,
  hostOrganisations: Array<HostOrganisations>,
  active: ActiveClient | Record<string, never>,
  menuItems: ReturnType<typeof generateMenuItemsOnPermissions>,
  updateActiveHandler: (value: ActiveClient) => void
): Menu[] => {
  let newMenu: Menu[] = [
    {
      type: "divider",
      title: "Legal entities",
    },
  ];

  entities.forEach((entity) => {
    newMenu = [
      ...newMenu,
      buildMenuItem("entity", entity, active, updateActiveHandler),
    ];
  });

  // Handle host orgs
  newMenu = [
    ...newMenu,
    {
      type: "divider",
      title: "Host Organisations",
    },
  ];

  hostOrganisations.forEach((host) => {
    newMenu = [
      ...newMenu,
      buildMenuItem("host", host, active, updateActiveHandler),
    ];
  });

  // Handle menu items
  newMenu = [
    ...newMenu,
    {
      type: "line-divider",
      title: "Actions",
    },
  ];

  menuItems.forEach((item) => {
    const isHostAndItemNotDisabled =
      active.objectType === "host" &&
      item.disabledForHost &&
      !item.disabledForHost.includes(`${active.host_organisation_id}`);
    if (isHostAndItemNotDisabled || active.objectType === "entity") {
      newMenu = [
        ...newMenu,
        {
          type: "menu_item",
          title: item.title,
          url: `/${active.objectType}/${active.shortCode}${item.url}`,
          isActive: item.isActive,
          icon: item.icon,
        },
      ];
    }
  });

  return newMenu;
};
