import {LOAD_ALL, LOAD_ONE, UPDATE_ONE, CREATE} from "Store/Constants/generic";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {HolidayCalendarStateType} from "Types/holidayCalendar";
import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

export const initialState: HolidayCalendarStateType = {
  all: [],
  selectedHolidayCalendar: {},
  loadingHolidayCalendarData: false,
  sendingHolidayCalendarData: false,
};

let actionsMap = {};

if (rolesList.HOLIDAYCALENDAR && rolesList.HOLIDAYCALENDAR.systemRoleName) {
  const holidayCalendarActionsMap = {
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${LOAD_ALL}${PENDING}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      all: [],
      loadingHolidayCalendarData: true,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${LOAD_ALL}${SUCCESS}`]: (
      state: HolidayCalendarStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      all: data || [],
      loadingHolidayCalendarData: false,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${LOAD_ALL}${FAIL}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      all: [],
      loadingHolidayCalendarData: false,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${LOAD_ONE}${PENDING}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      selectedHolidayCalendar: {},
      loadingHolidayCalendarData: true,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: HolidayCalendarStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      selectedHolidayCalendar: data || {},
      loadingHolidayCalendarData: false,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${LOAD_ONE}${FAIL}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      selectedHolidayCalendar: {},
      loadingHolidayCalendarData: false,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${CREATE}${PENDING}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      sendingHolidayCalendarData: true,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${CREATE}${SUCCESS}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      sendingHolidayCalendarData: false,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${CREATE}${FAIL}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      sendingHolidayCalendarData: false,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${UPDATE_ONE}${PENDING}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      sendingHolidayCalendarData: true,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${UPDATE_ONE}${SUCCESS}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      sendingHolidayCalendarData: false,
    }),
    [`${rolesList.HOLIDAYCALENDAR.systemRoleName}${UPDATE_ONE}${FAIL}`]: (
      state: HolidayCalendarStateType
    ) => ({
      ...state,
      sendingHolidayCalendarData: false,
    }),
  };

  actionsMap = {
    ...holidayCalendarActionsMap,
  };
}

export default function reducer(
  state: HolidayCalendarStateType = initialState,
  action: Actions
) {
  const fn: (
    state: HolidayCalendarStateType,
    action: Actions
  ) => HolidayCalendarStateType =
    actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
