//@flow
// EMPLOYEEMANAGEMENT role constants
export const EMPLOYEES_SUSPEND = 'EMPLOYEES_SUSPEND';
export const EMPLOYEES_LOCK = 'EMPLOYEES_LOCK';

export const EMPLOYEES_URL = '/api/employee/';

// EMPLOYEEPROFILEMANAGEMENT role constants
export const EMPLOYEE_PROFILES_LOAD_AVAILABLE = '_LOAD_AVAILABLE_PROFILES';
export const EMPLOYEE_PROFILES_ASSIGN = '_ASSIGN_PROFILE';
export const EMPLOYEE_PROFILES_UNASSIGN = '_UNASSIGN_PROFILE';

export const EMPLOYEE_PROFILES_URL = '/api/employee_profile_link/';
