//@flow
import React from 'react';
import ReactJson from 'react-json-view';
import appSettings from 'GlobalConfigs/settings';

// eslint-disable-next-line react/display-name
export default ({ msg }: Object) => {
  if (typeof msg === 'object' && Object.keys(msg).length > 0) {
    return (
      <div style={{
        "width": 'auto',
        "textAlign": 'left',
      }}
      >
        <ReactJson
          name='Error'
          src={ msg }
          enableClipboard={ false }
          displayDataTypes={ false }
          displayObjectSize={ false }
        />
      </div>
    );
  }

  if (typeof msg === 'string') return <>{msg}</>;

  if (appSettings.REACT_APP_SOMETHING_COOL) {
    return (
      <img
        src='/images/fine-fire.gif'
        alt='nothing-happen'
        width='70'
      />
    );
  }

  return <>Sorry, something went wrong.<br /> Please try again.</>;
};
