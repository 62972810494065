import {
  DEPOSITPRODUCTMANAGEMENT_EXPIRE_PRODUCT,
  DEPOSITPRODUCTMANAGEMENT_LAUNCH_PRODUCT,
  DEPOSITPRODUCTMANAGEMENT_SUSPEND_PRODUCT,
} from "Store/Constants/depositProducts";
import {
  LOAD_ALL,
  LOAD_ONE,
  UPDATE_ONE,
  CREATE,
  LOAD_FORM_DATA,
} from "Store/Constants/generic";
import { PENDING, SUCCESS, FAIL } from "Store/Constants/axios";
import { rolesList } from "GlobalConfigs";
import { DepositProductsType } from "Types/depositProducts";
import { Actions } from "Store/Actions";

export const initialState: DepositProductsType = {
  all: [],
  selectedDepositProduct: {},
  loadingDepositProductData: false,
  sendingDepositProductData: false,
  message: "",
  dynamicData: {
    deposit_system_product_id: [],
  },
  loadingDynamicData: false,
  productOptions: [],
  allProductOptions: {
    fixTerm: [],
    open: [],
    noticePeriod: [],
  },
};

let actionsMap = {};

if (
  rolesList.DEPOSITPRODUCTMANAGEMENT &&
  rolesList.DEPOSITPRODUCTMANAGEMENT.systemRoleName
) {
  const { systemRoleName } = rolesList.DEPOSITPRODUCTMANAGEMENT;
  const depositProductsActionsMap = {
    [systemRoleName + LOAD_ALL + PENDING]: (state: DepositProductsType) => ({
      ...state,
      all: [],
      loadingDepositProductData: true,
    }),
    [systemRoleName + LOAD_ALL + SUCCESS]: (
      state: DepositProductsType,
      { payload: { data } }: any
    ) => ({
      ...state,
      all: data || [],
      loadingDepositProductData: false,
    }),
    [systemRoleName + LOAD_ALL + FAIL]: (state: DepositProductsType) => ({
      ...state,
      loadingDepositProductData: false,
    }),
    [systemRoleName + LOAD_ONE + PENDING]: (state: DepositProductsType) => ({
      ...state,
      selectedDepositProduct: {},
      loadingDepositProductData: true,
    }),
    [systemRoleName + LOAD_ONE + SUCCESS]: (
      state: DepositProductsType,
      { payload: { data } }: any
    ) => ({
      ...state,
      selectedDepositProduct: data || {} || {},
      loadingDepositProductData: false,
      message: "",
    }),
    [systemRoleName + LOAD_ONE + FAIL]: (state: DepositProductsType) => ({
      ...state,
      loadingDepositProductData: false,
    }),
    [systemRoleName + CREATE + PENDING]: (state: DepositProductsType) => ({
      ...state,
      sendingDepositProductData: true,
    }),
    [systemRoleName + CREATE + SUCCESS]: (state: DepositProductsType) => ({
      ...state,
      sendingDepositProductData: false,
    }),
    [systemRoleName + CREATE + FAIL]: (state: DepositProductsType) => ({
      ...state,
      sendingDepositProductData: false,
    }),

    // Update one employee
    [systemRoleName + UPDATE_ONE + PENDING]: (state: DepositProductsType) => ({
      ...state,
      sendingDepositProductData: true,
    }),
    [systemRoleName + UPDATE_ONE + SUCCESS]: (state: DepositProductsType) => ({
      ...state,
      sendingDepositProductData: false,
    }),
    [systemRoleName + UPDATE_ONE + FAIL]: (state: DepositProductsType) => ({
      ...state,
      sendingDepositProductData: false,
    }),
  };

  const formDataActionMap = {
    [systemRoleName + LOAD_FORM_DATA + PENDING]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [systemRoleName + LOAD_FORM_DATA + SUCCESS]: (
      state: DepositProductsType,
      action: any
    ) => {
      const {
        payload: { data },
      } = action;
      const param =
        action?.meta?.previousAction?.payload?.request?.params
          ?.deposit_product_type;
      const choices = data.actions?.POST?.deposit_system_product_id?.choices;
      return {
        ...state,
        dynamicData: {
          ...(data.actions || {}).POST,
        },
        allProductOptions: {
          fixTerm:
            param === undefined || param === "FIXEDTERM"
              ? choices
              : state.allProductOptions.fixTerm,
          open:
            param === "INSTANTACCESS" ? choices : state.allProductOptions.open,
          noticePeriod:
            param === "NOTICEPERIOD"
              ? choices
              : state.allProductOptions.noticePeriod,
        },
        loadingDynamicData: false,
      };
    },
    [systemRoleName + LOAD_FORM_DATA + FAIL]: (state: DepositProductsType) => ({
      ...state,
      loadingDynamicData: false,
    }),
  };

  const depositProductsExtraActionsMap = {
    [DEPOSITPRODUCTMANAGEMENT_LAUNCH_PRODUCT + PENDING]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: true,
    }),
    [DEPOSITPRODUCTMANAGEMENT_LAUNCH_PRODUCT + SUCCESS]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: false,
    }),
    [DEPOSITPRODUCTMANAGEMENT_LAUNCH_PRODUCT + FAIL]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: false,
    }),
    [DEPOSITPRODUCTMANAGEMENT_EXPIRE_PRODUCT + PENDING]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: true,
    }),
    [DEPOSITPRODUCTMANAGEMENT_EXPIRE_PRODUCT + SUCCESS]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: false,
    }),
    [DEPOSITPRODUCTMANAGEMENT_EXPIRE_PRODUCT + FAIL]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: false,
    }),
    [DEPOSITPRODUCTMANAGEMENT_SUSPEND_PRODUCT + PENDING]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: true,
    }),
    [DEPOSITPRODUCTMANAGEMENT_SUSPEND_PRODUCT + SUCCESS]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: false,
    }),
    [DEPOSITPRODUCTMANAGEMENT_SUSPEND_PRODUCT + FAIL]: (
      state: DepositProductsType
    ) => ({
      ...state,
      loadingDepositProductData: false,
    }),
  };

  actionsMap = {
    ...depositProductsActionsMap,
    ...formDataActionMap,
    ...depositProductsExtraActionsMap,
  };
}

export default function reducer(
  state: DepositProductsType = initialState,
  action: Actions = {}
) {
  const fn: (
    state: DepositProductsType,
    action: Actions
  ) => DepositProductsType = actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
