import {
  IDENTITY_MANAGEMENT_LOAD_HISTORY,
  IDENTITY_MANAGEMENT_LOAD_IDENTITIES_ACTIVITY,
  IDENTITY_MANAGEMENT_LOAD_IDENTITIES,
  IDENTITY_MANAGEMENT_SET_IDENTITY_TO_ACT_ON,
  IDENTITY_MANAGEMENT_UPDATE_HISTORY,
  IDENTITY_MANAGEMENT_LOAD_ACTIVITIES_HISTORY,
  IDENTITY_MANAGEMENT_UPDATE_ACTIVITIES_HISTORY,
  IDENTITY_MANAGEMENT_LOAD_NEGATIVE_EVENTS,
  IDENTITY_MANAGEMENT_PERSON_SEARCH_RESULTS_DIALOG,
  IDENTITY_MANAGEMENT_IDENTITY_LINK_CANDIDATE,
  IDENTITY_MANAGEMENT_PREPARE_IDENTITY_FOR_LINK,
  IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL,
  IDENTITY_MANAGEMENT_VALIDATE_LINK_CANDIDATE,
  IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_SEARCH,
} from "Store/Constants/identityManagement";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";

import {auth} from "../../Views/IdentityManagement/MockData";
import {Actions} from "Store/Actions";

type IdentityManagementState = {
  isActivityHistoryLoading: boolean;
  isActivityHistoryUpdating: boolean;
  activityHistory: any[];
  isIdentitiesActivityLoading: boolean;
  identitiesActivity: any[];
  isIdentitiesLoading: boolean;
  identities: any[];
  identityToActOn: null;
  isIdentitiesHistoryLoading: any[];
  failedEvents: any[];
  isFailedEventsLoading: boolean;
  isPersonSearchDialogVisible: boolean;
  identityLinkCandidate: null;
  isPrepareIdentityForLoading: boolean;
  preparedIdentityForLinkUserID: string;
  preparedIdentityForLinkIdentity: Record<string, unknown>;
  isValidateLinkCandidateLoading: boolean;
  validateLinkCandidateData: Record<string, unknown>;
  isIdentityEmailUpdateLoading: boolean;
  isOrphanIdentitySearchLoading: boolean;
  orphanIdentities: any[];
  auth: {
    permissions: {
      read: boolean;
      role_id: string;
      write: boolean;
    }[];
  };
};

export const initialState: IdentityManagementState = {
  isActivityHistoryLoading: false,
  isActivityHistoryUpdating: false,
  activityHistory: [],
  isIdentitiesActivityLoading: false,
  identitiesActivity: [],
  isIdentitiesLoading: false,
  identities: [],
  identityToActOn: null,
  isIdentitiesHistoryLoading: [],
  failedEvents: [],
  isFailedEventsLoading: false,
  isPersonSearchDialogVisible: false,
  identityLinkCandidate: null,
  isPrepareIdentityForLoading: false,
  preparedIdentityForLinkUserID: "",
  preparedIdentityForLinkIdentity: {},
  isValidateLinkCandidateLoading: false,
  validateLinkCandidateData: {},
  isIdentityEmailUpdateLoading: false,
  isOrphanIdentitySearchLoading: false,
  orphanIdentities: [],
  auth,
};

const identityManagementActionMap = {
  // loadActivityHistory
  [IDENTITY_MANAGEMENT_LOAD_HISTORY + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isActivityHistoryLoading: true,
  }),
  [IDENTITY_MANAGEMENT_LOAD_HISTORY + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    activityHistory: data,
    isActivityHistoryLoading: false,
  }),
  [IDENTITY_MANAGEMENT_LOAD_HISTORY + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isActivityHistoryLoading: false,
  }),

  // loadFailedEvents
  [IDENTITY_MANAGEMENT_LOAD_NEGATIVE_EVENTS + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isFailedEventsLoading: true,
  }),
  [IDENTITY_MANAGEMENT_LOAD_NEGATIVE_EVENTS + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    failedEvents: data,
    isFailedEventsLoading: false,
  }),
  [IDENTITY_MANAGEMENT_LOAD_NEGATIVE_EVENTS + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isFailedEventsLoading: false,
  }),

  // updateActivityHistory
  [IDENTITY_MANAGEMENT_UPDATE_HISTORY + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isActivityHistoryUpdating: true,
  }),
  [IDENTITY_MANAGEMENT_UPDATE_HISTORY + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    activityHistory: data,
    isActivityHistoryUpdating: false,
  }),
  [IDENTITY_MANAGEMENT_UPDATE_HISTORY + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isActivityHistoryUpdating: false,
  }),

  // loadIdentitiesActivity
  [IDENTITY_MANAGEMENT_LOAD_IDENTITIES_ACTIVITY + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentitiesActivityLoading: true,
  }),
  [IDENTITY_MANAGEMENT_LOAD_IDENTITIES_ACTIVITY + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    identitiesActivity: data,
    isIdentitiesActivityLoading: false,
  }),
  [IDENTITY_MANAGEMENT_LOAD_IDENTITIES_ACTIVITY + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentitiesActivityLoading: false,
  }),

  //loadIdentities
  [IDENTITY_MANAGEMENT_LOAD_IDENTITIES + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentitiesLoading: true,
  }),
  [IDENTITY_MANAGEMENT_LOAD_IDENTITIES + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    identities: data.identities || [],
    isIdentitiesLoading: false,
    identityToActOn: null,
  }),
  [IDENTITY_MANAGEMENT_LOAD_IDENTITIES + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentitiesLoading: false,
  }),

  [IDENTITY_MANAGEMENT_SET_IDENTITY_TO_ACT_ON]: (
    state: IdentityManagementState,
    action: any
  ) => ({
    ...state,
    identityToActOn: action.payload.identityId,
  }),

  //load Identities history
  [IDENTITY_MANAGEMENT_LOAD_ACTIVITIES_HISTORY + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentitiesHistoryLoading: true,
  }),
  [IDENTITY_MANAGEMENT_LOAD_ACTIVITIES_HISTORY + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    identitiesHistory: data,
    isIdentitiesHistoryLoading: false,
  }),
  [IDENTITY_MANAGEMENT_LOAD_ACTIVITIES_HISTORY + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentitiesHistoryLoading: false,
  }),

  // updateActivitiesHistory
  [IDENTITY_MANAGEMENT_UPDATE_ACTIVITIES_HISTORY + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentitiesHistoryLoading: true,
  }),
  [IDENTITY_MANAGEMENT_UPDATE_ACTIVITIES_HISTORY + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    identitiesHistory: data,
    isIdentitiesHistoryLoading: false,
  }),
  [IDENTITY_MANAGEMENT_UPDATE_ACTIVITIES_HISTORY + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentitiesHistoryLoading: false,
  }),

  [IDENTITY_MANAGEMENT_PERSON_SEARCH_RESULTS_DIALOG]: (
    state: IdentityManagementState,
    {payload: {isVisible}}: any
  ) => ({
    ...state,
    isPersonSearchDialogVisible: isVisible,
  }),

  [IDENTITY_MANAGEMENT_IDENTITY_LINK_CANDIDATE]: (
    state: IdentityManagementState,
    {payload: {person}}: any
  ) => ({
    ...state,
    identityLinkCandidate: person,
  }),

  // prepare candidate for identity link
  [IDENTITY_MANAGEMENT_PREPARE_IDENTITY_FOR_LINK + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isPrepareIdentityForLoading: true,
  }),
  [IDENTITY_MANAGEMENT_PREPARE_IDENTITY_FOR_LINK + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => {
    const id = data?.identities !== undefined ? data?.identities[0] : null;
    return {
      ...state,
      preparedIdentityForLinkUserID: data?.user_id,
      preparedIdentityForLinkIdentity: id,

      isPrepareIdentityForLoading: false,
    };
  },
  [IDENTITY_MANAGEMENT_PREPARE_IDENTITY_FOR_LINK + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isPrepareIdentityForLoading: false,
  }),

  // Update identity email
  [IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentityEmailUpdateLoading: true,
  }),
  [IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL + SUCCESS]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentityEmailUpdateLoading: false,
    identityToActOn: null,
  }),
  [IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isIdentityEmailUpdateLoading: false,
    identityToActOn: null,
  }),

  // validate link candidate has YIP authorisation
  [IDENTITY_MANAGEMENT_VALIDATE_LINK_CANDIDATE + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isValidateLinkCandidateLoading: true,
  }),
  [IDENTITY_MANAGEMENT_VALIDATE_LINK_CANDIDATE + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    isValidateLinkCandidateLoading: false,
    validateLinkCandidateData: data,
  }),
  [IDENTITY_MANAGEMENT_VALIDATE_LINK_CANDIDATE + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isValidateLinkCandidateLoading: false,
  }),

  // Orphan identity search
  [IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_SEARCH + PENDING]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isOrphanIdentitySearchLoading: true,
  }),
  [IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_SEARCH + SUCCESS]: (
    state: IdentityManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    isOrphanIdentitySearchLoading: false,
    orphanIdentities: data,
  }),
  [IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_SEARCH + FAIL]: (
    state: IdentityManagementState
  ) => ({
    ...state,
    isOrphanIdentitySearchLoading: false,
    orphanIdentities: null,
  }),
};

const actionsMap = {
  ...identityManagementActionMap,
};

export default function reducer(
  state: IdentityManagementState = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
