//@flow
import {
  DEPOSIT_MANAGEMENT_SEARCH_DEPOSIT,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_SUMMARY,
  DEPOSIT_MANAGEMENT_LOAD_PENDING_APPROVAL,
  DEPOSIT_MANAGEMENT_ENQUIRE,
  DEPOSIT_MANAGEMENT_ACT,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_EVENTS,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_BALANCES,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_TRANSACTIONS,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_RATES,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LIMITS,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LOGS,
  DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_MATURITY,
} from 'Store/Constants/depositManagement';

export const DEPOSIT_MANAGEMENT_URL = '/api/deposit_account_management/';


export const searchDepositAccount = (accountId: number | string = '', accountRef: string = '') => {
  let searchPayload = {};
  if (accountRef) {
    searchPayload = {
      deposit_account_reference: accountRef,
    };
  }
  if (accountId) {
    searchPayload = {
      deposit_account_id: accountId,
    };
  }

  return {
    type: DEPOSIT_MANAGEMENT_SEARCH_DEPOSIT,
    payload: {
      client: 'authenticatedClient',
      request: {
        method: 'POST',
        url: '/api/deposit_account_management/search/',
        data: searchPayload,
      },
    },
  };
};

export const loadSelectedDepositSummary = (depositId: number | string) => ({
  type: DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_SUMMARY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/summary/`,
    },
  },
});

export const loadPendingApprovals = () => ({
  type: DEPOSIT_MANAGEMENT_LOAD_PENDING_APPROVAL,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }pending_review_events/`,
    },
  },
});

// Interaction handling
export const enquireEvent = (depositId: number | string, eventCode: any) => ({
  type: DEPOSIT_MANAGEMENT_ENQUIRE,
  payload: {
    eventCode,
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/${ eventCode }/`,
    },
  },
});

export const submitDepositManagementEvent = (depositId: number | string, submitUrl: any, values: any, selectedEventCode: string, requireFullRefresh: boolean = true, redirectTo: string = '') => ({
  type: DEPOSIT_MANAGEMENT_ACT,
  payload: {
    eventCode: selectedEventCode,
    requireFullRefresh,
    depositId,
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: submitUrl,
      data: {
        _api_data: {
          ...values,
          formsets: {
            ...values.formsets,
          },
        },
      },
    },
    redirectTo,
  },
});

// Data handling
export const loadSelectedDepositEvents = (depositId: number | string) => ({
  type: DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_EVENTS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/events/`,
      params: {
        deposit_id: depositId,
      },
    },
  },
});

export const loadSelectedDepositBalances = (depositId: number | string) => ({
  type: DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_BALANCES,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/balances/`,
      params: {
        deposit_id: depositId,
      },
    },
  },
});

export const loadSelectedDepositTransactions = (depositId: number | string) => ({
  type: DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_TRANSACTIONS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/transactions/`,
      params: {
        deposit_id: depositId,
      },
    },
  },
});

export const loadSelectedDepositRates = (depositId: number | string) => ({
  type: DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_RATES,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/rates/`,
      params: {
        deposit_id: depositId,
      },
    },
  },
});

export const loadSelectedDepositLimits = (depositId: number | string) => ({
  type: DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LIMITS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/limits/`,
      params: {
        deposit_id: depositId,
      },
    },
  },
});

export const loadSelectedDepositLogs = (depositId: number | string) => ({
  type: DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_LOGS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/deposit_account_log_records/`,
      params: {
        deposit_id: depositId,
      },
    },
  },
});

export const loadSelectedDepositMaturity = (depositId: number | string) => ({
  type: DEPOSIT_MANAGEMENT_LOAD_DEPOSIT_MATURITY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `${ DEPOSIT_MANAGEMENT_URL }${ depositId }/maturity/`,
      params: {
        deposit_id: depositId,
      },
    },
  },
});
