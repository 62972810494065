import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import notificationCenter from "./notificationCenter";
import application from "./application";
import homePage from "./homePage";
import employees from "./employees";
import bankAccounts from "./bankAccounts";
import personManagement from "./personManagement";
import profiles from "./profiles";
import outboundPaymentTransactions from "./outboundPaymentTransactions";
import inboundPaymentTransactions from "./inboundPaymentTransactions";
import testOutboundPayment from "./testOutboundPayment";
import holidayCalendar from "./holidayCalendar";
import fundingBuckets from "./fundingBuckets";
import portfolios from "./portfolios";
import loanProducts from "./loanProducts";
import loanManagement from "./loanManagement";
import depositProducts from "./depositProducts";
import depositManagement from "./depositManagement";
import referrals from "./referrals";
import creditDeclineOverturn from "./creditDeclineOverturn";
import identityManagement from "./identityManagement";
import feeBuilder from "./feeBuilder";
import accounting from "./accounting";
import apiKeyManagement from "./apiKeyManagement";
import companyManagement from "./companyManagement";

const rootReducer =  combineReducers({
  form: formReducer,
  notificationCenter,
  auth,
  application,
  homePage,
  employees,
  bankAccounts,
  personManagement,
  profiles,
  outboundPaymentTransactions,
  inboundPaymentTransactions,
  testOutboundPayment,
  holidayCalendar,
  fundingBuckets,
  loanProducts,
  loanManagement,
  depositProducts,
  depositManagement,
  referrals,
  creditDeclineOverturn,
  identityManagement,
  feeBuilder,
  accounting,
  apiKeyManagement,
  portfolios,
  companyManagement,
});

export type RootReducer = ReturnType<typeof rootReducer>
export default rootReducer

