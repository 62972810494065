import {
  rolesList as appRolesListChetwood,
  holeConfig as appHoleConfigChetwood,
} from "ClientConfiguration/config-CHETWOOD";
import {
  rolesList as appRolesListDefault,
  holeConfig as appHoleConfigDefault,
} from "ClientConfiguration/config-DEFAULT";
import {
  rolesList as appRolesListFronted,
  holeConfig as appHoleConfigFronted,
} from "ClientConfiguration/config-FRONTED";
import {
  rolesList as appRolesListTranch,
  holeConfig as appHoleConfigTranch,
} from "ClientConfiguration/config-TRANCH";
import { HoleConfig } from "Types/holeConfig";
import { RolesList } from "Types/rolesList";

import env from "./settings";

const getRolesList = () => {
  switch (env.REACT_APP_APP_CONFIG) {
    case "CHETWOOD":
      return appRolesListChetwood;
    case "FRONTED":
      return appRolesListFronted;
    case "TRANCH":
      return appRolesListTranch;
    default:
      return appRolesListDefault;
  }
};

const getHoleConfig = () => {
  switch (env.REACT_APP_APP_CONFIG) {
    case "CHETWOOD":
      return appHoleConfigChetwood;
    case "FRONTED":
      return appHoleConfigFronted;
    case "TRANCH":
      return appHoleConfigTranch;
    default:
      return appHoleConfigDefault;
  }
};

export const rolesList: RolesList = getRolesList();
export const holeConfig: HoleConfig = getHoleConfig();
