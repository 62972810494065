//@flow
export const LOAN_MANAGEMENT_SEARCH_LOAN = 'LOAN_MANAGEMENT_SEARCH_LOAN';
export const LOAN_MANAGEMENT_LOAD_LOAN_SUMMARY = 'LOAN_MANAGEMENT_LOAD_LOAN_SUMMARY';
export const LOAN_MANAGEMENT_LOAD_PENDING_APPROVAL = 'LOAN_MANAGEMENT_LOAD_PENDING_APPROVAL';
export const LOAN_MANAGEMENT_ENQUIRE = 'LOAN_MANAGEMENT_ENQUIRE';
export const LOAN_MANAGEMENT_ACT = 'LOAN_MANAGEMENT_ACT';
export const LOAN_MANAGEMENT_DRY_RUN = 'LOAN_MANAGEMENT_DRY_RUN';
export const LOAN_MANAGEMENT_DRY_RUN_PLATFORM_API = 'LOAN_MANAGEMENT_DRY_RUN_PLATFORM_API';
export const LOAN_MANAGEMENT_LOAD_LOAN_CASHFLOWS = 'LOAN_MANAGEMENT_LOAD_LOAN_CASHFLOWS';
export const LOAN_MANAGEMENT_LOAD_LOAN_EVENTS = 'LOAN_MANAGEMENT_LOAD_LOAN_EVENTS';
export const LOAN_MANAGEMENT_LOAD_LOAN_VERSIONS = 'LOAN_MANAGEMENT_LOAD_LOAN_VERSIONS';
export const LOAN_MANAGEMENT_LOAD_LOAN_MANDATES = 'LOAN_MANAGEMENT_LOAD_LOAN_MANDATES';
export const LOAN_MANAGEMENT_LOAD_LOAN_LOGS = 'LOAN_MANAGEMENT_LOAD_LOAN_LOGS';
export const LOAN_MANAGEMENT_LOAD_LOAN_GL_DATA = 'LOAN_MANAGEMENT_LOAD_LOAN_GL_DATA';
export const LOAN_MANAGEMENT_LOAD_LOAN_LEGAL_DOCUMENTS = 'LOAN_MANAGEMENT_LOAD_LOAN_LEGAL_DOCUMENTS';
export const LOAN_MANAGEMENT_LOAD_STATEMENTS = 'LOAN_MANAGEMENT_LOAD_STATEMENTS';
export const LOAN_MANAGEMENT_LOAD_LOAN_FLAGS = 'LOAN_MANAGEMENT_LOAD_LOAN_FLAGS';

export const LOAN_MANAGEMENT_URL = '/loan_management/';

// GL entry tool
export const LOAN_MANAGEMENT_GL_ENTRY_START = 'LOAN_MANAGEMENT_START_GL_ENTRY';
export const LOAN_MANAGEMENT_GL_ENTRY_REVIEW = 'LOAN_MANAGEMENT_REVIEW_GL_ENTRY';
export const LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS = 'LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS';
export const LOAN_MANAGEMENT_GET_REFINANCE_DYNAMIC_DATA = 'LOAN_MANAGEMENT_GET_REFINANCE_DYNAMIC_DATA';
export const LOAN_MANAGEMENT_LOAD_WP_KEY = 'LOAN_MANAGEMENT_LOAD_WP_KEY';
export const LOAN_MANAGEMENT_CARD_PAYMENTS = 'LOAN_MANAGEMENT_CARD_PAYMENTS';
export const LOAN_MANAGEMENT_CARD_PAYMENTS_REFRESH_RESULTS = 'LOAN_MANAGEMENT_CARD_PAYMENTS_REFRESH_RESULTS';
export const LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE = 'LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE';

export const GL_ENTRY_EVENT_URL = '/loan_management/gl_correction_entry/';

export const LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE = 'LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE';
