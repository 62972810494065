import { rolesList } from 'GlobalConfigs';
import React, { lazy, VoidFunctionComponent } from 'react';
import { Route } from 'react-router-dom';

const Views: Record<string, VoidFunctionComponent> = {
  Accounting: lazy(() => import("../../Views/Accounting")),
  APIKeyManagement: lazy(() => import("../../Views/APIKeyManagement")),
  BankAccounts: lazy(() => import("../../Views/BankAccounts")),
  CreditDeclineOverturn: lazy(() => import("../../Views/CreditDeclineOverturn")),
  DepositManagement: lazy(() => import("../../Views/DepositManagement")),
  DepositProducts: lazy(() => import("../../Views/DepositProducts")),
  Employees: lazy(() => import("../../Views/Employees")),
  FeeBuilder: lazy(() => import("../../Views/FeeBuilder")),
  FundingBuckets: lazy(() => import("../../Views/FundingBuckets")),
  HolidayCalendar: lazy(() => import("../../Views/HolidayCalendar")),
  IdentityManagement: lazy(() => import("../../Views/IdentityManagement")),
  InboundPaymentTransactions: lazy(() => import("../../Views/InboundPaymentTransactions")),
  LoanManagement: lazy(() => import("../../Views/LoanManagement")),
  LoanProducts: lazy(() => import("../../Views/LoanProducts")),
  OutboundPaymentTransactions: lazy(() => import("../../Views/OutboundPaymentTransactions")),
  PageTour: lazy(() => import("../../Views/PageTour")),
  PersonManagement: lazy(() => import("../../Views/PersonManagement")),
  Portfolios: lazy(() => import("../../Views/Portfolios")),
  Profiles: lazy(() => import("../../Views/Profiles")),
  Referrals: lazy(() => import("../../Views/Referrals")),
  TestOutboundPayment: lazy(() => import("../../Views/TestOutboundPayment")),
  CompanyManagement: lazy(() => import("../../Views/CompanyManagement")),
}

const RenderedRoutes = (currentPath: string) => Object.values(rolesList).map((module) => {
  if (module && module.url) {
    return (
      <Route
        key={ module.url }
        path={ `${currentPath}${module.url}` }
        component={ Views[module.moduleFolder] }
      />
    );
  }
  return null;
});

export default RenderedRoutes
