//@flow
// TESTOUTBOUNDPAYMENT role constants
export const TESTOUTBOUNDPAYMENT_APPROVE = 'TESTOUTBOUNDPAYMENT_APPROVE';
export const TESTOUTBOUNDPAYMENT_CANCEL = 'TESTOUTBOUNDPAYMENT_CANCEL';

export const TESTOUTBOUNDPAYMENT_LOAD_FORM_DATA = 'TESTOUTBOUNDPAYMENT_LOAD_FORM_DATA';
export const TESTOUTBOUNDPAYMENT_CREATE = 'TESTOUTBOUNDPAYMENT_CREATE';
export const TESTOUTBOUNDPAYMENT_CREATE_TO_LE_BANK_ACCOUNT = 'TESTOUTBOUNDPAYMENT_CREATE_TO_LE_BANK_ACCOUNT';
export const TESTOUTBOUNDPAYMENT_CREATE_TO_NEW = 'TESTOUTBOUNDPAYMENT_CREATE_TO_NEW';
export const TESTOUTBOUNDPAYMENT_CREATE_TO_PERSON = 'TESTOUTBOUNDPAYMENT_CREATE_TO_PERSON';
