//@flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dialog } from '@chetwoodfinancial/staff-app-visual-components';

import { removeNotification } from 'Store/Actions/notificationCenter';
import OtherError from './OtherError';
import { env } from '../../env';

const mapDispatchToProps = (dispatch: Function) => ({
  "actions": bindActionCreators({ removeNotification }, dispatch),
});

const mapStateToProps = (state: any) => ({
  "notifications": state.notificationCenter.notifications,
});

const dialogStyles = {
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

type Props = {
  actions: Object,
  notifications: Array<Object>,
};

const NotificationCenter = ({ actions, notifications = [] }: Props) => (
  <div className='notification-center'>
    {notifications.map((notification: Object) => {
      if (notification.type === 'success') {
        return (
          <Dialog
            key={ notification.id }
            isSuccess
            continueText='Continue'
            handleContinue={ () => actions.removeNotification(notification.id) }
            handleCancel={ () => actions.removeNotification(notification.id) }
            customStyle={ dialogStyles }
            hasURL={ !!notification.redirectURL }
            redirectURL={ notification.redirectURL }
          >
            <div
              style={{
                height: '80vh',
                overflow: 'auto',
              }}
            >
              <span className='notification-message'>{notification.text}</span>
            </div>
          </Dialog>
        );
      }

      if (notification.type === 'fail') {
        return (
          <Dialog
            key={ notification.id }
            isFail
            cancelText='Continue'
            handleContinue={ () => actions.removeNotification(notification.id) }
            handleCancel={ () => actions.removeNotification(notification.id) }
            customStyle={ dialogStyles }
            centered
          >
            <div style={{
              minWidth: 600,
              maxWidth: 1000,
              maxHeight: 600,
              overflowY: 'auto',
            }}
            >
              {(notification.errors || {}).db_error ? (
                <>
                  <span className='notification-message'>Database error:</span>
                  <span className='notification-message'>{notification.errors.db_error}</span>
                </>
              ) : null}
              {((notification.errors || {}).field_errors || []).length ? (
                <>
                  <p className='notification-message'>Field validation errors:</p>
                  {(notification.errors.field_errors || []).map((field: Object, i: number) => (
                    <span key={ i }
                      className='notification-message'>
                      {field.field_name}: {field.error}
                    </span>
                  ))}
                </>
              ) : null}
              {(notification.errors || {}).other ? <OtherError msg={ notification.errors.other } /> : null}

              {env.REACT_APP_TROLL && (
              <img
                src='https://media.giphy.com/media/7xCds0RBfyWQ0/giphy.gif'
                alt='nooo'
              />
              )}
            </div>
          </Dialog>
        );
      }
      return null;
    })}
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
