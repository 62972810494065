import {LoadingSpinner} from "@chetwoodfinancial/staff-app-visual-components";
import React, {Suspense, VoidFunctionComponent} from "react";
import {Route, Switch} from "react-router-dom";
import Logout from "Screens/Logout";
import NotFound from "Views/NotFound";

const AppContainer: VoidFunctionComponent<{children: JSX.Element}> = ({
  children,
}) => {
  return (
    <>
      <Suspense fallback={ <LoadingSpinner /> }>
        <Switch>
          <Route exact
            component={ Logout }
            path='/logout' />
          {children}
          <Route component={ NotFound } />
        </Switch>
      </Suspense>
    </>
  );
};

export default AppContainer;
