//@flow
export const SEARCH_PERSON_BY_DETAILS = 'SEARCH_PERSON_BY_DETAILS';
export const SEARCH_PERSON_BY_LOAN = 'SEARCH_PERSON_BY_LOAN';
export const LOAD_PERSON_CUSTOMER_REPORT = 'LOAD_PERSON_CUSTOMER_REPORT';
export const LOAD_PERSON_DSAR_REPORT = 'LOAD_PERSON_DSAR_REPORT';
export const LOAD_PERSON_BANK_ACCOUNTS = 'LOAD_PERSON_BANK_ACCOUNTS';
export const LOAD_PERSON_LEGAL_DOCUMENT = 'LOAD_PERSON_LEGAL_DOCUMENT';
export const LOAD_SELECTED_PERSON_FLAG = 'LOAD_SELECTED_PERSON_FLAG';
export const FIND_PERSON_LEGAL_DOCUMENT = 'FIND_PERSON_LEGAL_DOCUMENT';
export const PERSON_MANAGEMENT_LOAD_PRODUCTS = 'PERSON_MANAGEMENT_LOAD_PRODUCTS';
export const PERSON_MANAGEMENT_UPDATE_PERSON = 'PERSON_MANAGEMENT_UPDATE_PERSON';
export const PERSON_MANAGEMENT_REGENERATE_DOCUMENTS = 'PERSON_MANAGEMENT_REGENERATE_DOCUMENTS';
export const PERSON_MANAGEMENT_LOAD_CACHE = 'PERSON_MANAGEMENT_LOAD_CACHE';
export const PERSON_MANAGEMENT_CLEAR_CACHE = 'PERSON_MANAGEMENT_CLEAR_CACHE';
export const PERSON_MANAGEMENT_UPDATE_FLAG = 'PERSON_MANAGEMENT_UPDATE_FLAG';
export const PERSON_MANAGEMENT_LOAD_FLAG_HISTORY = 'PERSON_MANAGEMENT_LOAD_FLAG_HISTORY';
export const PERSON_MANAGEMENT_UPDATE_ADDRESS = 'PERSON_MANAGEMENT_UPDATE_ADDRESS';
export const LOAD_ADDRESS_DYNAMIC_DATA = 'LOAD_ADDRESS_DYNAMIC_DATA';
export const ADDRESS_MATCH = 'ADDRESS_MATCH';
export const POLL_ADDRESS_MATCH = 'POLL_ADDRESS_MATCH';
export const ADDRESS_FINDER_CLEAN = 'ADDRESS_FINDER_CLEAN';

export const IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION';
export const POLL_IDENTITY_VERIFICATION = 'POLL_IDENTITY_VERIFICATION';

export const NAME_IDENTITY_VERIFICATION = 'NAME_IDENTITY_VERIFICATION';
export const POLL_NAME_IDENTITY_VERIFICATION = 'POLL_NAME_IDENTITY_VERIFICATION';
export const CLEAR_NAME_IDENTITY_VERIFICATION = 'CLEAR_NAME_IDENTITY_VERIFICATION';

export const PERSON_MANAGEMENT_UPLOADFILE_OPTIONS = 'PERSON_MANAGEMENT_UPLOADFILE_OPTIONS';
export const PERSON_MANAGEMENT_UPLOAD_FILE = 'PERSON_MANAGEMENT_UPLOAD_FILE';

export const PERSON_MANAGEMENT_URL = '/api/person/';
export const PERSON_ACCOUNTS_URL = '/api/person_accounts/';
export const CUSTOMER_LOAN_DATA_URL = '/api/get_customer_loan_data/';
export const SEARCH_PERSON_BY_LOAN_URL = '/api/search_person_by_loan/';
export const PERSON_DPA_REPORT_URL = '/api/dpa_report/';
export const PERSON_BANK_ACCOUNTS_URL = '/api/person_bank_account/';

export const SEARCH_IDENTITY_LINK_CANDIDATES = 'SEARCH_IDENTITY_LINK_CANDIDATES';
export const CHOSEN_LINK_CANDIDATE = 'CHOSEN_LINK_CANDIDATE';
export const LOAD_CHOSEN_LINK_CANDIDATE = 'LOAD_CHOSEN_LINK_CANDIDATE';
export const GET_NATIONALITIES_LIST = "GET_NATIONALITIES_LIST";

export const PERSON_MANAGEMENT_HOLDING_ACCOUNT_WITHDRAWAL = "PERSON_MANAGEMENT_HOLDING_ACCOUNT_WITHDRAWAL";
