//@flow
import {
  LOANPRODUCTMANAGEMENT_CONVERT_SPREADSHEET_TO_RATE_TABLE,
  LOANPRODUCTMANAGEMENT_RESET_SPREADSHEET,
  LOANPRODUCTMANAGEMENT_LOAD_BUILDER_DATA,
  LOANPRODUCTMANAGEMENT_SAMPLE_QUOTE,
  LOANPRODUCTMANAGEMENT_CHECK_CONDITION,
  LOANPRODUCTMANAGEMENT_PRICING_SURFACE,
  LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT,
  LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT,
  sampleRateTable,
} from 'Store/Constants/loanProducts';
import {
  LOAD_ALL,
  LOAD_ONE,
  UPDATE_ONE,
  CREATE,
  LOAD_FORM_DATA,
} from 'Store/Constants/generic';
import {
  PENDING,
  SUCCESS,
  FAIL,
} from 'Store/Constants/axios';

import { rolesList } from 'GlobalConfigs';
import {Actions} from "../Actions";

type LoanProductsState = {
  all: any[];
  selectedLoanProduct: Record<string, unknown>;
  loadingLoanProductData: boolean;
  sendingLoanProductData: boolean;
  message: string;
  dynamicData: Record<string, unknown>;
  loadingDynamicData: boolean;
  newRateTable: any[];
  processingSpreadsheet: boolean;
  loanProductDataTree: Record<string, unknown>;
  loadingDataTree: boolean;
  // LP Actions
  sampleQuote: Record<string, unknown>;
  loadingSampleQuote: boolean;
  conditionCheck: Record<string, unknown>;
  loadingConditionCheck: boolean;
  pricingSurface: Record<string, unknown>;
  loadingPricingSurface: boolean;
}

export const initialState: LoanProductsState = {
  all: [],
  selectedLoanProduct: {},
  loadingLoanProductData: false,
  sendingLoanProductData: false,
  message: '',
  dynamicData: {},
  loadingDynamicData: false,
  newRateTable: [],
  processingSpreadsheet: false,
  loanProductDataTree: {},
  loadingDataTree: false,
  // LP Actions
  sampleQuote: {},
  loadingSampleQuote: false,
  conditionCheck: {},
  loadingConditionCheck: false,
  pricingSurface: {},
  loadingPricingSurface: false,
};

let actionsMap: Record<
    string,
    (
        state: LoanProductsState,
        action: Actions
    ) => LoanProductsState
    > = {};

if (rolesList.LOANPRODUCTMANAGEMENT && rolesList.LOANPRODUCTMANAGEMENT.systemRoleName) {
  const loanProductsActionsMap = {
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_ALL }${ PENDING }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      all: [],
      loadingLoanProductData: true,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_ALL }${ SUCCESS }`]: (
        state: LoanProductsState,
        {payload: {data}}: any
    ) => ({
      ...state,
      all: data || [],
      loadingLoanProductData: false,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_ALL }${ FAIL }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingLoanProductData: false,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_ONE }${ PENDING }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      selectedLoanProduct: {},
      loadingLoanProductData: true,
      sampleQuote: {},
      conditionCheck: {},
      pricingSurface: {},
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_ONE }${ SUCCESS }`]: (
        state: LoanProductsState,
        {payload: {data}}: any
    ) => ({
      ...state,
      selectedLoanProduct: data || {},
      loadingLoanProductData: false,
      message: '',
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_ONE }${ FAIL }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingLoanProductData: false,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ CREATE }${ PENDING }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      sendingLoanProductData: true,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ CREATE }${ SUCCESS }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      sendingLoanProductData: false,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ CREATE }${ FAIL }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      sendingLoanProductData: false,
    }),

    // Update one lp
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ UPDATE_ONE }${ PENDING }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      sendingLoanProductData: true,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ UPDATE_ONE }${ SUCCESS }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      sendingLoanProductData: false,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ UPDATE_ONE }${ FAIL }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      sendingLoanProductData: false,
    }),
  };

  const formDataActionMap = {
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_FORM_DATA }${ PENDING }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_FORM_DATA }${ SUCCESS }`]: (
        state: LoanProductsState,
        {payload: {data}}: any
    ) => ({
      ...state,
      dynamicData: {
        ...(data.actions || {}).POST,
      },
      loadingDynamicData: false,
    }),
    [`${ rolesList.LOANPRODUCTMANAGEMENT.systemRoleName }${ LOAD_FORM_DATA }${ FAIL }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingDynamicData: false,
    }),
  };

  const rateTableActionMap = {
    [LOANPRODUCTMANAGEMENT_CONVERT_SPREADSHEET_TO_RATE_TABLE + PENDING]: (
        state: LoanProductsState
    ) => ({
      ...state,
      newRateTable: [],
      processingSpreadsheet: true,
    }),
    [LOANPRODUCTMANAGEMENT_CONVERT_SPREADSHEET_TO_RATE_TABLE + SUCCESS]: (
        state: LoanProductsState,
        {payload: {data}}: any
    ) => ({
      ...state,
      newRateTable: [
        ...data,
      ],
      processingSpreadsheet: false,
    }),
    [LOANPRODUCTMANAGEMENT_CONVERT_SPREADSHEET_TO_RATE_TABLE + FAIL]: (
        state: LoanProductsState
    ) => ({
      ...state,
      newRateTable: [...sampleRateTable],
      processingSpreadsheet: false,
    }),
    [LOANPRODUCTMANAGEMENT_RESET_SPREADSHEET]: (
        state: LoanProductsState
    ) => ({
      ...state,
      newRateTable: [],
      processingSpreadsheet: false,
    }),
  };

  const eventDefinitionActionMap = {
    [LOANPRODUCTMANAGEMENT_LOAD_BUILDER_DATA + PENDING]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingDataTree: true,
    }),
    [LOANPRODUCTMANAGEMENT_LOAD_BUILDER_DATA + SUCCESS]: (
        state: LoanProductsState,
        {payload: {data}}: any
    ) => ({
      ...state,
      loanProductDataTree: {
        ...data,
      },
      loadingDataTree: false,
    }),
    [LOANPRODUCTMANAGEMENT_LOAD_BUILDER_DATA + FAIL]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loanProductDataTree: {},
      loadingDataTree: false,
    }),
  };

  const additionalActionsActionMap = {
    [LOANPRODUCTMANAGEMENT_SAMPLE_QUOTE + PENDING]: (
        state: LoanProductsState
    ) => ({
      ...state,
      sampleQuote: {},
      loadingSampleQuote: true,
    }),
    [LOANPRODUCTMANAGEMENT_SAMPLE_QUOTE + SUCCESS]: (
        state: LoanProductsState,
        {payload: {data}}: any
    ) => ({
      ...state,
      sampleQuote: {
        ...data,
      },
      loadingSampleQuote: false,
    }),
    [LOANPRODUCTMANAGEMENT_SAMPLE_QUOTE + FAIL]: (
        state: LoanProductsState
    ) => ({
      ...state,
      sampleQuote: {},
      loadingSampleQuote: false,
    }),
    [LOANPRODUCTMANAGEMENT_CHECK_CONDITION + PENDING]: (
        state: LoanProductsState
    ) => ({
      ...state,
      conditionCheck: {},
      loadingConditionCheck: true,
    }),
    [LOANPRODUCTMANAGEMENT_CHECK_CONDITION + SUCCESS]: (
        state: LoanProductsState,
        {payload: {data}}: any
    ) => ({
      ...state,
      conditionCheck: {
        ...data,
      },
      loadingConditionCheck: false,
    }),
    [LOANPRODUCTMANAGEMENT_CHECK_CONDITION + FAIL]: (
        state: LoanProductsState
    ) => ({
      ...state,
      conditionCheck: {},
      loadingConditionCheck: false,
    }),
    [LOANPRODUCTMANAGEMENT_PRICING_SURFACE + PENDING]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingPricingSurface: true,
      pricingSurface: {},
    }),
    [LOANPRODUCTMANAGEMENT_PRICING_SURFACE + SUCCESS]: (
        state: LoanProductsState,
        {payload: {data}}: any
    ) => ({
      ...state,
      pricingSurface: {
        ...data,
      },
      loadingPricingSurface: false,
    }),
    [LOANPRODUCTMANAGEMENT_PRICING_SURFACE + FAIL]: (
        state: LoanProductsState
    ) => ({
      ...state,
      pricingSurface: {},
      loadingPricingSurface: false,
    }),
  };

  const loanProductsExtraActionsMap = {
    [`${ LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT }${ PENDING }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingLoanProductData: true,
    }),
    [`${ LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT }${ SUCCESS }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingLoanProductData: false,
    }),
    [`${ LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT }${ FAIL }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingLoanProductData: false,
    }),
    [`${ LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT }${ PENDING }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingLoanProductData: true,
    }),
    [`${ LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT }${ SUCCESS }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingLoanProductData: false,
    }),
    [`${ LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT }${ FAIL }`]: (
        state: LoanProductsState
    ) => ({
      ...state,
      loadingLoanProductData: false,
    }),
  };

  actionsMap = {
    ...loanProductsActionsMap,
    ...formDataActionMap,
    ...rateTableActionMap,
    ...eventDefinitionActionMap,
    ...additionalActionsActionMap,
    ...loanProductsExtraActionsMap,
  };
}

export default function reducer(
    state: LoanProductsState = initialState,
    action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
