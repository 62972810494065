import {getLocalJWTToken} from "Store/Api/authentication";
import {
  APPLICATION_CHANGE_PAGE,
  APPLICATION_UPDATE_ACTIVE_HOLE,
  APPLICATION_UPDATE_LOCATION,
  APPLICATION_UPDATE_TOUR_STEPS,
} from "Store/Constants/application";
import {AUTH_PROCESS_ACCESS_TOKEN} from "Store/Constants/auth";
import {
  ApplicationStateType,
  Entities,
  HostOrganisations,
} from "Types/application";
import {processJWTEntities} from "Utils/permissions";
import {Actions} from "../Actions";

export const initialState: ApplicationStateType = {
  loadingHoLes: false,
  entities: [],
  defaultEntity: "",
  hostOrganisations: [],
  active: {},
  currentPage: [
    {
      title: "Home",
      url: "/",
    },
  ],
  tourSteps: [],
  pagination: {
    pageSize: 6,
    page: 0,
  },
};

export default function reducer(
  state: ApplicationStateType = initialState,
  action: Actions
): ApplicationStateType {
  if (action.type === APPLICATION_UPDATE_LOCATION) {
    const {newLocation, tourSteps, defaultPageSize} = action.payload;
    const isSamePageUrl =
      newLocation.length > 0 && state.currentPage[0].url !== newLocation[0].url;
    // Change application page (For breadcrumbs)
    return {
      ...state,
      currentPage: newLocation,
      tourSteps: tourSteps,
      pagination: isSamePageUrl
        ? state.pagination
        : {
          pageSize: defaultPageSize,
          page: 0,
        },
    };
  }

  if (action.type === APPLICATION_UPDATE_TOUR_STEPS) {
    return {
      ...state,
      tourSteps: action.payload.tourSteps,
    };
  }

  if (action.type === APPLICATION_CHANGE_PAGE) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page: action.payload.page,
      },
    };
  }

  if (action.type === AUTH_PROCESS_ACCESS_TOKEN) {
    const jwtToken = action.accountInfo?.access ?? getLocalJWTToken()
    const decodedJWTHoles: {
      entities: Entities[];
      hostOrganisations: HostOrganisations[];
    } = processJWTEntities(jwtToken);
    return {
      ...state,
      entities: decodedJWTHoles.entities,
      hostOrganisations: decodedJWTHoles.hostOrganisations,
      defaultEntity:
        (decodedJWTHoles.entities[0] || {}).shortCode || "no-access",
      active: decodedJWTHoles.entities[0],
    };
  }

  if (action.type === APPLICATION_UPDATE_ACTIVE_HOLE) {
    return {
      ...state,
      active: action.payload.newActive,
    };
  }

  return state;
}
