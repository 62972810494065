import {Actions} from "Store/Actions";
import {
  API_KEY_MANAGEMENT_CREATE_KEY,
  API_KEY_MANAGEMENT_LOAD_ACTIVE_KEYS,
  API_KEY_MANAGEMENT_LOAD_REVOKED_KEYS,
  API_KEY_MANAGEMENT_REVOKE_KEY,
  API_KEY_MANAGEMENT_PURGE_KEY,
} from "Store/Constants/apiKeyManagement";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";

type ApiKeyManagementState = {
  isActiveKeysLoading: boolean;
  isRevokedKeysLoading: boolean;
  isActiveKeysFailed: boolean;
  isRevokedKeysFailed: boolean;
  isCreateKeyLoading: boolean;
  isRevokeKeyLoading: boolean;
  isCreateKeyFailed: boolean;
  isRevokeKeyFailed: boolean;
  activeKeys: any[];
  revokedKeys: any[];
  createdKey: Record<string, unknown> | null;
  revokedKey: Record<string, unknown> | null;
  createKeyError: Record<string, unknown> | null;
  revokeKeyError: Record<string, unknown> | null;
};

export const initialState = {
  isActiveKeysLoading: false,
  isRevokedKeysLoading: false,
  isActiveKeysFailed: false,
  isRevokedKeysFailed: false,
  isCreateKeyLoading: false,
  isRevokeKeyLoading: false,
  isCreateKeyFailed: false,
  isRevokeKeyFailed: false,
  activeKeys: [],
  revokedKeys: [],
  createdKey: null,
  revokedKey: null,
  createKeyError: null,
  revokeKeyError: null,
};

const apiKeyManagementActionMap = {
  [API_KEY_MANAGEMENT_LOAD_ACTIVE_KEYS + PENDING]: (
    state: ApiKeyManagementState
  ) => ({
    ...state,
    isActiveKeysLoading: true,
  }),
  [API_KEY_MANAGEMENT_LOAD_ACTIVE_KEYS + FAIL]: (
    state: ApiKeyManagementState
  ) => ({
    ...state,
    isActiveKeysLoading: false,
    isActiveKeysFailed: true,
  }),
  [API_KEY_MANAGEMENT_LOAD_ACTIVE_KEYS + SUCCESS]: (
    state: ApiKeyManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    isActiveKeysLoading: false,
    activeKeys: data,
  }),
  [API_KEY_MANAGEMENT_LOAD_REVOKED_KEYS + PENDING]: (
    state: ApiKeyManagementState
  ) => ({
    ...state,
    isRevokedKeysLoading: true,
  }),
  [API_KEY_MANAGEMENT_LOAD_REVOKED_KEYS + FAIL]: (
    state: ApiKeyManagementState
  ) => ({
    ...state,
    isRevokedKeysLoading: false,
    isRevokedKeysFailed: true,
  }),
  [API_KEY_MANAGEMENT_LOAD_REVOKED_KEYS + SUCCESS]: (
    state: ApiKeyManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    isRevokedKeysLoading: false,
    revokedKeys: data,
  }),
  [API_KEY_MANAGEMENT_REVOKE_KEY + PENDING]: (
    state: ApiKeyManagementState
  ) => ({
    ...state,
    isRevokeKeyLoading: true,
  }),
  [API_KEY_MANAGEMENT_REVOKE_KEY + FAIL]: (
    state: ApiKeyManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    isRevokeKeyLoading: false,
    isRevokeKeyFailed: true,
    revokeKeyError: data,
  }),
  [API_KEY_MANAGEMENT_REVOKE_KEY + SUCCESS]: (
    state: ApiKeyManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    isRevokeKeyLoading: false,
    revokedKey: data,
  }),
  [API_KEY_MANAGEMENT_CREATE_KEY + PENDING]: (
    state: ApiKeyManagementState
  ) => ({
    ...state,
    isCreateKeyLoading: true,
  }),
  [API_KEY_MANAGEMENT_CREATE_KEY + FAIL]: (
    state: ApiKeyManagementState,
    payload: any
  ) => ({
    ...state,
    isCreateKeyLoading: false,
    isCreateKeyFailed: true,
    createKeyError: payload,
  }),
  [API_KEY_MANAGEMENT_CREATE_KEY + SUCCESS]: (
    state: ApiKeyManagementState,
    {payload: {data}}: any
  ) => ({
    ...state,
    isCreateKeyLoading: false,
    createdKey: data,
  }),
  [API_KEY_MANAGEMENT_PURGE_KEY]: (state: ApiKeyManagementState) => ({
    ...state,
    isCreateKeyLoading: false,
    createdKey: null,
  }),
};

const actionsMap = {
  ...apiKeyManagementActionMap,
};

export default function reducer(
  state: ApiKeyManagementState = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
