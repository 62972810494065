//@flow
/* eslint-disable no-unused-vars */

import {
  removeSets,
  removeDataValues,
} from 'Utils/dataProcessor';
import { FAIL } from 'Store/Constants/axios';

type Action = {
  type: string,
  payload: {
    status: string | number,
  },
  error: {
    response: {
      status: string | number,
    },
  },
  meta: {
    previousAction: {
      type: string,
      payload: Object,
    },
  },
};

const PROD_MANAGEMENT_ACTIONS = ['LOAN_MANAGEMENT_ENQUIRE', 'LOAN_MANAGEMENT_ACT', 'LOAN_MANAGEMENT_DRY_RUN', 'DEPOSIT_MANAGEMENT_ENQUIRE', 'DEPOSIT_MANAGEMENT_ACT'];

const apiResponseProcessor = () => (next: Function) => (action: Action) => {
  if (action.type.includes(FAIL)) {
    let failedAction = action.type.replace(FAIL, '');
    const isProdManagement = PROD_MANAGEMENT_ACTIONS.includes(failedAction);
    if (isProdManagement) {
      failedAction = `${ failedAction } - ${ action?.meta?.previousAction?.payload?.eventCode }`;
    }
    return next(action);
  }

  return next(action);
};
export default apiResponseProcessor;
