import { rolesList } from "GlobalConfigs";
import { PersonManagementStateType } from "Types/personManagement";
import {
  SEARCH_PERSON_BY_DETAILS,
  SEARCH_PERSON_BY_LOAN,
  PERSON_MANAGEMENT_LOAD_PRODUCTS,
  PERSON_MANAGEMENT_LOAD_CACHE,
  LOAD_PERSON_BANK_ACCOUNTS,
  FIND_PERSON_LEGAL_DOCUMENT,
  PERSON_MANAGEMENT_LOAD_FLAG_HISTORY,
  LOAD_PERSON_CUSTOMER_REPORT,
  LOAD_PERSON_DSAR_REPORT,
  LOAD_SELECTED_PERSON_FLAG,
  PERSON_MANAGEMENT_REGENERATE_DOCUMENTS,
  PERSON_MANAGEMENT_UPLOADFILE_OPTIONS,
  ADDRESS_MATCH,
  POLL_ADDRESS_MATCH,
  ADDRESS_FINDER_CLEAN,
  IDENTITY_VERIFICATION,
  POLL_IDENTITY_VERIFICATION,
  NAME_IDENTITY_VERIFICATION,
  POLL_NAME_IDENTITY_VERIFICATION,
  CLEAR_NAME_IDENTITY_VERIFICATION,
  LOAD_ADDRESS_DYNAMIC_DATA,
  SEARCH_IDENTITY_LINK_CANDIDATES,
  CHOSEN_LINK_CANDIDATE,
  LOAD_CHOSEN_LINK_CANDIDATE,
  GET_NATIONALITIES_LIST,
} from "Store/Constants/personManagement";

import { LOAD_ONE, LOAD_FORM_DATA } from "Store/Constants/generic";
import { PENDING, SUCCESS, FAIL } from "Store/Constants/axios";
import { Actions } from "Store/Actions";

export const initialState: PersonManagementStateType = {
  searchResults: [],
  loadingSearchresults: false,
  selectedPerson: {
    externalClientLinks: []
  },
  loadingSelectedPerson: false,
  loadingPersonLoans: false,
  personProducts: {},
  loadingPersonProducts: false,
  personActiveJourneys: [],
  loadingPersonCache: false,
  personBankAccounts: [],
  loadingPersonBankAccounts: false,
  legalDocuments: [],
  loadingExtraLegalDocument: false,
  loadingPersonFlags: false,
  personFlagsHistory: [],
  loadingPersonFlagsHistory: false,

  addressUpdateTool: {
    addressMatch: [],
    loadingAddressMatch: false,
    identityVerificationTicketStatus: null,
    identityVerificationTicketId: null,
    loadingIdentityVerification: false,
    idvAssesmentDecision: null,
  },

  personUpdateTool: {
    identityVerificationTicketStatus: null,
    identityVerificationTicketId: null,
    loadingIdentityVerification: false,
    idvAssesmentDecision: null,
  },

  dynamicData: {},
  loadingDynamicData: false,
  loadingCustomerRecord: false,
  loadingRegeneratePersonDocuments: false,
  fileUploadDynamicData: {},
  personLoans: [],
  loadingIdentityLinksCandidates: false,
  identityLinkCandidates: null,
  chosenLinkCandidateID: null,
  linkCandidateData: null,
  loadingLinkCandidateData: false,

  nationalities: [],
  loadingNationalities: false

};

let actionsMap: Record<
    string,
    (
        state: PersonManagementStateType,
        action: Actions
    ) => PersonManagementStateType
    > = {};

if (rolesList.PERSONMANAGEMENT && rolesList.PERSONMANAGEMENT.systemRoleName) {
  const searchPersonByDetailsActionsMap = {
    [SEARCH_PERSON_BY_DETAILS + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      searchResults: [],
      personBankAccounts: [],
      personProducts: {},
      loadingSearchresults: true,
    }),
    [SEARCH_PERSON_BY_DETAILS + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      searchResults: data || [],
      loadingSearchresults: false,
    }),
    [SEARCH_PERSON_BY_DETAILS + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      loadingSearchresults: false,
    }),
  };

  const searchIdentityLinkCandidatesActionsMap = {
    [SEARCH_IDENTITY_LINK_CANDIDATES + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      identityLinkCandidates: null,
      linkCandidateData: null,
      loadingIdentityLinksCandidates: true,
    }),
    [SEARCH_IDENTITY_LINK_CANDIDATES + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      identityLinkCandidates: data || [],
      loadingIdentityLinksCandidates: false,
    }),
    [SEARCH_IDENTITY_LINK_CANDIDATES + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      identityLinkCandidates: null,
      loadingIdentityLinksCandidates: false,
    }),
  };

  const chosenLinkCandidateActionsMap = {
    [CHOSEN_LINK_CANDIDATE]: (
      state: PersonManagementStateType,
      { payload: { personDetails } }: any
    ) => ({
      ...state,
      chosenLinkCandidateID: personDetails?.person_id || null,
    }),
  };

  const loadIdentityLinkCandidateActionsMap = {
    [LOAD_CHOSEN_LINK_CANDIDATE + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      linkCandidateData: {},
      loadingLinkCandidateData: true,
    }),
    [LOAD_CHOSEN_LINK_CANDIDATE + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      linkCandidateData: data || {},
      loadingLinkCandidateData: false,
    }),
    [LOAD_CHOSEN_LINK_CANDIDATE + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingLinkCandidateData: false,
    }),
  };

  const searchPersonByLoanActionsMap = {
    [SEARCH_PERSON_BY_LOAN + PENDING]: (state: PersonManagementStateType) => ({
      ...state,
      searchResults: [],
      loadingSearchresults: true,
    }),
    [SEARCH_PERSON_BY_LOAN + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      searchResults: data || [],
      loadingSearchresults: false,
    }),
    [SEARCH_PERSON_BY_LOAN + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      loadingSearchresults: false,
    }),
  };

  const personActionsMap = {
    [`${rolesList.PERSONMANAGEMENT.systemRoleName}${LOAD_ONE}${PENDING}`]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      selectedPerson: {},
      loadingSelectedPerson: true,
      personFlagsHistory: [],
      loadingPersonFlagsHistory: true,
      personBankAccounts: [],
      legalDocuments: [],
      personProducts: {},
    }),
    [`${rolesList.PERSONMANAGEMENT.systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      selectedPerson: data || {},
      loadingSelectedPerson: false,
      loadingPersonFlagsHistory: false,
    }),
    [`${rolesList.PERSONMANAGEMENT.systemRoleName}${LOAD_ONE}${FAIL}`]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingSelectedPerson: false,
      loadingPersonFlagsHistory: false,
    }),
    [LOAD_SELECTED_PERSON_FLAG + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingPersonFlags: true,
    }),
    [LOAD_SELECTED_PERSON_FLAG + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        person_flags: data.person_flags,
        retrieval_timestamp: data.retrieval_timestamp,
      },
      loadingPersonFlags: false,
    }),
    [LOAD_SELECTED_PERSON_FLAG + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      loadingPersonFlags: false,
    }),
    [PERSON_MANAGEMENT_LOAD_FLAG_HISTORY + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personFlagsHistory: [],
      loadingPersonFlagsHistory: true,
    }),
    [PERSON_MANAGEMENT_LOAD_FLAG_HISTORY + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      personFlagsHistory: (data || {}).person_flags || [],
      loadingPersonFlagsHistory: false,
    }),
    [PERSON_MANAGEMENT_LOAD_FLAG_HISTORY + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingPersonFlagsHistory: false,
    }),
  };

  const productsActionsMap = {
    [PERSON_MANAGEMENT_LOAD_PRODUCTS + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personProducts: {},
      loadingPersonProducts: true,
    }),
    [PERSON_MANAGEMENT_LOAD_PRODUCTS + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      personProducts: data || {},
      loadingPersonProducts: false,
    }),
    [PERSON_MANAGEMENT_LOAD_PRODUCTS + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingPersonProducts: false,
    }),
    [LOAD_PERSON_BANK_ACCOUNTS + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personBankAccounts: [],
      loadingPersonBankAccounts: true,
    }),
    [LOAD_PERSON_BANK_ACCOUNTS + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      personBankAccounts: data || [],
      loadingPersonBankAccounts: false,
    }),
    [LOAD_PERSON_BANK_ACCOUNTS + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      loadingPersonBankAccounts: false,
    }),
  };

  const legalDocumentMap = {
    [FIND_PERSON_LEGAL_DOCUMENT + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      extraLegalDocument: [],
      loadingExtraLegalDocument: true,
    }),
    [FIND_PERSON_LEGAL_DOCUMENT + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      legalDocuments: data || [],
      loadingExtraLegalDocument: false,
    }),
    [FIND_PERSON_LEGAL_DOCUMENT + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingExtraLegalDocument: false,
    }),
  };

  const formDataActionMap = {
    [`${rolesList.PERSONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${PENDING}`]:
        (state: PersonManagementStateType) => ({
          ...state,
          loadingDynamicData: true,
        }),
    [`${rolesList.PERSONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${SUCCESS}`]:
        (state: PersonManagementStateType, { payload: { data } }: any) => ({
          ...state,
          dynamicData: {
            ...state.dynamicData,
            ...(data.actions || {}).PATCH,
          },
          loadingDynamicData: false,
        }),
    [`${rolesList.PERSONMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${FAIL}`]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
      },
      loadingDynamicData: false,
    }),
    [LOAD_ADDRESS_DYNAMIC_DATA + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [LOAD_ADDRESS_DYNAMIC_DATA + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
        ...(data.actions || {}).POST,
      },
      loadingDynamicData: false,
    }),
    [LOAD_ADDRESS_DYNAMIC_DATA + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
      },
      loadingDynamicData: false,
    }),
  };

  const personCacheMap = {
    [PERSON_MANAGEMENT_LOAD_CACHE + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personActiveJourneys: [],
      loadingPersonCache: true,
    }),
    [PERSON_MANAGEMENT_LOAD_CACHE + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      personActiveJourneys: data || [],
      loadingPersonCache: false,
    }),
    [PERSON_MANAGEMENT_LOAD_CACHE + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personActiveJourneys: [],
      loadingPersonCache: false,
    }),
  };

  const processingCustomerDocuments = {
    [LOAD_PERSON_CUSTOMER_REPORT]: (state: PersonManagementStateType) => ({
      ...state,
      loadingCustomerRecord: true,
    }),
    [LOAD_PERSON_CUSTOMER_REPORT + SUCCESS]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingCustomerRecord: false,
    }),
    [LOAD_PERSON_CUSTOMER_REPORT + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingCustomerRecord: false,
    }),
    [LOAD_PERSON_DSAR_REPORT]: (state: PersonManagementStateType) => ({
      ...state,
      loadingCustomerRecord: true,
    }),
    [LOAD_PERSON_DSAR_REPORT + SUCCESS]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingCustomerRecord: false,
    }),
    [LOAD_PERSON_DSAR_REPORT + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      loadingCustomerRecord: false,
    }),
    [PERSON_MANAGEMENT_REGENERATE_DOCUMENTS]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingRegeneratePersonDocuments: true,
    }),
    [PERSON_MANAGEMENT_REGENERATE_DOCUMENTS + SUCCESS]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingRegeneratePersonDocuments: false,
    }),
    [PERSON_MANAGEMENT_REGENERATE_DOCUMENTS + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      loadingRegeneratePersonDocuments: false,
    }),
  };

  const uploadFileFormOptions = {
    [PERSON_MANAGEMENT_UPLOADFILE_OPTIONS + SUCCESS]: (
      state: PersonManagementStateType,
      { payload: { data } }: any
    ) => ({
      ...state,
      fileUploadDynamicData: {
        ...(data.actions || {}).POST,
      },
    }),
  };

  const addressMatchActionMap = {
    [ADDRESS_MATCH + PENDING]: (state: PersonManagementStateType) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        loadingAddressMatch: true,
      },
    }),
    [ADDRESS_MATCH + SUCCESS]: (
      state: PersonManagementStateType,
      {
        payload: {
          data: { matched_addresses },
        },
      }: any
    ) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        addressMatch: matched_addresses,
        loadingAddressMatch: matched_addresses.length === 0,
      },
    }),
    [ADDRESS_MATCH + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        loadingAddressMatch: false,
      },
    }),
    [POLL_ADDRESS_MATCH + PENDING]: (state: PersonManagementStateType) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        loadingAddressMatch: true,
      },
    }),
    [POLL_ADDRESS_MATCH + SUCCESS]: (
      state: PersonManagementStateType,
      {
        payload: {
          data: { matched_addresses },
        },
      }: any
    ) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        addressMatch: matched_addresses,
        loadingAddressMatch: matched_addresses.length === 0,
      },
    }),
    [POLL_ADDRESS_MATCH + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        loadingAddressMatch: false,
      },
    }),

    [IDENTITY_VERIFICATION + PENDING]: (state: PersonManagementStateType) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        loadingIdentityVerification: true,
      },
    }),
    [IDENTITY_VERIFICATION + SUCCESS]: (
      state: PersonManagementStateType,
      {
        payload: {
          data: {
            task_status: identityVerificationTicketStatus,
            idv_assessment_decision: idvAssesmentDecision,
            ticket_id: identityVerificationTicketId,
          },
        },
      }: any
    ) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        identityVerificationTicketStatus,
        identityVerificationTicketId,
        idvAssesmentDecision,
        loadingIdentityVerification:
            identityVerificationTicketStatus === "PENDING" ||
            identityVerificationTicketStatus === "PROCESSING",
      },
    }),
    [IDENTITY_VERIFICATION + FAIL]: (state: PersonManagementStateType) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        loadingIdentityVerification: false,
      },
    }),
    [POLL_IDENTITY_VERIFICATION + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        loadingIdentityVerification: true,
      },
    }),
    [POLL_IDENTITY_VERIFICATION + SUCCESS]: (
      state: PersonManagementStateType,
      {
        payload: {
          data: {
            task_status: identityVerificationTicketStatus,
            idv_assessment_decision: idvAssesmentDecision,
          },
        },
      }: any
    ) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        identityVerificationTicketStatus,
        idvAssesmentDecision,
        loadingIdentityVerification:
            identityVerificationTicketStatus === "PENDING" ||
            identityVerificationTicketStatus === "PROCESSING",
      },
    }),
    [POLL_IDENTITY_VERIFICATION + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        loadingIdentityVerification: false,
      },
    }),

    // Person IDV
    [NAME_IDENTITY_VERIFICATION + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personUpdateTool: {
        ...state.personUpdateTool,
        loadingIdentityVerification: true,
      },
    }),
    [NAME_IDENTITY_VERIFICATION + SUCCESS]: (
      state: PersonManagementStateType,
      {
        payload: {
          data: {
            task_status: identityVerificationTicketStatus,
            idv_assessment_decision: idvAssesmentDecision,
            ticket_id: identityVerificationTicketId,
          },
        },
      }: any
    ) => ({
      ...state,
      personUpdateTool: {
        ...state.personUpdateTool,
        identityVerificationTicketStatus,
        identityVerificationTicketId,
        idvAssesmentDecision,
        loadingIdentityVerification:
            identityVerificationTicketStatus === "PENDING" ||
            identityVerificationTicketStatus === "PROCESSING",
      },
    }),
    [NAME_IDENTITY_VERIFICATION + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personUpdateTool: {
        ...state.personUpdateTool,
        loadingNameIdentityVerification: false,
      },
    }),
    [POLL_NAME_IDENTITY_VERIFICATION + PENDING]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personUpdateTool: {
        ...state.personUpdateTool,
        loadingIdentityVerification: true,
      },
    }),
    [POLL_NAME_IDENTITY_VERIFICATION + SUCCESS]: (
      state: PersonManagementStateType,
      {
        payload: {
          data: {
            task_status: identityVerificationTicketStatus,
            idv_assessment_decision: idvAssesmentDecision,
          },
        },
      }: any
    ) => ({
      ...state,
      personUpdateTool: {
        ...state.personUpdateTool,
        identityVerificationTicketStatus,
        idvAssesmentDecision,
        loadingIdentityVerification:
            identityVerificationTicketStatus === "PENDING" ||
            identityVerificationTicketStatus === "PROCESSING",
      },
    }),
    [POLL_NAME_IDENTITY_VERIFICATION + FAIL]: (
      state: PersonManagementStateType
    ) => ({
      ...state,
      personUpdateTool: {
        ...state.personUpdateTool,
        loadingIdentityVerification: false,
      },
    }),

    [CLEAR_NAME_IDENTITY_VERIFICATION]: (state: PersonManagementStateType) => ({
      ...state,
      personUpdateTool: {
        identityVerificationTicketStatus: null,
        identityVerificationTicketId: null,
        loadingIdentityVerification: false,
        idvAssesmentDecision: null,
      },
    }),

    // end of PERSON IDV

    [ADDRESS_FINDER_CLEAN]: (state: PersonManagementStateType) => ({
      ...state,
      addressUpdateTool: {
        ...state.addressUpdateTool,
        addressMatch: [],
        identityVerificationTicketStatus: null,
        identityVerificationTicketId: null,
        idvAssesmentDecision: null,
      },
    }),
  };

  actionsMap = {
    ...searchPersonByDetailsActionsMap,
    ...searchPersonByLoanActionsMap,
    ...personActionsMap,
    ...productsActionsMap,
    ...legalDocumentMap,
    ...personCacheMap,
    ...processingCustomerDocuments,
    ...formDataActionMap,
    ...uploadFileFormOptions,
    ...addressMatchActionMap,
    ...searchIdentityLinkCandidatesActionsMap,
    ...chosenLinkCandidateActionsMap,
    ...loadIdentityLinkCandidateActionsMap,
  };
}
export default function reducer(
  state: PersonManagementStateType = initialState,
  action: Actions
) {
  if (action.type === GET_NATIONALITIES_LIST) {
    return {
      ...state,
      loadingNationalities: true,
    };
  }

  if (action.type === GET_NATIONALITIES_LIST + SUCCESS) {
    return {
      ...state,
      loadingNationalities: false,
      nationalities: action.payload.data,
    };
  }

  if (action.type === GET_NATIONALITIES_LIST + FAIL) {
    return {
      ...state,
      loadingNationalities: false,
      nationalities: [],
    };
  }

  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
