import {
  LOAD_ALL,
  LOAD_ONE,
  UPDATE_ONE,
  CREATE,
  LOAD_FORM_DATA,
} from "Store/Constants/generic";
import {
  LOANFUNDINGBUCKETTRANSACTION_CREATE,
  LOANFUNDINGBUCKETTRANSACTION_LOAD,
} from "Store/Constants/fundingBuckets";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {FundingBucketsStateType} from "Types/fundingBuckets";
import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

export const initialState: FundingBucketsStateType = {
  all: [],
  selectedFundingBucket: {},
  loadingFundingBucketData: false,
  sendingFundingBucketData: false,
  message: "",
  dynamicData: {},
  loadingDynamicData: false,
  transactions: [],
};

let actionsMap = {};

if (
  rolesList.LOANFUNDINGBUCKETMANAGEMENT &&
  rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName
) {
  const fundingBucketsActionsMap = {
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_ALL}${PENDING}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      all: [],
      loadingFundingBucketData: true,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_ALL}${SUCCESS}`]: (
      state: FundingBucketsStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      all: data || [],
      loadingFundingBucketData: false,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_ALL}${FAIL}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      all: [],
      loadingFundingBucketData: false,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_ONE}${PENDING}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      selectedFundingBucket: {},
      loadingFundingBucketData: true,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: FundingBucketsStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      selectedFundingBucket: data || {},
      loadingFundingBucketData: false,
      message: "",
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_ONE}${FAIL}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      selectedFundingBucket: {},
      loadingFundingBucketData: false,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${CREATE}${PENDING}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      sendingFundingBucketData: true,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${CREATE}${SUCCESS}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      sendingFundingBucketData: false,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${CREATE}${FAIL}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      sendingFundingBucketData: false,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${UPDATE_ONE}${PENDING}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      sendingFundingBucketData: true,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${UPDATE_ONE}${SUCCESS}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      sendingFundingBucketData: false,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${UPDATE_ONE}${FAIL}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      sendingFundingBucketData: false,
    }),
  };

  const transactionsActionMap = {
    // load transactions the fundingBucket
    [LOANFUNDINGBUCKETTRANSACTION_LOAD + PENDING]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      loadingFundingBucketData: true,
    }),
    [LOANFUNDINGBUCKETTRANSACTION_LOAD + SUCCESS]: (
      state: FundingBucketsStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      loadingFundingBucketData: false,
      transactions: data,
    }),
    [LOANFUNDINGBUCKETTRANSACTION_LOAD + FAIL]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      loadingFundingBucketData: false,
    }),
    // create one transaction of the fundingBucket
    [LOANFUNDINGBUCKETTRANSACTION_CREATE + PENDING]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      loadingFundingBucketData: true,
    }),
    [LOANFUNDINGBUCKETTRANSACTION_CREATE + SUCCESS]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      loadingFundingBucketData: false,
    }),
    [LOANFUNDINGBUCKETTRANSACTION_CREATE + FAIL]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      loadingFundingBucketData: false,
    }),
  };

  const formDataActionMap = {
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${PENDING}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${SUCCESS}`]: (
      state: FundingBucketsStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      dynamicData: {
        ...(data.actions || {}).POST,
      },
      loadingDynamicData: false,
    }),
    [`${rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${FAIL}`]: (
      state: FundingBucketsStateType
    ) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
      },
      loadingDynamicData: false,
    }),
  };

  actionsMap = {
    ...fundingBucketsActionsMap,
    ...transactionsActionMap,
    ...formDataActionMap,
  };
}

export default function reducer(
  state: FundingBucketsStateType = initialState,
  action: Actions
): FundingBucketsStateType {
  const fn: (
    state: FundingBucketsStateType,
    action: Actions
  ) => FundingBucketsStateType =
    actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
