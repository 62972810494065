//@flow
import React, { Component } from 'react';
import {
  Opacity,
  Card,
} from '@chetwoodfinancial/staff-app-visual-components';

type Props = {};

class NotFound extends Component<Props> {
  render() {
    return (
      <Opacity speed={ 300 }>
        <div className='not-found-container'>
          <div
            className='grid-container'
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <div className='row'>
              <div className='col col-lg-12'>
                <div className='main-container-content'>
                  <div className='row'>
                    <div className='col-lg-5 col-lg-offset-3'>
                      <h3 className='error'>Under construction</h3>
                    </div>
                    <div className='col-lg-4'>
                      <Card isRounded>
                        <img
                          src='/images/under-construction.jpg'
                          alt='under-construction'
                          style={{ width: '100%' }}
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Opacity>
    );
  }
}

export default NotFound;
