//@flow
import {
  OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH,
  OUTBOUND_PAYMENT_TRANSACTIONS_ACT,
  LOAD_OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH_DATA,
} from 'Store/Constants/outboundPaymentTransactions';


/**
 * searchTransactions - Load all transactions with the provided search parameters
 *
 * @param {string} searchParams   Object with search parameters
 *
 * @return {action}               Action creator which will resolve to an array of objects
 */
export const searchTransactions = (data: Object, offset: number = 0) => {
  const outboundIds = [];
  const depositIds = [];
  const inboundIds = [];
  const expectedCFIds = [];

  if (data && data.outbound_payment_transaction_ids) {
    const splitStatusIds = (data.outbound_payment_transaction_ids || '').split(',');

    splitStatusIds.forEach((id: string) => {
      outboundIds.push(id);
    });
  }
  if (data && data.deposit_account_transaction_ids) {
    const splitStatusIds = (data.deposit_account_transaction_ids || '').split(',');

    splitStatusIds.forEach((id: string) => {
      depositIds.push(id);
    });
  }
  if (data && data.inbound_payment_transaction_ids) {
    const splitStatusIds = (data.inbound_payment_transaction_ids || '').split(',');

    splitStatusIds.forEach((id: string) => {
      inboundIds.push(id);
    });
  }
  if (data && data.loan_version_expected_cashflow_ids) {
    const splitStatusIds = (data.loan_version_expected_cashflow_ids || '').split(',');

    splitStatusIds.forEach((id: string) => {
      expectedCFIds.push(id);
    });
  }

  return {
    type: OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH,
    payload: {
      client: 'authenticatedClient',
      request: {
        method: 'POST',
        url: '/api/outbound_payment_transaction_management/search/',
        data: {
          search: {
            ...data,
            outbound_payment_transaction_ids: outboundIds,
            deposit_account_transaction_ids: depositIds,
            inbound_payment_transaction_ids: inboundIds,
            loan_version_expected_cashflow_ids: expectedCFIds,
            result_offset: offset,
          },
        },
      },
    },
  };
};

/**
 * outboundTransactionsAct - Submit aoutbound transactions to specified URL
 *
 * @param {string} script   Object with search parameters
 * @param {object} values   Object with search parameters
 *
 * @return {action}               Action creator which will resolve to an array of objects
 */
export const outboundTransactionsAct = (values: Object, selectedTransactions: Object) => {
  // TODO: add action for repay once data structure is defined
  // TODO: check payloads against valid transaction IDs
  const payload = {
    actionedTransactions: [],
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: '',
      data: {},
    },
  };

  if (values.action_name === 'approve') {
    payload.request.url = '/api/outbound_payment_transaction_management/approve/';
    payload.request.data.retrieval_timestamp = selectedTransactions[0].retrieval_timestamp;
    payload.request.data.outbound_payment_transactions_ids_approve = selectedTransactions.map((t: Object) => ({
      outbound_payment_transaction_id: t.outbound_payment_transaction_id,
      outbound_payment_transaction_status_id: t.outbound_payment_transaction_status_id,
    }));
  }

  if (values.action_name === 'cancel') {
    payload.request.url = '/api/outbound_payment_transaction_management/cancel/';
    payload.request.data.retrieval_timestamp = selectedTransactions[0].retrieval_timestamp;
    payload.request.data.outbound_payment_transactions_ids_mark_as_cancelled = selectedTransactions.map((t: Object) => ({
      outbound_payment_transaction_id: t.outbound_payment_transaction_id,
      outbound_payment_transaction_status_id: t.outbound_payment_transaction_status_id,
    }));
  }

  if (values.action_name === 'manually_send') {
    payload.request.url = '/api/outbound_payment_transaction_management/manually_send/';
    payload.request.data.retrieval_timestamp = selectedTransactions[0].retrieval_timestamp;
    payload.request.data.outbound_payment_transactions_ids_mark_as_manually_sent = selectedTransactions.map((t: Object) => ({
      outbound_payment_transaction_id: t.outbound_payment_transaction_id,
      outbound_payment_transaction_status_id: t.outbound_payment_transaction_status_id,
    }));
  }

  if (values.action_name === 'resubmit') {
    payload.request.url = '/api/outbound_payment_transaction_management/resubmit/';
    payload.request.data.retrieval_timestamp = selectedTransactions[0].retrieval_timestamp;
    payload.request.data.outbound_payment_transaction_ids_resubmit = selectedTransactions.map((t: Object) => ({
      outbound_payment_transaction_id: t.outbound_payment_transaction_id,
      outbound_payment_transaction_status_id: t.outbound_payment_transaction_status_id,
    }));
  }

  if (values.action_name === 'resubmit_different_details') {
    payload.request.url = '/api/outbound_payment_transaction_management/resubmit_different_details/';
    payload.request.data.retrieval_timestamp = selectedTransactions[0].retrieval_timestamp;
    payload.request.data.outbound_payment_transaction_ids_resubmit = selectedTransactions.map((t: Object) => ({
      outbound_payment_transaction_id: t.outbound_payment_transaction_id,
      outbound_payment_transaction_status_id: t.outbound_payment_transaction_status_id,
    }));
    payload.request.data.outbound_payment_transactions_resubmit_detail = values.outbound_payment_transactions_resubmit_detail || {};
  }

  payload.actionedTransactions = selectedTransactions.map((t: Object) => (t.outbound_payment_transaction_id));

  return {
    type: OUTBOUND_PAYMENT_TRANSACTIONS_ACT,
    payload,
  };
};

export const loadOutboundPaymentTransactionSearchData = () => ({
  type: LOAD_OUTBOUND_PAYMENT_TRANSACTIONS_SEARCH_DATA,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'OPTIONS',
      url: '/api/outbound_payment_transaction_management/search',
    },
  },
});
