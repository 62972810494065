//@flow
/* eslint-disable prefer-destructuring */
import React from 'react';

import appSettings from 'GlobalConfigs/settings';

//$FlowFixMe
import { rolesList } from 'GlobalConfigs';

import {
  SUCCESS,
  FAIL,
} from 'Store/Constants/axios';
import {
  UPDATE_ONE,
  CREATE,
  DELETE,
} from 'Store/Constants/generic';

import {
  LOANPRODUCTMANAGEMENT_SUSPEND_PRODUCT,
  LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT,
  LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT,
} from 'Store/Constants/loanProducts';
import {
  DEPOSITPRODUCTMANAGEMENT_EXPIRE_PRODUCT,
  DEPOSITPRODUCTMANAGEMENT_LAUNCH_PRODUCT,
  DEPOSITPRODUCTMANAGEMENT_SUSPEND_PRODUCT,
} from 'Store/Constants/depositProducts';
import { LOANFUNDINGBUCKETTRANSACTION_CREATE } from 'Store/Constants/fundingBuckets';
import {
  LOAN_MANAGEMENT_ACT,
  LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE,
  LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS,
} from 'Store/Constants/loanManagement';
import { DEPOSIT_MANAGEMENT_ACT } from 'Store/Constants/depositManagement';
import { APPLY_DECISION } from 'Store/Constants/creditDeclineOverturn';
import {
  TESTOUTBOUNDPAYMENT_CREATE,
  TESTOUTBOUNDPAYMENT_APPROVE,
  TESTOUTBOUNDPAYMENT_CANCEL,
} from 'Store/Constants/testOutboundPayment';

import {
  EMPLOYEE_PROFILES_ASSIGN,
  EMPLOYEE_PROFILES_UNASSIGN,
  EMPLOYEES_LOCK,
  EMPLOYEES_SUSPEND,
} from 'Store/Constants/employees';

import {
  PERSON_MANAGEMENT_UPDATE_PERSON,
  PERSON_MANAGEMENT_REGENERATE_DOCUMENTS,
  PERSON_MANAGEMENT_UPDATE_FLAG,
  PERSON_MANAGEMENT_CLEAR_CACHE,
  PERSON_MANAGEMENT_UPDATE_ADDRESS,
  PERSON_MANAGEMENT_UPLOAD_FILE,
} from 'Store/Constants/personManagement';
import { INBOUND_PAYMENT_TRANSACTIONS_ACT } from 'Store/Constants/inboundPaymentTransactions';
import { OUTBOUND_PAYMENT_TRANSACTIONS_ACT } from 'Store/Constants/outboundPaymentTransactions';

import {
  addNotification,
  addToast,
  showPopup,
} from 'Store/Actions/notificationCenter';
import {
  loadOne,
  loadAll,
} from 'Store/Actions/generic';
import { loadFundingBucketsTransaction } from 'Store/Actions/fundingBuckets';

import {
  IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY,
  IDENTITY_MANAGEMENT_BLOCK_IDENTITY,
  IDENTITY_MANAGEMENT_UNBLOCK_IDENTITY,
  IDENTITY_MANAGEMENT_LINK_CANDIDATE_TO_IDENTITY,
  IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_DELETE,
} from 'Store/Constants/identityManagement';

const envSupressError = appSettings.REACT_APP_SUPRESS_ERRORS;
const supressErrors = !!envSupressError || false;

export default (store: Object) => (next: Function) => (action: { type: string, payload: Object, meta: Object, error: Object, }) => {
  if (action.type.includes(FAIL) && !action.type.includes('@@redux-form')) {
    if (action.type === 'PERSON_MANAGEMENT_UPDATE_ADDRESS_FAIL') {
      const copyResError = { ...(((action.error || {}).response || {}).data || {}) };
      if (copyResError.new_address) delete copyResError.new_address;
      if (Object.keys(copyResError).length > 0) {
        store.dispatch(
          addNotification({
            errors: {
              other: ((action.error || {}).response || {}).data || {},
            },
            type: 'fail',
          })
        );
      }
      return next(action);
    }

    if (action.type === 'IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY_FAIL' || action.type === 'IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL_FAIL') {
      addToast('error', 'Identity not created. Email address already in use.');
      return next(action);
    }

    if (action.error && action.error.response && action.error.response.data.detail) {
      const message = (
        <>
          <p className='white'>The following error occured:</p>
          <p className='white'>{action.error.response.data.detail}</p>
          <hr />
          <p className='white'>Attempted action:</p>
          <p className='white'>{action.type.replace('_FAIL', '').replace(/_/g, ' ')}</p>
        </>
      );
      addToast('error', message);
      return next(action);
    }
    if (!supressErrors) {
      const otherNotifications = (store.getState().notificationCenter.notifications || []).filter(
        (notification: Object) => notification.type === 'fail' && notification.errors && notification.errors.other
      );

      if (otherNotifications.length < 1) {
        const displayedError = ((action.error || {}).response || {}).data || {};
        store.dispatch(
          addNotification({
            errors: {
              other: displayedError,
            },
            type: 'fail',
          })
        );
      }
      return next(action);
    }
    return next(action);
  }

  switch (action.type) {
    // Success messages (for new roles add more cases here)
    // Role updates
    case `${ EMPLOYEES_LOCK }${ SUCCESS }`:
    case `${ EMPLOYEES_SUSPEND }${ SUCCESS }`:
    case `${ (rolesList.EMPLOYEEMANAGEMENT || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Employee updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.LOANFUNDINGBUCKETMANAGEMENT || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Funding bucket updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.HOLIDAYCALENDAR || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Holiday calendar updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.LOANPRODUCTMANAGEMENT || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Loan product updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.PORTFOLIOMANAGEMENT || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Portfolio updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.DEPOSITPRODUCTMANAGEMENT || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Deposit Product updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.FRAUDREFERS || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Fraud referral updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.LEBANKACCOUNT || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Bank account updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case PERSON_MANAGEMENT_UPDATE_PERSON + SUCCESS:
      addToast('success', 'Person updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case PERSON_MANAGEMENT_REGENERATE_DOCUMENTS + SUCCESS:
      addToast('success', 'Documents re-generated successfully');
      return next(action);
    case PERSON_MANAGEMENT_UPDATE_FLAG + SUCCESS:
      addToast('success', 'Flags submitted successfully');
      return next(action);
    case PERSON_MANAGEMENT_UPDATE_ADDRESS + SUCCESS:
      addToast('success', 'Address updated successfully');
      return next(action);
    case `${ (rolesList.PERSONMANAGEMENT || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Person details updated');
      return next(action);
    case PERSON_MANAGEMENT_CLEAR_CACHE + SUCCESS:
      addToast('success', 'Person cache cleared');
      return next(action);
    case PERSON_MANAGEMENT_UPLOAD_FILE + SUCCESS:
      addToast('success', 'File uploaded successfully');
      return next(action);
    case `${ (rolesList.PROFILEMANAGEMENT || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      addToast('success', 'Profile updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.CREDITDECLINEOVERTURN || {}).systemRoleName }${ APPLY_DECISION }${ SUCCESS }`:
      addToast('success', 'Overturn decision updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ INBOUND_PAYMENT_TRANSACTIONS_ACT }${ SUCCESS }`:
    case `${ OUTBOUND_PAYMENT_TRANSACTIONS_ACT }${ SUCCESS }`:
      addToast('success', 'Transaction updated', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE }${ SUCCESS }`:
      addToast('success', 'Payment successful', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ TESTOUTBOUNDPAYMENT_APPROVE }${ SUCCESS }`:
      addToast('success', 'Test outbound payment approved', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ TESTOUTBOUNDPAYMENT_CANCEL }${ SUCCESS }`:
      addToast('success', 'Test outbound payment cancelled', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);

    // Role creates
    case `${ (rolesList.EMPLOYEEMANAGEMENT || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      addToast('success', 'Employee created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ rolesList.LOANFUNDINGBUCKETMANAGEMENT.systemRoleName }${ CREATE }${ SUCCESS }`:
      addToast('success', 'Funding bucket created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.HOLIDAYCALENDAR || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      addToast('success', 'Holiday calendar created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.LOANPRODUCTMANAGEMENT || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      addToast('success', 'Loan product created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.DEPOSITPRODUCTMANAGEMENT || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      addToast('success', 'Deposit product created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.PORTFOLIOMANAGEMENT || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      addToast('success', 'Portfolio created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.LEBANKACCOUNT || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      addToast('success', 'Bank account created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.PROFILEMANAGEMENT || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      addToast('success', 'Profile created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ TESTOUTBOUNDPAYMENT_CREATE }${ SUCCESS }`:
      addToast('success', 'Test outbound payment created', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);

    // Role deletes
    case `${ (rolesList.EMPLOYEEMANAGEMENT || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      addToast('success', 'Employee deleted', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.LOANFUNDINGBUCKETMANAGEMENT || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      addToast('success', 'Funding bucket deleted', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.HOLIDAYCALENDAR || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      addToast('success', 'Holiday calendar deleted', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.LOANPRODUCTMANAGEMENT || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      addToast('success', 'Loan product deleted', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.DEPOSITPRODUCTMANAGEMENT || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      addToast('success', 'Deposit product deleted', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.PORTFOLIOMANAGEMENT || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      addToast('success', 'Portfolio deleted', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.LEBANKACCOUNT || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      addToast('success', 'Bank account deleted', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case `${ (rolesList.PROFILEMANAGEMENT || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      addToast('success', 'Profile deleted', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);

    case `${ LOANPRODUCTMANAGEMENT_LAUNCH_PRODUCT }${ SUCCESS }`:
    case `${ DEPOSITPRODUCTMANAGEMENT_LAUNCH_PRODUCT }${ SUCCESS }`:
      addToast('success', 'Product launched');
      return next(action);
    case `${ DEPOSITPRODUCTMANAGEMENT_EXPIRE_PRODUCT }${ SUCCESS }`:
    case `${ LOANPRODUCTMANAGEMENT_EXPIRE_PRODUCT }${ SUCCESS }`:
      addToast('success', 'Product expired');
      return next(action);
    case `${ LOANPRODUCTMANAGEMENT_SUSPEND_PRODUCT }${ SUCCESS }`:
    case `${ DEPOSITPRODUCTMANAGEMENT_SUSPEND_PRODUCT }${ SUCCESS }`:
      if (!action.payload.data || !action.payload.data.length) {
        addToast('success', 'Action performed');
      } else {
        const productId = action.meta.previousAction.payload.depositProductId || action.meta.previousAction.payload.loanProductId;
        const productPayload = (action.payload.data || []).find((product: Object) => product.loan_product_id === productId || product.deposit_product_id === productId);
        addToast('success', `${ productPayload.suspended ? 'Product suspended' : 'Product unsuspended' }`);
      }
      return next(action);

    // Loan Management
    case LOAN_MANAGEMENT_ACT + SUCCESS:
    case DEPOSIT_MANAGEMENT_ACT + SUCCESS:
      addToast('success', 'Values have been submitted successfully', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS + SUCCESS:
      if (!action.payload.data.available_products || !action.payload.data.available_products.length) {
        addToast('error', 'No matching products found for selected parameters');
      }
      return next(action);

    // Funding Buckets
    case LOANFUNDINGBUCKETTRANSACTION_CREATE + SUCCESS:
      addToast('success', 'Transaction recorded');
      store.dispatch(loadOne('LOANFUNDINGBUCKETMANAGEMENT', action.meta.previousAction.payload.request.data.loan_funding_bucket_id));
      store.dispatch(loadFundingBucketsTransaction(action.meta.previousAction.payload.request.data.loan_funding_bucket_id));
      return next(action);

    // Employee profile management
    case EMPLOYEE_PROFILES_ASSIGN + SUCCESS:
    case EMPLOYEE_PROFILES_UNASSIGN + SUCCESS:
      addToast('success', 'Employee profiles updated');
      return next(action);

    case `${ (rolesList.FEECONFIGURATION || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      store.dispatch(showPopup());
      return next(action);

    case `${ (rolesList.FEECONFIGURATION || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      store.dispatch(showPopup());
      return next(action);

    case `${ (rolesList.ACCOUNTING || {}).systemRoleName }${ CREATE }${ SUCCESS }`:
      if (action?.meta?.previousAction?.payload?.request?.url.includes('chart_of_accounts')) {
        store.dispatch(loadAll('ACCOUNTING', null, 'chart_of_accounts'));
        store.dispatch(showPopup(null, 'create'));
      }
      if (action?.meta?.previousAction?.payload?.request?.url.includes('template')) {
        const journalClassificationId = action?.meta?.previousAction?.payload?.request?.data?.accounting_journal_classification_id;
        store.dispatch(loadOne('ACCOUNTING', journalClassificationId, undefined, 'template'));
        store.dispatch(showPopup(null, 'create'));
      }
      return next(action);
    case `${ (rolesList.ACCOUNTING || {}).systemRoleName }${ UPDATE_ONE }${ SUCCESS }`:
      if (action?.meta?.previousAction?.payload?.request?.url.includes('chart_of_accounts')) {
        store.dispatch(loadAll('ACCOUNTING', null, 'chart_of_accounts'));
        store.dispatch(showPopup(null, 'edit'));
      }
      if (action?.meta?.previousAction?.payload?.request?.url.includes('template')) {
        const journalClassificationId = action?.meta?.previousAction?.payload?.request?.data?.accounting_journal_classification_id;
        store.dispatch(loadOne('ACCOUNTING', journalClassificationId, undefined, 'template'));
        store.dispatch(showPopup(null, 'edit'));
      }
      return next(action);
    case `${ (rolesList.ACCOUNTING || {}).systemRoleName }${ DELETE }${ SUCCESS }`:
      if (action?.meta?.previousAction?.payload?.request?.url.includes('chart_of_accounts')) {
        store.dispatch(loadAll('ACCOUNTING', null, 'chart_of_accounts'));
      }
      if (action?.meta?.previousAction?.payload?.request?.url.includes('template')) {
        const journalClassificationId = action?.payload?.data[0].accounting_journal_classification_id;
        store.dispatch(loadOne('ACCOUNTING', journalClassificationId, undefined, 'template'));
        store.dispatch(loadAll('ACCOUNTING', null, 'chart_of_accounts'));
      }
      return next(action);
    case IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY + SUCCESS:
      addToast('success', 'New identity created');
      return next(action);

    case IDENTITY_MANAGEMENT_BLOCK_IDENTITY + SUCCESS:
      addToast('success', 'Identity blocked');
      return next(action);

    case IDENTITY_MANAGEMENT_UNBLOCK_IDENTITY + SUCCESS:
      addToast('success', 'Identity unblocked');
      return next(action);

    case IDENTITY_MANAGEMENT_LINK_CANDIDATE_TO_IDENTITY + SUCCESS:
      addToast('success', 'Identity linked to new person', { redirectURL: action.meta.previousAction.payload.redirectTo });
      return next(action);
    case IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_DELETE + SUCCESS:
      addToast('success', 'Orphan identity deleted');
      return next(action);

    default:
      return next(action);
  }
};
