import {LOAD_ALL, LOAD_ONE, UPDATE_ONE, CREATE} from "Store/Constants/generic";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {PortfolioStateType} from "Types/portfolios";
import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

export const initialState: PortfolioStateType = {
  all: [],
  selectedPortfolio: {},
  loadingPortfolioData: false,
  sendingPortfolioData: false,
  message: "",
};

let actionsMap: Record<
  string,
  (state: PortfolioStateType, action: Actions) => PortfolioStateType
> = {};

if (
  rolesList.PORTFOLIOMANAGEMENT &&
  rolesList.PORTFOLIOMANAGEMENT.systemRoleName
) {
  const portfoliosActionsMap = {
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${LOAD_ALL}${PENDING}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      all: [],
      loadingPortfolioData: true,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${LOAD_ALL}${SUCCESS}`]: (
      state: PortfolioStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      all: data || [],
      loadingPortfolioData: false,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${LOAD_ALL}${FAIL}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      all: [],
      loadingPortfolioData: false,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${LOAD_ONE}${PENDING}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      selectedPortfolio: {},
      loadingPortfolioData: true,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: PortfolioStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      selectedPortfolio: data || {},
      loadingPortfolioData: false,
      message: "",
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${LOAD_ONE}${FAIL}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      selectedPortfolio: {},
      loadingPortfolioData: false,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${CREATE}${PENDING}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      sendingPortfolioData: true,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${CREATE}${SUCCESS}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      sendingPortfolioData: false,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${CREATE}${FAIL}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      sendingPortfolioData: false,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${UPDATE_ONE}${PENDING}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      sendingPortfolioData: true,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${UPDATE_ONE}${SUCCESS}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      sendingPortfolioData: false,
    }),
    [`${rolesList.PORTFOLIOMANAGEMENT.systemRoleName}${UPDATE_ONE}${FAIL}`]: (
      state: PortfolioStateType
    ) => ({
      ...state,
      sendingPortfolioData: false,
    }),
  };

  actionsMap = {
    ...portfoliosActionsMap,
  };
}

export default function reducer(
  state: PortfolioStateType = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
