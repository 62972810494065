//@flow
import {
  INBOUND_PAYMENT_TRANSACTIONS_SEARCH,
  INBOUND_PAYMENT_TRANSACTIONS_ACT,
} from 'Store/Constants/inboundPaymentTransactions';


/**
 * searchTransactions - Load all transactions with the provided search parameters
 *
 * @param {string} searchParams   Object with search parameters
 *
 * @return {action}               Action creator which will resolve to an array of objects
 */
export const searchTransactions = (data: Object, offset: number = 0) => {
  const statusIds = [];

  if (data && data.inbound_payment_transaction_ids) {
    const splitStatusIds = (data.inbound_payment_transaction_ids || '').split(',');

    splitStatusIds.forEach((id: string) => {
      statusIds.push(id);
    });
  }

  return {
    type: INBOUND_PAYMENT_TRANSACTIONS_SEARCH,
    payload: {
      client: 'authenticatedClient',
      request: {
        method: 'POST',
        url: '/api/inbound_payment_transaction_management/search/',
        data: {
          search: {
            ...data,
            inbound_payment_transaction_ids: statusIds,
            result_offset: offset,
          },
        },
      },
    },
  };
};


/**
 * outboundTransactionsAct - Submit aoutbound transactions to specified URL
 *
 * @param {string} script   Object with search parameters
 * @param {object} values   Object with search parameters
 *
 * @return {action}               Action creator which will resolve to an array of objects
 */
export const inboundTransactionsAct = (values: Object, selectedTransactions: Object) => {
  // TODO: add action for repay once data structure is defined
  // TODO: check payloads against valid transaction IDs
  const payload = {
    actionedTransactions: [],
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: '',
      data: {},
    },
  };

  if (values.action_name === 'allocate_deposit_account') {
    payload.request.url = '/api/inbound_payment_transaction_management/allocate_deposit_account/';
    payload.request.data.retrieval_timestamp = selectedTransactions[0].retrieval_timestamp;
    payload.request.data.deposit_account_allocate_inbound_payment = {
      deposit_account_id: values.deposit_account_id,
      inbound_payment_transaction_id: selectedTransactions[0].inbound_payment_transaction_id,
      inbound_payment_transaction_status_id: selectedTransactions[0].inbound_payment_transaction_status_id,
    };
  }

  if (values.action_name === 'mark_returned') {
    payload.request.url = '/api/inbound_payment_transaction_management/mark_returned/';
    payload.request.data.retrieval_timestamp = selectedTransactions[0].retrieval_timestamp;
    payload.request.data.inbound_payments_mark_as_returned = selectedTransactions.map((t: Object) => ({
      inbound_payment_transaction_id: t.inbound_payment_transaction_id,
      inbound_payment_transaction_status_id: selectedTransactions[0].inbound_payment_transaction_status_id,
    }));
  }

  if (values.action_name === 'repay') {
    payload.request.url = '/api/inbound_payment_transaction_management/repay/';
    payload.request.data.retrieval_timestamp = selectedTransactions[0].retrieval_timestamp;
    payload.request.data.inbound_payments_return = selectedTransactions.map((t: Object) => ({
      inbound_payment_transaction_id: t.inbound_payment_transaction_id,
      inbound_payment_transaction_status_id: t.inbound_payment_transaction_status_id,
    }));
    payload.request.data.inbound_payment_transaction_payment_return_detail = values.inbound_payment_transaction_payment_return_detail || {};
  }
  payload.actionedTransactions = selectedTransactions.map((t: Object) => (t.outbound_payment_transaction_id));

  return {
    type: INBOUND_PAYMENT_TRANSACTIONS_ACT,
    payload,
  };
};
