import {
  TESTOUTBOUNDPAYMENT_APPROVE,
  TESTOUTBOUNDPAYMENT_CANCEL,
} from "Store/Constants/testOutboundPayment";
import {
  LOAD_ALL,
  LOAD_ONE,
  CREATE,
  LOAD_FORM_DATA,
} from "Store/Constants/generic";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

type TestOutboundPaymentState = {
  all: any[];
  selectedTestOutboundPayment: Record<string, unknown>;
  dynamicData: Record<string, unknown>;
  loadingDynamicData: boolean;
  loadingTestOutboundPaymentData: boolean;
  sendingTestOutboundPaymentData: boolean;
  message: string;
};

export const initialState: TestOutboundPaymentState = {
  all: [],
  selectedTestOutboundPayment: {},
  dynamicData: {},
  loadingDynamicData: false,
  loadingTestOutboundPaymentData: false,
  sendingTestOutboundPaymentData: false,
  message: "",
};

let actionsMap: Record<
  string,
  (state: TestOutboundPaymentState, action: Actions) => TestOutboundPaymentState
> = {};

if (
  rolesList.TESTOUTBOUNDPAYMENT &&
  rolesList.TESTOUTBOUNDPAYMENT.systemRoleName
) {
  const testOutboundPaymentActionsMap = {
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_ALL}${PENDING}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      all: [],
      loadingTestOutboundPaymentData: true,
    }),
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_ALL}${SUCCESS}`]: (
      state: TestOutboundPaymentState,
      {payload: {data}}: any
    ) => ({
      ...state,
      all: data || [],
      loadingTestOutboundPaymentData: false,
    }),
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_ALL}${FAIL}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      all: [],
      loadingTestOutboundPaymentData: false,
    }),

    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_ONE}${PENDING}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      selectedTestOutboundPayment: {},
      loadingTestOutboundPaymentData: true,
    }),
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: TestOutboundPaymentState,
      {payload: {data}}: any
    ) => ({
      ...state,
      selectedTestOutboundPayment: data || {},
      loadingTestOutboundPaymentData: false,
      message: "",
    }),
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_ONE}${FAIL}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      selectedTestOutboundPayment: {},
      loadingTestOutboundPaymentData: false,
    }),

    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${CREATE}${PENDING}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: true,
    }),
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${CREATE}${SUCCESS}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: false,
    }),
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${CREATE}${FAIL}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: false,
    }),

    // Special payment updates
    [`${TESTOUTBOUNDPAYMENT_APPROVE}${PENDING}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: true,
    }),
    [`${TESTOUTBOUNDPAYMENT_APPROVE}${SUCCESS}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: false,
    }),
    [`${TESTOUTBOUNDPAYMENT_APPROVE}${FAIL}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: false,
    }),
    [`${TESTOUTBOUNDPAYMENT_CANCEL}${PENDING}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: true,
    }),
    [`${TESTOUTBOUNDPAYMENT_CANCEL}${SUCCESS}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: false,
    }),
    [`${TESTOUTBOUNDPAYMENT_CANCEL}${FAIL}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      sendingTestOutboundPaymentData: false,
    }),
  };

  const formDataActionMap = {
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_FORM_DATA}${PENDING}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_FORM_DATA}${SUCCESS}`]: (
      state: TestOutboundPaymentState,
      {payload: {data}}: any
    ) => ({
      ...state,
      dynamicData: (data.actions || {}).POST,
      loadingDynamicData: false,
    }),
    [`${rolesList.TESTOUTBOUNDPAYMENT.systemRoleName}${LOAD_FORM_DATA}${FAIL}`]: (
      state: TestOutboundPaymentState
    ) => ({
      ...state,
      dynamicData: {},
      loadingDynamicData: false,
    }),
  };

  actionsMap = {
    ...testOutboundPaymentActionsMap,
    ...formDataActionMap,
  };
}

export default function reducer(
  state: TestOutboundPaymentState = initialState,
  action: Actions
) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
