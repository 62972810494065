import {
  LOAD_ONE,
} from "Store/Constants/generic";
import { PENDING, SUCCESS, FAIL } from "Store/Constants/axios";
import { CompanyManagementStateType } from "Types/feeBuilder";
import { rolesList } from "GlobalConfigs";
import { Actions } from "Store/Actions";

export const initialState: CompanyManagementStateType = {
  businessDetail: {},
  addresses: {},
  authorisedPeople: {},
};

let actionsMap = {};

if (rolesList.COMPANYMANAGEMENT && rolesList.COMPANYMANAGEMENT.systemRoleName) {
  const { systemRoleName } = rolesList.COMPANYMANAGEMENT;
  const companyManagementsMap = {
    [`${systemRoleName}${LOAD_ONE}${PENDING}`]: (state: CompanyManagementStateType) => ({
      ...state,
      loadingData: true,
    }),
    [`${systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: CompanyManagementStateType,
      action: Actions
    ) => {
      const { payload: { data } } = action;
      if (action?.payload?.config?.url.includes('authorised_people')) {
        return ({
          ...state,
          authorisedPeople: data || {},
          loadingData: false,
        });
      }
      if (action?.payload?.config?.url.includes('addresses')) {
        return ({
          ...state,
          addresses: data || {},
          loadingData: false,
        });
      }
      return ({
        ...state,
        businessDetail: data || {},
        loadingData: false,
      });
    },
    [`${systemRoleName}${LOAD_ONE}${FAIL}`]: (state: CompanyManagementStateType) => ({
      ...state,
      businessDetail: {},
      loadingData: false,
    }),
  };

  actionsMap = {
    ...companyManagementsMap,
  };
}

export default function reducer(
  state: CompanyManagementStateType = initialState,
  action: Actions
) {
  const fn: (state: CompanyManagementStateType, action: Actions) => CompanyManagementStateType =
    actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
