//@flow
import {
  SEARCH_DECISION,
  REFRESH_RESULTS,
  APPLY_DECISION,
} from 'Store/Constants/creditDeclineOverturn';
//$FlowFixMe
import { rolesList } from 'GlobalConfigs';


export const searchCreditDeclineOverturn = (transactionId: number) => ({
  type: `${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ SEARCH_DECISION }`,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: '/api/credit_check_status/',
      data: {
        transaction: transactionId,
      },
    },
  },
});

export const refreshCreditDeclineOverturn = (refreshUrl: string) => ({
  type: `${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ REFRESH_RESULTS }`,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: refreshUrl,
    },
  },
});

export const applyDecision = (confirmToken: number, refreshUrl: string) => ({
  type: `${ rolesList.CREDITDECLINEOVERTURN.systemRoleName }${ APPLY_DECISION }`,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'PATCH',
      url: refreshUrl,
      data: {
        confirm_token: confirmToken,
      },
    },
  },
});
