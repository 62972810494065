import { Actions } from "Store/Actions";
import { UpdateActive } from "Store/Actions/application";
import {
  clearStorage,
  getLocalJWTToken,
  getLocalUserInfo
} from "Store/Api/authentication";
import { APPLICATION_UPDATE_ACTIVE_HOLE } from "Store/Constants/application";
import {
  AUTH_ERROR, AUTH_LOGOUT,
  AUTH_PROCESS_ACCESS_TOKEN,
  AUTH_REFRESH_ACCESS_TOKEN,
  AUTH_REFRESH_ACCESS_TOKEN_FAIL,
  AUTH_REFRESH_ACCESS_TOKEN_SUCCESS,
  AUTH_RETRIEVE_ACCESS_TOKEN,
  AUTH_RETRIEVE_ACCESS_TOKEN_FAIL,
  AUTH_RETRIEVE_ACCESS_TOKEN_SUCCESS,
  AUTH_SIGNIN,
  REDIRECT_TO_LOGOUT, REGISTRATION_SAVE_DATA,
  REGISTRATION_SAVE_DATA_FAIL,
  REGISTRATION_SAVE_DATA_SUCCESS,
  REGISTRATION_SAVE_SECURITY_QUESTIONS,
  REGISTRATION_SAVE_SECURITY_QUESTIONS_FAIL,
  REGISTRATION_SAVE_SECURITY_QUESTIONS_SUCCESS
} from "Store/Constants/auth";
import { AuthStateType, Permission } from "Types/auth";
import {
  processJWTEmployee,
  processJWTEntities,
  processJWTPermissions
} from "Utils/permissions";
import appSettings from "GlobalConfigs/settings";

export const initialState: AuthStateType = {
  dynamicData: {
    securityQuestions: [],
    management: {},
  },
  loadingDynamicData: false,
  auth0: {
    profile: null,
    token: null,
    id: null,
    submitting: false,
    error: null,
    newUser: false,
  },
  accountInfo: {
    signup_required: false,
    security_questions_required: false,
    sms_required: false,
    access: "",
    refresh: "",
  },
  userInfo: {},
  profiles: [],
  permissions: [],
  allPermissions: {},
  rolesList: {},
  loadingPermissions: false,
  loadingUserData: false,
  loadingToken: false,
  checkingToken: false,
  loadingSignupData: false,
  JWT: "",
  CSRF: "",
  redirectToLogout: false,
  employeeId: "",
};

const processClientPermissionsAndRoles = (token: string, clientId: string) => {
  const processedJWTPermissions = processJWTPermissions(token) as {
    [key: string]: Permission[];
  };

  const permissionsForClient = processedJWTPermissions[clientId] ?? [];

  const rolesListForClient = permissionsForClient.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.role_id]: cur.role_id,
    }),
    {} as {[key: string]: string}
  );

  return {
    permissionsForClient,
    rolesListForClient,
  };
};

const processAccessToken = (state: AuthStateType, action: UpdateActive) => {
  const {
    payload: {token, newActive},
  } = action;
  // TODO => has this been called with conditional parameters
  if (newActive.objectType === "entity" || newActive.objectType === "host") {
    const clientId =
      newActive.objectType === "entity"
        ? newActive.id
        : newActive.legal_entity_id; // for Host organisations

    const {
      permissionsForClient,
      rolesListForClient,
    } = processClientPermissionsAndRoles(token ?? "", clientId);

    if (appSettings.REACT_APP_COMPANY_MANAGEMENT) {
      return {
        ...state,
        permissions: [
          ...permissionsForClient,
          {read: true,
            role_id: "COMPANYMANAGEMENT",
            write: true},
        ],
        rolesList: {
          ...rolesListForClient,
          COMPANYMANAGEMENT: "COMPANYMANAGEMENT",
        },
      };
    }

    return {
      ...state,
      permissions: permissionsForClient,
      rolesList: rolesListForClient,
    };
  }

  return state;
};

export default function reducer(
  state: AuthStateType = initialState,
  action: Actions
): AuthStateType {
  if (action.type === AUTH_SIGNIN) {
    const {profile, token} = action.payload;
    return {
      ...state,
      auth0: {
        ...state.auth0,
        // TODO => this field does not exist on current auth0 profile
        // id: profile.global_client_id,
        profile,
        token,
      },
    };
  }

  if (action.type === AUTH_LOGOUT) {
    clearStorage();
    return initialState;
  }

  if (action.type === AUTH_ERROR) {
    return {
      ...state,
      auth0: {
        ...state.auth0,
        error: action.payload.error,
      },
    };
  }

  if (action.type === AUTH_RETRIEVE_ACCESS_TOKEN) {
    return {
      ...state,
      redirectToLogout: false,
      loadingToken: true,
    };
  }

  if (action.type === AUTH_RETRIEVE_ACCESS_TOKEN_SUCCESS) {
    return {
      ...state,
      loadingToken: false,
      accountInfo: {
        ...initialState.accountInfo,
        ...action.payload.data,
      },
    };
  }

  if (action.type === AUTH_RETRIEVE_ACCESS_TOKEN_FAIL) {
    return {
      ...state,
      loadingToken: false,
    };
  }

  if (action.type === AUTH_REFRESH_ACCESS_TOKEN) {
    return {
      ...state,
      redirectToLogout: false,
    };
  }

  if (action.type === AUTH_REFRESH_ACCESS_TOKEN_SUCCESS) {
    return {
      ...state,
      accountInfo: {
        ...state.accountInfo,
        ...action.payload.data,
      },
    };
  }

  if (action.type === AUTH_REFRESH_ACCESS_TOKEN_FAIL) {
    return state;
  }

  if (action.type === AUTH_PROCESS_ACCESS_TOKEN) {
    const localJTWTToken = action.accountInfo?.access ?? getLocalJWTToken()
    const {entities} = processJWTEntities(localJTWTToken);
    const allPermissions = processJWTPermissions(localJTWTToken) as {
      [key: string]: Permission[];
    };
    const localUserInfo = getLocalUserInfo();

    const userInfo = {
      email_address: localUserInfo.email ?? "",
      full_name: localUserInfo.name ?? "",
      avatar: localUserInfo.picture ?? "",
    };

    if(state.accountInfo.signup_required || state.accountInfo.security_questions_required || state.loadingToken){
      return {
        ...state,
        allPermissions: allPermissions,
        permissions: entities.length ? allPermissions[entities[0].id] : [],
        employeeId: processJWTEmployee(localJTWTToken),
        userInfo,
      }
    }

    if (!entities.length) {
      return {
        ...state,
        redirectToLogout: true,
        loadingToken: false,
        userInfo,
      };
    }

    return {
      ...state,
      allPermissions: allPermissions,
      permissions: entities.length ? allPermissions[entities[0].id] : [],
      employeeId: processJWTEmployee(localJTWTToken),
      userInfo,
    };
  }

  if (action.type === APPLICATION_UPDATE_ACTIVE_HOLE) {
    return processAccessToken(state, action);
  }

  if (
    action.type === REGISTRATION_SAVE_DATA) {
    return {
      ...state,
      loadingSignupData: true,
    };
  }

  if (
    action.type === REGISTRATION_SAVE_DATA_SUCCESS) {
    return {
      ...state,
      loadingSignupData: false,
      accountInfo: action.payload.data,
    };
  }

  if (
    action.type === REGISTRATION_SAVE_DATA_FAIL) {
    return {
      ...state,
      loadingSignupData: false,
    };
  }

  if (action.type === REGISTRATION_SAVE_SECURITY_QUESTIONS) {
    return {
      ...state,
      loadingToken: true,
    };
  }

  if (action.type === REGISTRATION_SAVE_SECURITY_QUESTIONS_SUCCESS) {
    return {
      ...state,
      loadingToken: false,

      accountInfo: action.payload.data,
    };
  }

  if (action.type === REGISTRATION_SAVE_SECURITY_QUESTIONS_FAIL) {
    return {
      ...state,
      loadingToken: false,
    };
  }

  if (action.type === REDIRECT_TO_LOGOUT) {
    return {
      ...state,
      redirectToLogout: true,
    };
  }

  return state;
}
