//@flow
import {
  API_KEY_MANAGEMENT_LOAD_ACTIVE_KEYS,
  API_KEY_MANAGEMENT_LOAD_REVOKED_KEYS,
  API_KEY_MANAGEMENT_CREATE_KEY,
  API_KEY_MANAGEMENT_REVOKE_KEY,
  API_KEY_MANAGEMENT_PURGE_KEY,
} from 'Store/Constants/apiKeyManagement';

export const loadActiveKeys = () => ({
  type: API_KEY_MANAGEMENT_LOAD_ACTIVE_KEYS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: '/api/api_key_management/key/?active=true',
      params: {},
    },
  },
});

export const loadRevokedKeys = () => ({
  type: API_KEY_MANAGEMENT_LOAD_REVOKED_KEYS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: '/api/api_key_management/key/?active=false',
      params: {},
    },
  },
});

export const createKey = (creationReason: string, publicKey: string, idempotencyKey: string) => ({
  type: API_KEY_MANAGEMENT_CREATE_KEY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: '/api/api_key_management/key/',
      params: { idempotencykey: idempotencyKey },
      data: {
        creation_reason: creationReason,
        public_key: publicKey,
      },
    },
  },
});

export const revokeKey = (keyId: number, revocationReason: string) => ({
  type: API_KEY_MANAGEMENT_REVOKE_KEY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'PATCH',
      url: `/api/api_key_management/key/${ keyId }`,
      data: { revocation_reason: revocationReason },
    },
  },
});

export const purgeKey = () => ({
  type: API_KEY_MANAGEMENT_PURGE_KEY,
});
