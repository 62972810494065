import {AUTH_REFRESH_ACCESS_TOKEN_FAIL} from "Store/Constants/auth";
import {redirectToLogout} from "Store/Actions/auth";
import {Middleware} from "redux";
import {RootReducer} from "Store/Reducers";
import {Actions} from "Store/Actions";

const unauthorisedMiddleware: Middleware<Record<string, never>, RootReducer> = (
  store
) => (next) => (action: Actions) => {
  switch (action.type) {
  case AUTH_REFRESH_ACCESS_TOKEN_FAIL:
    if (
      action.error &&
        action.error.response &&
        action.error.response.status === 401
    ) {
      store.dispatch(redirectToLogout());
    }
    return next(action);

  default:
    return next(action);
  }
};

export default unauthorisedMiddleware;
