import {Dialog} from "@chetwoodfinancial/staff-app-visual-components";
import React, {ErrorInfo, ReactNode} from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: Error): State {
    console.error(error);
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='login-container'>
          <Dialog
            isVisible
            isFail
            hasURL
            customStyle={{alignItems: "center",
              textAlign: "center"}}
          >
            <br />
            <h3 className='error'>Something seems to have gone wrong.</h3>
            <p className='error text-bold'>
              Please refresh the page to try again
            </p>
            <p className='error text-bold'>
              If the issue persists, please contact the administrator.
            </p>
            <span
              className='btn btn-success'
              style={{marginTop: 20}}
              onClick={ () => {
                window.location.reload();
              } }
            >
              Refresh page
            </span>
            <br />
          </Dialog>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
