//@flow
import {
  LOAN_MANAGEMENT_SEARCH_LOAN,
  LOAN_MANAGEMENT_LOAD_LOAN_SUMMARY,
  LOAN_MANAGEMENT_LOAD_PENDING_APPROVAL,
  LOAN_MANAGEMENT_ENQUIRE,
  LOAN_MANAGEMENT_ACT,
  LOAN_MANAGEMENT_DRY_RUN,
  LOAN_MANAGEMENT_DRY_RUN_PLATFORM_API,
  LOAN_MANAGEMENT_LOAD_LOAN_CASHFLOWS,
  LOAN_MANAGEMENT_LOAD_LOAN_FLAGS,
  LOAN_MANAGEMENT_LOAD_LOAN_EVENTS,
  LOAN_MANAGEMENT_LOAD_LOAN_VERSIONS,
  LOAN_MANAGEMENT_LOAD_LOAN_MANDATES,
  LOAN_MANAGEMENT_LOAD_LOAN_LOGS,
  LOAN_MANAGEMENT_LOAD_LOAN_GL_DATA,
  LOAN_MANAGEMENT_LOAD_LOAN_LEGAL_DOCUMENTS,
  LOAN_MANAGEMENT_LOAD_STATEMENTS,
  LOAN_MANAGEMENT_URL,
  LOAN_MANAGEMENT_LOAD_WP_KEY,
  LOAN_MANAGEMENT_CARD_PAYMENTS,
  LOAN_MANAGEMENT_CARD_PAYMENTS_REFRESH_RESULTS,
  LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE,
  LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS,
  LOAN_MANAGEMENT_GET_REFINANCE_DYNAMIC_DATA,
  LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE,
} from 'Store/Constants/loanManagement';

export const searchLoan = (loanId: number | string = '', loanRef: string = '') => {
  let searchPayload = {};
  if (loanRef) {
    searchPayload = {
      loan_reference: loanRef,
    };
  }
  if (loanId) {
    searchPayload = {
      loan_id: loanId,
    };
  }

  return {
    type: LOAN_MANAGEMENT_SEARCH_LOAN,
    payload: {
      client: 'authenticatedClient',
      request: {
        method: 'POST',
        url: `api${ LOAN_MANAGEMENT_URL }search/`,
        data: searchPayload,
      },
    },
  };
};

/**
 * loadSelectedLoanSummary - Search for a loan based on ID or customer reference
 *
 * @param {string | number} loanId  Unique database ID for a loan
 *
 * @return {action}                 Action creator which will resolve to a loan ID or an error message if the loan does not exist
 */
export const loadSelectedLoanSummary = (loanId: number | string) => {
  return {
    type: LOAN_MANAGEMENT_LOAD_LOAN_SUMMARY,
    payload: {
      client: 'authenticatedClient',
      request: {
        method: 'GET',
        url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/summary/`,
      },
    },
  };
};

/**
 * loadSelectedLoanGlData - Load GL data associated with a loan ID
 *
 * @param {string | number} loanId      Unique database ID for a loan
 *
 * @return {action}                     Action creator which will resolve to an object with loan GL calculated values
 */
export const loadSelectedLoanGlData = (loanId: number) => ({
  type: LOAN_MANAGEMENT_LOAD_LOAN_GL_DATA,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/gl_summary/`,
      params: {},
    },
  },
});

/**
 * loadSelectedLoanDocuments - Load documents data associated with a loan ID
 *
 * @param {string | number} loanId      Unique database ID for a loan
 *
 * @return {action}                     Action creator which will resolve to an object with loan account documents list
 */
export const loadSelectedLoanDocuments = (loanId: number) => ({
  type: LOAN_MANAGEMENT_LOAD_LOAN_LEGAL_DOCUMENTS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/documents/`,
      params: {},
    },
  },
});

/**
 * loadSelectedLoanCashflows - Load cashflows associated with a loan
 *
 * @param {string | number} loanId    Unique database ID for a loan
 *
 * @return {action}                   Action creator which will resolve to an object with loan cashflows
 */
export const loadSelectedLoanCashflows = (loanId: number) => ({
  type: LOAN_MANAGEMENT_LOAD_LOAN_CASHFLOWS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/filtered_cashflows/`,
      params: {},
    },
  },
});

/**
 * loadSelectedLoanFlags - Load flags associated with a loan
 *
 * @param {string | number} loanId    Unique database ID for a loan
 *
 * @return {action}                   Action creator which will resolve to an object with loan flags
 */
export const loadSelectedLoanFlags = (loanId: number) => ({
  type: LOAN_MANAGEMENT_LOAD_LOAN_FLAGS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/loan_flags/`,
      params: {},
    },
  },
});

/**
 * loadSelectedLoanEvents - Load events associated with a loan
 *
 * @param {string | number} loanId    Unique database ID for a loan
 *
 * @return {action}                   Action creator which will resolve to an object with loan events
 */
export const loadSelectedLoanEvents = (loanId: number) => ({
  type: LOAN_MANAGEMENT_LOAD_LOAN_EVENTS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/loan_events/`,
      params: {},
    },
  },
});

/**
 * loadSelectedLoanVersions - Load versions associated with a loan
 *
 * @param {string | number} loanId    Unique database ID for a loan
 *
 * @return {action}                   Action creator which will resolve to an object with loan versions
 */
export const loadSelectedLoanVersions = (loanId: number) => ({
  type: LOAN_MANAGEMENT_LOAD_LOAN_VERSIONS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/loan_versions/`,
      params: {},
    },
  },
});

/**
 * loadSelectedLoanMandates - Load direct debit mandates associated with a loan and version
 *
 * @param {string | number} loanId      Unique database ID for a loan
 * @param {string | number} versionId   ID of the version (defaults to current one)
 *
 * @return {action}                     Action creator which will resolve to an object with loan mandates
 */
export const loadSelectedLoanMandates = (loanId: number, versionId: number) => ({
  type: LOAN_MANAGEMENT_LOAD_LOAN_MANDATES,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/loan_mandates/`,
      params: {
        loan_version_id: versionId,
      },
    },
  },
});

/**
 * updateLoanCollectionMandate - update direct debit mandate associated with a mandate ID
 *
 * @param {string | number} loanId              Unique database ID for a loan
 * @param {string | number} mandateID           ID of the direct debit mandate
 * @param {boolean} suspend                     A boolean to state if the mandate should be suspended (true) or reactivated (false)
 * @param {string | number} collectionOrgID     ID of the collection organisation
 * @param {string | number} retrievalTimestamp  Timestamp of the retrieval
 * @param {string | number} hostOrgID           ID of the host organisation
 * @param {number} currentLiveVersion  Version number of the loan //might not be the latest version of the mandate
 *
 * @return {action}                     Action creator which will resolve to an object with loan mandates
 */
export const updateLoanMandate = (
  loanId: number, //
  mandateID: string,
  suspend: boolean,
  collectionOrgID: number,
  retrievalTimestamp: string,
  hostOrgID: number,
  currentLiveVersion: number
) => ({
  type: LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/update_loan_collection_mandate/`,
      data: {
        _api_data: {
          collection_organisation_id: collectionOrgID,
          retrieval_timestamp: retrievalTimestamp,
          loan_collection_mandate_suspended: suspend,
          loan_collection_mandate_id: mandateID,
        },
        host_organisation_id: hostOrgID,
      },
    },
    currentLiveVersion,
    loanId,
  },
});

/**
 * loadSelectedLoanLogs - Load loan logs associated with a loan and version
 *
 * @param {string | number} loanId      Unique database ID for a loan
 * @param {string | number} versionId   ID of the version
 *
 * @return {action}                     Action creator which will resolve to an object with loan logs
 */
export const loadSelectedLoanLogs = (loanId: number) => ({
  type: LOAN_MANAGEMENT_LOAD_LOAN_LOGS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }${ loanId }/loan_log_records/`,
      params: {},
    },
  },
});

/**
 * loadPendingApprovals - Load all pending events for a host org
 *
 * @return {action}   Action creator which will resolve to an object with pending loan events in the system
 */
export const loadPendingApprovals = () => ({
  type: LOAN_MANAGEMENT_LOAD_PENDING_APPROVAL,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `api${ LOAN_MANAGEMENT_URL }pending_review_events/`,
    },
  },
});

/**
 * enquireEvent - Retreive data about a particular event (this includes the required form fields)
 *
 * @param {string | number} loanId    Unique database ID for a loan
 * @param {string} eventCode          Code for the event. Thsi is also used to generate the backend route for the event
 *
 * @return {action}                   Action creator which will resolve to an object with event details
 */
export const enquireEvent = (loanId: number | string, eventCode: any) => ({
  type: LOAN_MANAGEMENT_ENQUIRE,
  payload: {
    client: 'authenticatedClient',
    eventCode,
    request: {
      method: 'GET',
      url: `/api${ LOAN_MANAGEMENT_URL }${ loanId }/${ eventCode }/`,
    },
  },
});

/**
 * submitLoanManagementEvent - Submit event form
 *
 * @param {string | number} loanId    Unique database ID for a loan
 * @param {string} submitUrl          Code for the event. Thsi is also used to generate the backend route for the event
 * @param {Object} values             Form values subvmitted by the user
 *
 * @return {action}                   Action creator which will resolve to the new value of the loan
 */
export const submitLoanManagementEvent = (
  loanId: number | string,
  submitUrl: any,
  values: any,
  selectedEventCode: string,
  requireFullRefresh: boolean = true,
  redirectTo: string = '',
  requireFormRefresh: boolean = true
) => ({
  type: LOAN_MANAGEMENT_ACT,
  payload: {
    eventCode: selectedEventCode,
    requireFullRefresh,
    requireFormRefresh,
    loanId,
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: submitUrl,
      data: {
        _api_data: {
          ...values,
          formsets: {
            ...(values || {}).formsets,
          },
        },
      },
    },
    redirectTo,
  },
});

/**
 * submitLoanManagementDryRun - Submit a dry run for the event
 *
 * @param {string | number} loanId    Unique database ID for a loan
 * @param {string} submitUrl          Code for the event. This is also used to generate the backend route for the event
 * @param {Object} values             Form values subvmitted by the user
 *
 * @return {action}                   Action creator which will resolve to the proposed changes for the loan
 */
export const submitLoanManagementDryRun = (loanId: number | string, submitUrl: any, values: any, selectedEventCode: string = '') => ({
  type: LOAN_MANAGEMENT_DRY_RUN,
  payload: {
    client: 'authenticatedClient',
    eventCode: selectedEventCode,
    request: {
      method: 'POST',
      url: submitUrl,
      data: {
        action: 'start_event',
        _api_data: {
          ...values,
          formsets: {
            ...(values || {}).formsets,
          },
        },
      },
    },
  },
});

// Platform API submit functions
export const submitLoanManagementEventPlatformAPI = (
  loanId: number | string,
  hostOrganisationID: string | number,
  eventCode: string,
  action: string,
  apiPayload: Object,
  submitUrl: any,
  // Optionals
  requireFullRefresh: boolean = true,
  redirectTo: string = '',
  requireFormRefresh: boolean = true
) => ({
  type: LOAN_MANAGEMENT_ACT,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: submitUrl,
      data: {
        _api_data: {
          ...apiPayload,
        },
        action,
        host_organisation_id: hostOrganisationID,
      },
    },
    eventCode,
    requireFullRefresh,
    requireFormRefresh,
    loanId,
    redirectTo,
  },
});

export const submitLoanManagementDryRunPlatformAPI = (
  loanId: number | string,
  hostOrganisationID: string | number,
  eventCode: string,
  action: string,
  apiPayload: Object,
  submitUrl: any
) => ({
  type: LOAN_MANAGEMENT_DRY_RUN_PLATFORM_API,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: submitUrl,
      data: {
        _api_data: {
          ...apiPayload,
        },
        action,
        host_organisation_id: hostOrganisationID,
      },
    },
    eventCode,
    loanId,
  },
});

export const loadStatementsFromURL = (fileURL: string) => ({
  type: LOAN_MANAGEMENT_LOAD_STATEMENTS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: fileURL,
      responseType: 'blob',
    },
  },
});

/**
 * submitLoanManagementWPDetails - load client key required for payment
 *
 * @param {string | number} host_organisation_id    Unique ID for a host org
 *
 * @return {action}                   Action creator which will resolve to the proposed changes for the loan
 */
export const retreiveLoanManagementWPDetails = (hostOrganisationID: number | string) => ({
  type: LOAN_MANAGEMENT_LOAD_WP_KEY,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `/api/host_organisation/${ hostOrganisationID }/public_keys/`,
      params: {
        host_organisation_id: hostOrganisationID,
      },
    },
  },
});

/**
 * submitLoanManagementCardPayment - Submit a card payment payload
 *
 * @param {string | number} loanId    Unique database ID for a loan
 * @param {string} personId           Unique database ID for the person owning the loan
 * @param {Object} values             Form values subvmitted by the user (includes the worldpay tokens payload)
 * @param {string} redirectTo         Page to navigate on success
 *
 * @return {action}                   Action creator which will resolve to the proposed changes for the loan
 */
export const submitLoanManagementCardPayment = (loanId: number | string, personId: number | string, values: any, redirectTo: string) => ({
  type: LOAN_MANAGEMENT_CARD_PAYMENTS,
  payload: {
    redirectTo,
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: '/api/cardpayment/',
      data: {
        person_id: personId,
        loan_id: loanId,
        ...values,
      },
    },
  },
});

/**
 * refreshLoanManagementCardPayment - Poll for payment status
 * TODO: Rmove polling in future implementation
 *
 * @param {string | number} refreshUrl  Backend URL required fro polling
 * @param {string} taskStatus           Status of the polled ticket
 * @param {string} state                Ticket authorisation token
 * @param {string} redirectTo           Page to navigate on success
 *
 * @return {action}                   Action creator which will resolve to a new request
 * Due to reasons unkown frontend needs to do an extra request to confirm transaction? This should be removed at some point (POST method below)
 */
const FINALISE_PAYMENT_TICKET_STATUSES = ['SUCCESS', 'FAILURE', 'ERROR'];
export const refreshLoanManagementCardPayment = (refreshUrl: string, taskStatus: string, state: string, redirectTo: string) => {
  if (!FINALISE_PAYMENT_TICKET_STATUSES.includes(taskStatus)) {
    return {
      type: LOAN_MANAGEMENT_CARD_PAYMENTS_REFRESH_RESULTS,
      payload: {
        redirectTo,
        client: 'authenticatedClient',
        request: {
          method: 'POST',
          url: refreshUrl,
          data: {
            state,
            task_status: taskStatus,
          },
        },
      },
    };
  }

  return {
    type: LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE,
    payload: {
      redirectTo,
      client: 'authenticatedClient',
      request: {
        method: 'PATCH',
        url: refreshUrl.substr(0, refreshUrl.indexOf('check/')),
        data: {
          state,
          task_status: taskStatus,
        },
      },
    },
  };
};

/**
 * retreiveLoanManagementRefinanceDynamicData - load client key required for payment
 *
 * @param {string | number} host_organisation_id    Unique ID for a host org
 *
 * @return {action}                   Action creator which will resolve to the proposed changes for the loan
 */
export const retreiveLoanManagementRefinanceDynamicData = (loanId: number) => ({
  type: LOAN_MANAGEMENT_GET_REFINANCE_DYNAMIC_DATA,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: `/api/loan_management/${ loanId }/refinance/quote/`,
    },
  },
});
/**
 * retreiveLoanManagementRefinanceOptions - load client key required for payment
 *
 * @param {string | number} host_organisation_id    Unique ID for a host org
 *
 * @return {action}                   Action creator which will resolve to the proposed changes for the loan
 */
export const retreiveLoanManagementRefinanceOptions = (loanId: number, data: Object, hostOrgId: number = 1) => ({
  type: LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: `/api/loan_management/${ loanId }/refinance/quote/?host_organisation_id=${ hostOrgId }`,
      data: {
        ...data,
      },
    },
  },
});
