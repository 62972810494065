import { getLocalRefreshToken } from "Store/Api/authentication";
import { BASE_URL } from "Store/Constants/application";
import {
  AUTH_ERROR, AUTH_LOGOUT,
  AUTH_PROCESS_ACCESS_TOKEN,
  AUTH_REFRESH_ACCESS_TOKEN,
  AUTH_REFRESH_ACCESS_TOKEN_FAIL,
  AUTH_REFRESH_ACCESS_TOKEN_SUCCESS,
  AUTH_RETRIEVE_ACCESS_TOKEN,
  AUTH_RETRIEVE_ACCESS_TOKEN_FAIL,
  AUTH_RETRIEVE_ACCESS_TOKEN_SUCCESS,
  AUTH_SIGNIN,
  // Logout
  REDIRECT_TO_LOGOUT,
  // URLs (TODO: keep them here for now, will expand once api secrets are revealed)
  REGISTRATION_DATA_URL, REGISTRATION_SAVE_DATA,
  REGISTRATION_SAVE_DATA_FAIL,
  REGISTRATION_SAVE_DATA_SUCCESS,
  REGISTRATION_SAVE_SECURITY_QUESTIONS,
  REGISTRATION_SAVE_SECURITY_QUESTIONS_FAIL,
  REGISTRATION_SAVE_SECURITY_QUESTIONS_SUCCESS, SECURITY_QUESTIONS_URL
} from "Store/Constants/auth";
import { ApiError } from "Types/api";
import {
  AccountInfoType,
  Auth0ErrorType, LocalAuthProfile,
  LocalAuthTokens,
  RegistrationFormData,
  SecurityQuestionsTypes
} from "Types/auth";

// AUTH0 actions
export const auth0SignIn = (
  profile: LocalAuthProfile,
  token: LocalAuthTokens
) => ({
  type: AUTH_SIGNIN as typeof AUTH_SIGNIN,
  payload: {
    profile,
    token,
    newUser: false,
  },
});
type Auth0SignIn = ReturnType<typeof auth0SignIn>;

export const auth0Error = (error: Auth0ErrorType) => ({
  type: AUTH_ERROR as typeof AUTH_ERROR,
  payload: {
    error,
  },
});
type Auth0Error = ReturnType<typeof auth0Error>;

// Token management
export const retrieveToken = (accessToken: string) => ({
  type: AUTH_RETRIEVE_ACCESS_TOKEN as typeof AUTH_RETRIEVE_ACCESS_TOKEN,
  payload: {
    client: "loginClient",
    request: {
      method: "POST",
      url: `${BASE_URL}/api/login/`,
      data: {
        access_token: accessToken,
      },
    },
  },
});
type RetrieveToken = ReturnType<typeof retrieveToken>;

type RetrieveTokenSuccess = {
  type: typeof AUTH_RETRIEVE_ACCESS_TOKEN_SUCCESS;
  payload: {
    data: AccountInfoType;
  };
};

type RetrievetokenFail = {
  type: typeof AUTH_RETRIEVE_ACCESS_TOKEN_FAIL;
  error: ApiError;
};

export const refreshAccessToken = () => {
  const refreshToken = getLocalRefreshToken();

  return {
    type: AUTH_REFRESH_ACCESS_TOKEN as typeof AUTH_REFRESH_ACCESS_TOKEN,
    payload: {
      client: "loginClient",
      request: {
        method: "POST",
        url: "/api/refresh_token/",
        data: {
          refresh: refreshToken,
        },
      },
    },
  };
};
type RefreshAccessToken = ReturnType<typeof refreshAccessToken>;
type RefreshAccessTokenSuccess = {
  type: typeof AUTH_REFRESH_ACCESS_TOKEN_SUCCESS;
  payload: {
    data: AccountInfoType;
  };
};

type RefreshAccessTokenFail = {
  type: typeof AUTH_REFRESH_ACCESS_TOKEN_FAIL;
  error: ApiError;
};

export const processTokenData = (accountInfo?: AccountInfoType) => ({
  type: AUTH_PROCESS_ACCESS_TOKEN as typeof AUTH_PROCESS_ACCESS_TOKEN,
  accountInfo
});
type ProcessTokenData = ReturnType<typeof processTokenData>;

// Registration/ first time login
export const saveRegistrationData = (values: RegistrationFormData) => ({
  type: REGISTRATION_SAVE_DATA as typeof REGISTRATION_SAVE_DATA,
  payload: {
    client: "registrationClient",
    request: {
      method: "POST",
      url: `${REGISTRATION_DATA_URL}`,
      data: {
        ...values,
      },
    },
  },
});
type SaveRegistrationData = ReturnType<typeof saveRegistrationData>;

type RegistrationSaveDataSuccess = {
  type: typeof REGISTRATION_SAVE_DATA_SUCCESS;
  payload: {
    data: AccountInfoType;
  };
};

type RegistrationSaveDataFail = {
  type: typeof REGISTRATION_SAVE_DATA_FAIL;
  error: ApiError;
};

export const saveSecurityQuestions = (values: SecurityQuestionsTypes) => ({
  type: REGISTRATION_SAVE_SECURITY_QUESTIONS as typeof REGISTRATION_SAVE_SECURITY_QUESTIONS,
  payload: {
    client: "registrationClient",
    request: {
      method: "POST",
      url: `${SECURITY_QUESTIONS_URL}`,
      data: {
        ...values,
      },
    },
  },
});
type SaveSecurityQuestions = ReturnType<typeof saveSecurityQuestions>;
type SaveSecurityQuestionsSuccess = {
  type: typeof REGISTRATION_SAVE_SECURITY_QUESTIONS_SUCCESS;
  payload: {
    data: AccountInfoType;
  };
};
type SaveSecurityQuestionsFail = {
  type: typeof REGISTRATION_SAVE_SECURITY_QUESTIONS_FAIL;
  payload: {
    data: AccountInfoType;
  };
};

export const logout = () => ({
  type: AUTH_LOGOUT as typeof AUTH_LOGOUT,
});
type Logout = ReturnType<typeof logout>;

export const redirectToLogout = () => ({
  type: REDIRECT_TO_LOGOUT as typeof REDIRECT_TO_LOGOUT,
});
type RedirectToLogout = ReturnType<typeof redirectToLogout>;

export type AuthActions =
  | Auth0SignIn
  | Auth0Error
  | RetrieveToken
  | RetrieveTokenSuccess
  | RetrievetokenFail
  | RefreshAccessToken
  | RefreshAccessTokenSuccess
  | RefreshAccessTokenFail
  | ProcessTokenData
  | SaveRegistrationData
  | RegistrationSaveDataSuccess
  | RegistrationSaveDataFail
  | SaveSecurityQuestions
  | SaveSecurityQuestionsSuccess
  | SaveSecurityQuestionsFail
  | Logout
  | RedirectToLogout;
