import React from 'react'
import { Dialog } from '@chetwoodfinancial/staff-app-visual-components';


const PermissionDenied = () => {
  return (
    <div className='login-container'>
      <Dialog isVisible
        isFail
        hasURL
        customStyle={{ alignItems: 'center',
          textAlign: 'center' }}
        redirectURL='/logout'
        continueText='Back to login'>
        <br />
        <h3 className='error'>You do not have permissions to access the Staff App</h3>
        <p className='error'>If the issue persists, please contact the administrator.</p>
        <p className='error'>Please log in again to continue.</p>
        <br />
      </Dialog>
    </div>
  )
}

export default PermissionDenied
