//@flow
/* eslint-disable prefer-destructuring */
import {
  SEARCH_DECISION,
  REFRESH_RESULTS,
} from 'Store/Constants/creditDeclineOverturn';
//$FlowFixMe
import { rolesList } from 'GlobalConfigs';
import { SUCCESS } from 'Store/Constants/axios';
import { refreshCreditDeclineOverturn } from 'Store/Actions/creditDeclineOverturn';

const POLLING_STATUSES = ['PROCESSING', 'PENDING'];

type Action = {
  type: string,
  payload: {
    data: {
      refresh_url: string,
      task_status: string,
    },
  },
};

export default (store: Object) => (next: Function) => (action: Action) => {
  switch (action.type) {
    case `${ (rolesList.CREDITDECLINEOVERTURN || {}).systemRoleName }${ SEARCH_DECISION }${ SUCCESS }`:
    case `${ (rolesList.CREDITDECLINEOVERTURN || {}).systemRoleName }${ REFRESH_RESULTS }${ SUCCESS }`:
      if (action.payload && action.payload.data && POLLING_STATUSES.includes(action.payload.data.task_status)) {
        setTimeout(
          () => {
            store.dispatch(refreshCreditDeclineOverturn(action.payload.data.refresh_url));
          },
          1000
        );
      }
      return next(action);

    default:
      return next(action);
  }
};
