//@flow
/* eslint-disable prefer-destructuring */
import {
  ADDRESS_MATCH,
  POLL_ADDRESS_MATCH,
  IDENTITY_VERIFICATION,
  POLL_IDENTITY_VERIFICATION,
} from 'Store/Constants/personManagement';
import { SUCCESS } from 'Store/Constants/axios';
import {
  pollAddressMatch,
  pollIdentityVerification,
} from 'Store/Actions/personManagement';

type Action = {
  type: string,
  payload: {
    data: {
      refresh_url: string,
      task_status: string,
      state: string,
      matched_addresses: string,
    },
  },
  meta: {
    previousAction: {
      payload: {
        redirectTo: string,
      },
    },
  },
};

export default (store: Object) => (next: Function) => (action: Action) => {
  switch (action.type) {
    case ADDRESS_MATCH + SUCCESS:
    case POLL_ADDRESS_MATCH + SUCCESS:
      if (action.payload && action.payload.data && action.payload.data.matched_addresses.length !== 0) {
        return next(action);
      }
      setTimeout(() => {
        store.dispatch(pollAddressMatch(action.payload.data.refresh_url));
      }, 2000);
      return next(action);
    case IDENTITY_VERIFICATION + SUCCESS:
    case POLL_IDENTITY_VERIFICATION + SUCCESS:
      if (action.payload && action.payload.data && (action.payload.data.task_status === 'SUCCESS' || action.payload.data.task_status === 'FAILURE')) {
        return next(action);
      }
      setTimeout(() => {
        store.dispatch(pollIdentityVerification(action.payload.data.refresh_url));
      }, 2000);
      return next(action);
    default:
      return next(action);
  }
};
