import {
  Dialog,
  LoadingSpinner,
  NavigationDrawer,
} from "@chetwoodfinancial/staff-app-visual-components";
import React, {
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactGA from "react-ga";
import IdleTimer from "react-idle-timer";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {Route, Switch} from "react-router-dom";
import Logout from "Screens/Logout";
import {updateActive} from "Store/Actions/application";
import {refreshAccessToken} from "Store/Actions/auth";
import {shouldRefreshAccessToken} from "Store/Api/authentication";
import {useAppSelector} from "Store/configureStore";
import {JWT_EXPIRY_MINUTES} from "Store/Constants/application";
import {ActiveClient} from "Types/application";
import {generateMenuItemsOnPermissions, menuBuilder} from "Utils/menuBuilder";
import PageTour from "Views/PageTour";
import {env} from "../../env";
import "./Dashboard.scss";
import ErrorBoundary from "./ErrorBoundary";
import PermissionDenied from "./PermissionDenied";
import RenderedRoutes from "./RenderedRoutes";

const trl = env.REACT_APP_TROLL || false;
const INACTIVITY_TIMER = JWT_EXPIRY_MINUTES - 5; // minutes

const Home = lazy(() => import("Views/Home"));

const MainAppScreen = () => {
  const [showingIdleDialog, setShowingIdleDialog] = useState(false);
  const idleTimer = useRef(null);
  const {
    entities,
    hostOrganisations,
    active,
    loadingHoLes,
    defaultEntity,
  } = useAppSelector((state) => state.application);
  const {permissions, userInfo, redirectToLogout, employeeId} = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname === "/") {
      history.push(`/entity/${defaultEntity}`);
    }

    ReactGA.ga(
      "send",
      "pageview",
      `/${active.objectType}/${active.shortCode}/`
    );
  }, []);

  const updateActiveHandler = (clients: ActiveClient[]) => {
    const client = clients.find((e) =>
      history.location.pathname.includes(e.shortCode)
    );
    if (client) dispatch(updateActive(client));
  };

  useEffect(() => {
    if (history.location.pathname.includes("entity")) {
      updateActiveHandler(entities);
    }

    if (history.location.pathname.includes("host")) {
      updateActiveHandler(hostOrganisations);
    }
  }, [history.location.pathname]);

  if (redirectToLogout || !permissions || !permissions.length || !active) {
    return <PermissionDenied />;
  }

  if (loadingHoLes) {
    return (
      <div className='registration-steps-container'>
        <LoadingSpinner />
      </div>
    );
  }

  const onAction = () => {
    if (shouldRefreshAccessToken()) dispatch(refreshAccessToken());
  };

  const MainMenu = useMemo(
    () =>
      menuBuilder(
        entities,
        hostOrganisations,
        active,
        generateMenuItemsOnPermissions(
          permissions,
          history.location.pathname,
          active.objectType
        ),
        (value) => dispatch(updateActive(value))
      ),
    [
      entities,
      hostOrganisations,
      active,
      permissions,
      history.location.pathname,
    ]
  );

  const routesRootPath = useMemo(
    () => `/${active.objectType}/${active.shortCode}`,
    [active]
  );

  return (
    <ErrorBoundary>
      <div className={ `App${trl ? " trl" : ""}` }>
        <IdleTimer
          ref={ (ref: any) => {
            idleTimer.current = ref;
          } }
          element={ document }
          onActive={ () => {} }
          onIdle={ () => setShowingIdleDialog(!showingIdleDialog) }
          onAction={ onAction }
          debounce={ 500 }
          timeout={ INACTIVITY_TIMER * 60 * 1000 }
        />

        <Dialog
          isControlled
          isVisible={ showingIdleDialog }
          title='Hello?'
          content='If you do not perform any actions you will be logged out'
          handleContinue={ () => {
            dispatch(refreshAccessToken());
            setShowingIdleDialog(!showingIdleDialog);
          } }
          continueText='Refresh session'
        />
        <NavigationDrawer
          user={{...userInfo,
            employee_id: employeeId}}
          menuItems={ MainMenu }
          incompleteTasks={ [] }
          notifications={ [] }
        >
          <Suspense fallback={ <div /> }>
            <Switch>
              <Route exact
                component={ Logout }
                path='/logout' />
              <Route path={ routesRootPath }
                exact
                component={ Home } />
              {RenderedRoutes(routesRootPath)}
            </Switch>
          </Suspense>
        </NavigationDrawer>
        <PageTour />
      </div>
    </ErrorBoundary>
  );
};

export default MainAppScreen;
