//@flow
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Tour from 'reactour';

import { toggleTour } from 'Store/Actions/application';

const mapDispatchToProps = (dispatch: Function) => ({
  actions: bindActionCreators({ toggleTour }, dispatch),
});

const mapStateToProps = (state: any) => ({
  tourSteps: state.application.tourSteps,
});

type StepType = {
  selector: string,
  content: string | any,
};

type Props = {
  tourSteps: Array<StepType>,
  actions: Object,
};

type State = {
  tourActive: boolean,
  cachedSteps: Array<StepType>,
};

class PageTour extends PureComponent<Props, State> {
  static defaultProps = {
    tourSteps: [],
  }

  state = {
    tourActive: false,
    cachedSteps: [], // eslint-disable-line
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.tourSteps !== state.cachedSteps) {
      return ({
        cachedSteps: props.tourSteps,
        tourActive: false,
      });
    }

    // Return null to indicate no change to state.
    return null;
  }

  handleToggleTour = () => {
    this.setState((prevState: State) => ({ tourActive: !prevState.tourActive }));
  }

  render() {
    const { tourActive } = this.state;
    const { actions, tourSteps } = this.props;

    const runningSteps = tourSteps.filter((step: StepType) => step.selector !== 'config');

    if (!runningSteps || !runningSteps.length) { return null; }

    return (
      <div>
        <span
          onClick={ this.handleToggleTour }
          style={{
            position: 'fixed',
            right: 30,
            bottom: 30,
            cursor: 'pointer',
          }}
          data-tour='global-page-tutorial'
        ><i className='material-icons blue'>help_outline</i></span>

        <Tour
          steps={ runningSteps || [] }
          isOpen={ tourActive }
          onRequestClose={ () => this.handleToggleTour() }
          maskSpace={ 5 }
          disableInteraction
          rounded={ 5 }
          accentColor='#01d263'
          showNavigation
          startAt={ 0 }
          actions={ actions }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTour);
