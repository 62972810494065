import React from "react";
import { Slide, ToastContainer } from "react-toastify";
import NotificationCenter from "Views/NotificationCenter";
import Dashboard from '../Dashboard';

const App = () => {

  return (
    <>
      <ToastContainer
        autoClose={ 10000 }
        hideProgressBar
        transition={ Slide }
        draggable={ false }
      />
      <Dashboard />
      <NotificationCenter />
    </>
  );
};

export default App;
