import appSettings from "GlobalConfigs/settings";

const REACT_APP_LENDING_DISABLED_HO = (
  appSettings.REACT_APP_LENDING_DISABLED_HO || ""
).split(",");

/**
 * Global roles configuration - This object contains all of the required information for routing and axios requests
 *
 * Standard config object structure
 'ROLE_NAME': {
   title:                 'How it shows in the menu (eg Employees)',
   url:                   'Frontend URL (eg /employees)',
   shortCode:             'Shortcode for role (eg employees)',
   icon:                  'Menu icon for the role - picked from material ui icon set (eg supervisor_account)',
   order:                 'Menu order (eg 1)',
   type:                  'Access level for role (eg entity/host)',
   disabled:              'Is the role disabled or not (eg false)'',
   systemRoleName:        'Usually same as ROLE_NAME (eg EMPLOYEEMANAGEMENT)',
   roleURL:               'Base backend URL for this role ( eg /api/employee/)',
   client:                'Default axios client to be used for this role (eg authenticatedClient)',
   disabledForHost:       'Array of host orgs for which this role is disabled (eg: [1, 2])',
   moduleFolder:          'Path to the view to be loaded for this role',
   disabledFunctionality: 'Array with disabled features for client'
  },
 */

const rolesList = {
  EMPLOYEEMANAGEMENT: {
    title: "Employees",
    url: "/employees",
    shortCode: "employees",
    icon: "supervisor_account",
    order: 1,
    type: "entity",
    disabled: false,
    systemRoleName: "EMPLOYEEMANAGEMENT",
    roleURL: "/api/employee/",
    client: "authenticatedClient",
    moduleFolder: "Employees",
  },
  LEBANKACCOUNT: {
    title: "Legal Entity Bank Account",
    url: "/bank-accounts",
    shortCode: "bank-accounts",
    icon: "account_balance",
    order: 2,
    type: "entity",
    systemRoleName: "LEBANKACCOUNT",
    roleURL: "/api/legal_entity_bank_account/",
    client: "authenticatedClient",
    moduleFolder: "BankAccounts",
  },
  PERSONMANAGEMENT: {
    title: "Person Management",
    url: "/person-management",
    shortCode: "person-management",
    icon: "perm_contact_calendar",
    order: 3,
    type: "entity",
    disabled: false,
    systemRoleName: "PERSONMANAGEMENT",
    roleURL: "/api/person/",
    client: "authenticatedClient",
    moduleFolder: "PersonManagement",
    disabledFunctionality: [
      "CUSTOMER_ACTIVE_JOURNEYS",
      "REGENERATE_LEGAL_DOCUMENTS",
      "ADDRESS_FINDER",
    ],
  },
  PROFILEMANAGEMENT: {
    title: "Profiles",
    url: "/profiles",
    shortCode: "profiles",
    icon: "folder_shared",
    order: 4,
    type: "entity",
    disabled: false,
    systemRoleName: "PROFILEMANAGEMENT",
    roleURL: "/api/profile/",
    client: "authenticatedClient",
    moduleFolder: "Profiles",
  },
  OUTBOUNDPAYMENTTRANSACTIONMANAGEMENT: {
    title: "Outbound Payment Transactions",
    url: "/outbound-payment-transactions",
    shortCode: "outbound-payment-transactions",
    icon: "file_download",
    order: 5,
    type: "entity",
    disabled: false,
    systemRoleName: "OUTBOUNDPAYMENTTRANSACTIONMANAGEMENT",
    roleURL: "/api/outbound_payment_transaction_management/",
    client: "authenticatedClient",
    moduleFolder: "OutboundPaymentTransactions",
  },
  // INBOUNDPAYMENTTRANSACTIONMANAGEMENT: {
  //   title: 'Inbound payment transactions',
  //   url: '/inbound-payment-transactions',
  //   shortCode: 'inbound-payment-transactions',
  //   icon: 'file_upload',
  //   order: 6,
  //   type: 'entity',
  //   disabled: false,
  //   systemRoleName: 'INBOUNDPAYMENTTRANSACTIONMANAGEMENT',
  //   roleURL: '/api/inbound_payment_transaction_management/',
  //   client: 'authenticatedClient',
  //   moduleFolder: 'InboundPaymentTransactions',
  // },
  TESTOUTBOUNDPAYMENT: {
    title: "Test Outbound Payment",
    url: "/test-outbound-payment",
    shortCode: "test-outbound-payment",
    icon: "zoom_out_map",
    order: 7,
    type: "entity",
    disabled: false,
    systemRoleName: "TESTOUTBOUNDPAYMENT",
    roleURL: "/api/test_outbound_payment/",
    client: "authenticatedClient",
    moduleFolder: "TestOutboundPayment",
  },
  HOLIDAYCALENDAR: {
    title: "Holiday Calendar",
    url: "/holiday-calendar",
    shortCode: "holiday-calendar",
    icon: "today",
    order: 8,
    type: "entity",
    disabled: false,
    systemRoleName: "HOLIDAYCALENDAR",
    roleURL: "/api/holiday_calendar/",
    client: "authenticatedClient",
    moduleFolder: "HolidayCalendar",
  },
  // HOe roles
  LOANFUNDINGBUCKETMANAGEMENT: {
    title: "Loan Funding Buckets",
    url: "/funding-buckets",
    shortCode: "funding-buckets",
    icon: "opacity",
    order: 1,
    type: "host",
    disabled: false,
    systemRoleName: "LOANFUNDINGBUCKETMANAGEMENT",
    roleURL: "/api/loan_funding_bucket/",
    client: "authenticatedClient",
    disabledForHost: [...REACT_APP_LENDING_DISABLED_HO],
    moduleFolder: "FundingBuckets",
  },
  PORTFOLIOMANAGEMENT: {
    title: "Portfolios",
    url: "/portfolios",
    shortCode: "portfolios",
    icon: "chrome_reader_mode",
    order: 2,
    type: "host",
    disabled: false,
    systemRoleName: "PORTFOLIOMANAGEMENT",
    roleURL: "/api/portfolio/",
    client: "authenticatedClient",
    disabledForHost: [],
    moduleFolder: "Portfolios",
  },
  LOANPRODUCTMANAGEMENT: {
    title: "Loan Products",
    url: "/loan-products",
    shortCode: "loan-products",
    icon: "view_carousel",
    order: 3,
    type: "host",
    disabled: false,
    systemRoleName: "LOANPRODUCTMANAGEMENT",
    roleURL: "/api/loan_product/",
    client: "authenticatedClient",
    disabledForHost: [...REACT_APP_LENDING_DISABLED_HO],
    moduleFolder: "LoanProducts",
  },
  LOANMANAGEMENT: {
    title: "Loan Management",
    url: "/loan-management",
    shortCode: "loan-management",
    icon: "account_balance_wallet",
    order: 4,
    type: "host",
    disabled: false,
    systemRoleName: "LOANMANAGEMENT",
    client: "authenticatedClient",
    disabledForHost: [...REACT_APP_LENDING_DISABLED_HO],
    moduleFolder: "LoanManagement",
    disabledFunctionality: ["LEGAL_DOCUMENTS", "CARD_PAYMENTS"],
  },
  FRAUDREFERS: {
    title: "Fraud Refers",
    url: "/referrals",
    shortCode: "referrals",
    icon: "add_alert",
    order: 7,
    type: "host",
    disabled: true,
    systemRoleName: "FRAUDREFERS",
    roleURL: "/api/fraud_refer/",
    client: "authenticatedClient",
    disabledForHost: [],
    moduleFolder: "Referrals",
  },
  CREDITDECLINEOVERTURN: {
    title: "Credit Decline Overturns",
    url: "/credit-decline-overturn",
    shortCode: "credit-decline-overturn",
    icon: "undo",
    order: 8,
    type: "host",
    disabled: false,
    systemRoleName: "CREDITDECLINEOVERTURN",
    disabledForHost: [...REACT_APP_LENDING_DISABLED_HO],
    moduleFolder: "CreditDeclineOverturn",
  },
};

const holeConfig = {
  fronted: {
    customCss: {
      backgroundColor: "#162C45",
    },
  },
};

export { rolesList, holeConfig };
