import {
  LOAD_ALL,
  LOAD_FORM_DATA,
  LOAD_ONE,
  UPDATE_ONE,
  CREATE,
} from 'Store/Constants/generic';
import {
  PENDING,
  SUCCESS,
  FAIL,
} from 'Store/Constants/axios';
import { BankAccountStateType } from 'Types/bankAccounts';

import { rolesList } from 'GlobalConfigs';
import { Actions } from 'Store/Actions';

export const initialState = {
  all: [],
  selectedBankAccount: {},
  loadingBankAccountData: false,
  sendingBankAccountData: false,
  message: '',
  dynamicData: {},
  loadingDynamicData: false,
};

let actionsMap = {};

if (rolesList.LEBANKACCOUNT && rolesList.LEBANKACCOUNT.systemRoleName) {
  const bankAccountsActionsMap = {
    // TODO => make handler keys declarative
    // TODO => investigate how to declare actions dispatched by axios middleware
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_ALL }${ PENDING }`]: (state: BankAccountStateType) => ({
      ...state,
      all: [],
      loadingBankAccountData: true,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_ALL }${ SUCCESS }`]: (state: BankAccountStateType, { payload: { data } }: any) => ({
      ...state,
      all: data,
      loadingBankAccountData: false,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_ALL }${ FAIL }`]: (state: BankAccountStateType) => ({
      ...state,
      all: [],
      loadingBankAccountData: false,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_ONE }${ PENDING }`]: (state: BankAccountStateType) => ({
      ...state,
      selectedBankAccount: {},
      loadingBankAccountData: true,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_ONE }${ SUCCESS }`]: (state: BankAccountStateType, { payload: { data } }: any) => ({
      ...state,
      selectedBankAccount: data,
      loadingBankAccountData: false,
      message: '',
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_ONE }${ FAIL }`]: (state: BankAccountStateType) => ({
      ...state,
      selectedBankAccount: {},
      loadingBankAccountData: false,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ CREATE }${ PENDING }`]: (state: BankAccountStateType) => ({
      ...state,
      sendingBankAccountData: true,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ CREATE }${ SUCCESS }`]: (state: BankAccountStateType) => ({
      ...state,
      sendingBankAccountData: false,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ CREATE }${ FAIL }`]: (state: BankAccountStateType) => ({
      ...state,
      sendingBankAccountData: false,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ UPDATE_ONE }${ PENDING }`]: (state: BankAccountStateType) => ({
      ...state,
      sendingBankAccountData: true,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ UPDATE_ONE }${ SUCCESS }`]: (state: BankAccountStateType) => ({
      ...state,
      sendingBankAccountData: false,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ UPDATE_ONE }${ FAIL }`]: (state: BankAccountStateType) => ({
      ...state,
      sendingBankAccountData: false,
    }),
  };


  const formDataActionMap = {
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_FORM_DATA }${ PENDING }`]: (state: BankAccountStateType) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_FORM_DATA }${ SUCCESS }`]: (state: BankAccountStateType, { payload: { data } }: any) => ({
      ...state,
      dynamicData: {
        ...(data.actions || {}).POST,
      },
      loadingDynamicData: false,
    }),
    [`${ rolesList.LEBANKACCOUNT.systemRoleName }${ LOAD_FORM_DATA }${ FAIL }`]: (state: BankAccountStateType) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
      },
      loadingDynamicData: false,
    }),
  };

  actionsMap = {
    ...bankAccountsActionsMap,
    ...formDataActionMap,
  };
}

export default function reducer(state: BankAccountStateType = initialState, action: Actions): BankAccountStateType {
  const fn: (state: BankAccountStateType, action: Actions) => BankAccountStateType = actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
