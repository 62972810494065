//@flow
import {Actions} from "Store/Actions";
import moment from 'moment';

import {
    LOAN_MANAGEMENT_SEARCH_LOAN,
    LOAN_MANAGEMENT_LOAD_LOAN_SUMMARY,
    LOAN_MANAGEMENT_LOAD_PENDING_APPROVAL,
    LOAN_MANAGEMENT_ENQUIRE,
    LOAN_MANAGEMENT_LOAD_LOAN_CASHFLOWS,
    LOAN_MANAGEMENT_LOAD_LOAN_FLAGS,
    LOAN_MANAGEMENT_LOAD_LOAN_EVENTS,
    LOAN_MANAGEMENT_LOAD_LOAN_VERSIONS,
    LOAN_MANAGEMENT_LOAD_LOAN_MANDATES,
    LOAN_MANAGEMENT_LOAD_LOAN_LOGS,
    LOAN_MANAGEMENT_LOAD_LOAN_GL_DATA,
    LOAN_MANAGEMENT_LOAD_LOAN_LEGAL_DOCUMENTS,
    LOAN_MANAGEMENT_ACT,
    LOAN_MANAGEMENT_DRY_RUN,
    LOAN_MANAGEMENT_DRY_RUN_PLATFORM_API,
    LOAN_MANAGEMENT_LOAD_WP_KEY,
    LOAN_MANAGEMENT_CARD_PAYMENTS,
    LOAN_MANAGEMENT_CARD_PAYMENTS_REFRESH_RESULTS,
    LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE,
    LOAN_MANAGEMENT_GET_REFINANCE_DYNAMIC_DATA,
    LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS,
    LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE,
} from 'Store/Constants/loanManagement';

import { APPLICATION_UPDATE_ACTIVE_HOLE } from 'Store/Constants/application';

import {PENDING, SUCCESS, FAIL} from 'Store/Constants/axios';

type LoanManagementState = {
    loadingSearchResults: boolean;
    allPendingApproval: any[];
    loadingAllPendingApproval: boolean;
    selectedLoan: Record<string, unknown>;
    loadingSelectedLoan: boolean;
    selectedLoanGlData: Record<string, unknown>;
    loadingSelectedLoanGlData: boolean;
    selectedLoanDocuments: any[];
    loadingSelectedLoanDocuments: boolean;
    availableEvents: any[];
    selectedLoanCashFlows: any[];
    loadingSelectedLoanCashFlows: boolean;
    selectedLoanFlags: any[];
    loadingSelectedLoanFlags: boolean;
    selectedLoanEvents: any[];
    loadingSelectedLoanEvents: boolean;
    selectedLoanVersions: any[];
    loadingSelectedLoanVersions: boolean;
    selectedLoanMandates: any[];
    loadingSelectedLoanMandates: boolean;
    selectedLoanLogs: any[];
    loadingSelectedLoanLogs: boolean;

    loadingSelectedLoanInformation: boolean;
    enquireResults: Record<string, unknown>;
    loadingEnquireResults: boolean;
    proposedChanges: any[];
    actResults: Record<string, unknown>;
    sendingAct: boolean;
    dryRunResults: Record<string, unknown>;
    cardPaymentClientKey: string;
    cardPaymentSiteCode: string;
    loadingDryResults: boolean;
    loadingCardPayments: boolean;

    refinanceTool: {
        dynamicData: Record<string, unknown>;
        loadingDynamicData: boolean;
        refinanceDeals: any[];
        availableProducts: any[];
        loading: boolean;
    };
};

const loanProcessor = (loanObject: Record<string, any>) => {
    if (!loanObject) return {};
    const {loan_id, loan_status_application_code, current_live_version, loan_summary} = loanObject;
    return {
        loanId: loan_id,
        loanStatusCode: loan_status_application_code,
        current_live_version,
        fatObject: loanObject,
        loan_summary
    };
};

export const initialState: LoanManagementState = {
    loadingSearchResults: false,
    allPendingApproval: [],
    loadingAllPendingApproval: false,
    selectedLoan: {},
    loadingSelectedLoan: false,
    selectedLoanGlData: {},
    loadingSelectedLoanGlData: false,
    selectedLoanDocuments: [],
    loadingSelectedLoanDocuments: false,
    availableEvents: [],
    selectedLoanCashFlows: [],
    loadingSelectedLoanCashFlows: false,
    selectedLoanFlags: [],
    loadingSelectedLoanFlags: false,
    selectedLoanEvents: [],
    loadingSelectedLoanEvents: false,
    selectedLoanVersions: [],
    loadingSelectedLoanVersions: false,
    selectedLoanMandates: [],
    loadingSelectedLoanMandates: false,
    selectedLoanLogs: [],
    loadingSelectedLoanLogs: false,

    loadingSelectedLoanInformation: false,
    enquireResults: {},
    loadingEnquireResults: false,
    proposedChanges: [],
    actResults: {},
    sendingAct: false,
    dryRunResults: {},
    cardPaymentClientKey: "",
    cardPaymentSiteCode: "",
    loadingDryResults: false,
    loadingCardPayments: false,

    refinanceTool: {
        dynamicData: {},
        loadingDynamicData: false,
        refinanceDeals: [],
        availableProducts: [],
        loading: false,
    },
};

const loadSelectedLoanSummaryActionMap = {
    [LOAN_MANAGEMENT_LOAD_PENDING_APPROVAL + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        allPendingApproval: [],
        loadingAllPendingApproval: true,
    }),
    [LOAN_MANAGEMENT_LOAD_PENDING_APPROVAL + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        allPendingApproval: data || [],
        loadingAllPendingApproval: false,
    }),
    [LOAN_MANAGEMENT_LOAD_PENDING_APPROVAL + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingAllPendingApproval: false,
    }),
    // FIXME: Has this been addressed? Cannot find APP-5108 anything in Jira
    // TODO: remove from usage once APP-5108 is addressed, this really bad cross module function, but due to BE restrictions has to be done
    [APPLICATION_UPDATE_ACTIVE_HOLE]: (
        state: LoanManagementState
    ) => ({
        ...state,
        allPendingApproval: [],
        loadingAllPendingApproval: false,
    }),
    [LOAN_MANAGEMENT_SEARCH_LOAN + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoan: {},
        loadingSearchResults: true,
    }),
    [LOAN_MANAGEMENT_SEARCH_LOAN + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        selectedLoan: loanProcessor(data) || {},
        loadingSearchResults: false,
    }),
    [LOAN_MANAGEMENT_SEARCH_LOAN + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSearchResults: false,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_SUMMARY + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoan: {},
        enquireResults: {},
        proposedChanges: [],
        dryRunResults: [],
        selectedLoanCashFlows: [],
        selectedLoanEvents: [],
        selectedLoanVersions: [],
        selectedLoanMandates: [], //loaded separately in own component
        selectedLoanLogs: [],
        loadingSelectedLoan: true,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_SUMMARY + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        selectedLoan: loanProcessor(data) || {},
        availableEvents: (data || {}).allowed_events || [],
        loadingSelectedLoan: false,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_SUMMARY + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoan: false,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_GL_DATA + PENDING]:
        (
            state: LoanManagementState
        ) => ({
        ...state,
        selectedLoanGlData: {},
        loadingSelectedLoanGlData: true,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_GL_DATA + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        selectedLoanGlData: data || {},
        loadingSelectedLoanGlData: false,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_GL_DATA + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanGlData: false,
    }),
    // Legal Documents Tab
    [LOAN_MANAGEMENT_LOAD_LOAN_LEGAL_DOCUMENTS + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoanDocuments: {},
        loadingSelectedLoanDocuments: true,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_LEGAL_DOCUMENTS + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        selectedLoanDocuments: data || {},
        loadingSelectedLoanDocuments: false,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_LEGAL_DOCUMENTS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanDocuments: false,
        selectedLoanDocuments: [],
    }),
};

const eventManagementActionsMap = {
    [LOAN_MANAGEMENT_ENQUIRE + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        enquireResults: {},
        loadingEnquireResults: true,
        proposedChanges: [],
        dryRunResults: [],
    }),
    [LOAN_MANAGEMENT_ENQUIRE + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        enquireResults: data,
        loadingEnquireResults: false,
        proposedChanges: data.pending_events,
    }),
    [LOAN_MANAGEMENT_ENQUIRE + FAIL]: (
        state: LoanManagementState,
        {error: {response: {data}}}: any
    ) => ({
        ...state,
        loadingEnquireResults: false,
        enquireResults: data,
    }),
};

const loanInformationActionsMap = {
    [LOAN_MANAGEMENT_LOAD_LOAN_CASHFLOWS + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanCashFlows: true,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_CASHFLOWS + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => {
        // TODO: Abstract this out
        const cashflows =
            (data || [])
                .sort((l: any, r: any) => moment.utc(l.cashflow_due_datetime)
                .diff(moment.utc(r.cashflow_due_datetime)));
        return {
            ...state,
            selectedLoanCashFlows: [...cashflows],
            loadingSelectedLoanCashFlows: false,
        };
    },
    [LOAN_MANAGEMENT_LOAD_LOAN_CASHFLOWS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoanCashFlows: [],
        loadingSelectedLoanCashFlows: false,
    }),

    [LOAN_MANAGEMENT_LOAD_LOAN_FLAGS + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanFlags: true,
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_FLAGS + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => {
        return {
            ...state,
            selectedLoanFlags: data.loan_flags,
            loadingSelectedLoanFlags: false
        };
    },
    [LOAN_MANAGEMENT_LOAD_LOAN_FLAGS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoanFlags: [],
        loadingSelectedLoanFlags: false
    }),

    [LOAN_MANAGEMENT_LOAD_LOAN_EVENTS + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanEvents: true
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_EVENTS + SUCCESS]: (
        state: LoanManagementState, 
        {payload: {data}}: any
    ) => ({
        ...state,
        // TODO: Abstract this out
        selectedLoanEvents: data.sort((l: any, r: any) => l.loan_event_schedule_id - r.loan_event_schedule_id) || [],
        loadingSelectedLoanEvents: false
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_EVENTS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoanEvents: [],
        loadingSelectedLoanEvents: false
    }),

    [LOAN_MANAGEMENT_LOAD_LOAN_VERSIONS + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanVersions: true
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_VERSIONS + SUCCESS]: (
        state: LoanManagementState, 
        {payload: {data}}: any
    ) => ({
        ...state,
        // TODO: Abstract this out
        selectedLoanVersions: data.sort((l: any, r: any) => l.loan_version_id - r.loan_version_id) || [],
        loadingSelectedLoanVersions: false
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_VERSIONS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoanVersions: [],
        loadingSelectedLoanVersions: false
    }),

    [LOAN_MANAGEMENT_LOAD_LOAN_MANDATES + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanMandates: true
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_MANDATES + SUCCESS]: (
        state: LoanManagementState, 
        {payload: {data}}: any
    ) => ({
        ...state,
        selectedLoanMandates: data || [],
        loadingSelectedLoanMandates: false
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_MANDATES + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoanMandates: [],
        loadingSelectedLoanMandates: false
    }),

    [LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanMandates: true
    }),
    [LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE + SUCCESS]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanMandates: false
    }),
    [LOAN_MANAGEMENT_UPDATE_LOAN_MANDATE + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanMandates: false
    }),

    [LOAN_MANAGEMENT_LOAD_LOAN_LOGS + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingSelectedLoanLogs: true
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_LOGS + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        selectedLoanLogs: data || [],
        loadingSelectedLoanLogs: false
    }),
    [LOAN_MANAGEMENT_LOAD_LOAN_LOGS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        selectedLoanLogs: [],
        loadingSelectedLoanLogs: false
    }),
};

const eventActActionsMap = {
    [LOAN_MANAGEMENT_ACT + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        sendingAct: true,
        actResults: {}
    }),
    [LOAN_MANAGEMENT_ACT + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        sendingAct: false,
        enquireResults: {},
        proposedChanges: [],
        dryRunResults: [],
        // TODO: Rewrite this
        actResults: typeof data === 'object' && data !== null ? data : {}
    }),
    [LOAN_MANAGEMENT_ACT + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        sendingAct: false
    }),
    [LOAN_MANAGEMENT_DRY_RUN + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingDryResults: true
    }),
    [LOAN_MANAGEMENT_DRY_RUN + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        loadingDryResults: false,
        dryRunResults: (data || {}).active_event ? [(data || {}).active_event] : []
    }),
    [LOAN_MANAGEMENT_DRY_RUN + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingDryResults: false
    }),
    // Platform API dry run returns different payload which needs to be mapped to existing UI
    // Eventually this will become the only payload and we can update the consuming components at that stage
    [LOAN_MANAGEMENT_DRY_RUN_PLATFORM_API + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingDryResults: true
    }),
    [LOAN_MANAGEMENT_DRY_RUN_PLATFORM_API + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        loadingDryResults: false,
        dryRunResults: [{ event_changes: { ...data } }]
    }),
    [LOAN_MANAGEMENT_DRY_RUN_PLATFORM_API + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingDryResults: false
    }),
};

const customEventActActionsMap = {
    [LOAN_MANAGEMENT_GET_REFINANCE_DYNAMIC_DATA + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        refinanceTool: {
            ...state.refinanceTool,
            loadingDynamicData: true,
            dynamicData: {}
        },
    }),
    [LOAN_MANAGEMENT_GET_REFINANCE_DYNAMIC_DATA + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        refinanceTool: {
            ...state.refinanceTool,
            loadingDynamicData: false,
            dynamicData: {}
        },
    }),
    [LOAN_MANAGEMENT_GET_REFINANCE_DYNAMIC_DATA + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        refinanceTool: {
            ...state.refinanceTool,
            loadingDynamicData: false,
            dynamicData: data
        },
    }),
    [LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        refinanceTool: {
            ...state.refinanceTool,
            loading: true,
            refinanceDeals: [],
            availableProducts: []
        },
    }),
    [LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        refinanceTool: {
            ...state.refinanceTool,
            loading: false,
            refinanceDeals: data.loan_economics || [],
            availableProducts: data.available_products || [],
        },
        loadingRefinanceOptions: false,
        refinanceOptions: data.loan_economics || []
    }),
    [LOAN_MANAGEMENT_GET_REFINANCE_OPTIONS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        refinanceTool: {
            ...state.refinanceTool,
            loading: false,
            refinanceDeals: [],
            availableProducts: []
        }
    })
};

const cardPaymentActionMap = {
    [LOAN_MANAGEMENT_LOAD_WP_KEY + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingCardPayments: true,
        cardPaymentClientKey: '',
        cardPaymentSiteCode: ''
    }),
    [LOAN_MANAGEMENT_LOAD_WP_KEY + SUCCESS]: (
        state: LoanManagementState,
        {payload: {data}}: any
    ) => ({
        ...state,
        loadingCardPayments: false,
        cardPaymentClientKey: (data || {}).card_payment_client_key ? (data || {}).card_payment_client_key : '',
        cardPaymentSiteCode: (data || {}).site_code ? (data || {}).site_code : ''
    }),
    [LOAN_MANAGEMENT_LOAD_WP_KEY + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingCardPayments: false
    }),
    [LOAN_MANAGEMENT_CARD_PAYMENTS + PENDING]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingCardPayments: true
    }),
    [LOAN_MANAGEMENT_CARD_PAYMENTS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingCardPayments: false
    }),
    [LOAN_MANAGEMENT_CARD_PAYMENTS_REFRESH_RESULTS + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingCardPayments: false
    }),
    [LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE + SUCCESS]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingCardPayments: false
    }),
    [LOAN_MANAGEMENT_CARD_PAYMENTS_FINALISE + FAIL]: (
        state: LoanManagementState
    ) => ({
        ...state,
        loadingCardPayments: false
    }),
};

const actionsMap = {
    ...loadSelectedLoanSummaryActionMap,
    ...eventManagementActionsMap,
    ...loanInformationActionsMap,
    ...eventActActionsMap,
    ...customEventActActionsMap,
    ...cardPaymentActionMap
};

export default function reducer(
    state: LoanManagementState = initialState,
    action: Actions
) {
    const fn = actionsMap[action.type];
    return fn ? fn(state, action) : state;
}
