//@flow
import {
  LOANFUNDINGBUCKETTRANSACTION_CREATE,
  LOANFUNDINGBUCKETTRANSACTION_LOAD,
} from 'Store/Constants/fundingBuckets';

export const loadFundingBucketsTransaction = (fundingBucketId: number | string) => ({
  type: LOANFUNDINGBUCKETTRANSACTION_LOAD,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'GET',
      url: '/api/loan_funding_bucket_transactions/',
      params: {
        loan_funding_bucket_id: fundingBucketId,
      },
    },
  },
});

export const createFundingBucketsTransaction = (data: Object) => ({
  type: LOANFUNDINGBUCKETTRANSACTION_CREATE,
  payload: {
    client: 'authenticatedClient',
    request: {
      method: 'POST',
      url: '/api/loan_funding_bucket_transactions/',
      data: {
        ...data,
      },
    },
  },
});
