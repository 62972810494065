import {
  LOAD_ALL,
  LOAD_FORM_DATA,
  LOAD_ONE,
  UPDATE_ONE,
  CREATE,
} from "Store/Constants/generic";
import {
  EMPLOYEE_PROFILES_LOAD_AVAILABLE,
  EMPLOYEE_PROFILES_ASSIGN,
  EMPLOYEE_PROFILES_UNASSIGN,
} from "Store/Constants/employees";
import {PENDING, SUCCESS, FAIL} from "Store/Constants/axios";
import {EmployeeStateType} from "Types/employees";
import {rolesList} from "GlobalConfigs";
import {Actions} from "Store/Actions";

export const initialState: EmployeeStateType = {
  all: [],
  selectedEmployee: {},
  loadingEmployeeData: false,
  sendingEmployeeData: false,
  message: "",
  dynamicData: {},
  loadingDynamicData: false,
  availableProfiles: [],
  loadingAvailableProfiles: false,
};

let actionsMap = {};

if (
  rolesList.EMPLOYEEMANAGEMENT &&
  rolesList.EMPLOYEEMANAGEMENT.systemRoleName
) {
  const employeesActionsMap = {
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_ALL}${PENDING}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      all: [],
      loadingEmployeeData: true,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_ALL}${SUCCESS}`]: (
      state: EmployeeStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      all: data || [],
      loadingEmployeeData: false,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_ALL}${FAIL}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      all: [],
      loadingEmployeeData: false,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_ONE}${PENDING}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      selectedEmployee: {},
      loadingEmployeeData: true,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_ONE}${SUCCESS}`]: (
      state: EmployeeStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      selectedEmployee: data || {},
      loadingEmployeeData: false,
      message: "",
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_ONE}${FAIL}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      selectedEmployee: {},
      loadingEmployeeData: false,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${CREATE}${PENDING}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      loadingEmployeeData: true,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${CREATE}${SUCCESS}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      sendingEmployeeData: false,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${CREATE}${FAIL}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      sendingEmployeeData: false,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${UPDATE_ONE}${PENDING}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      sendingEmployeeData: true,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${UPDATE_ONE}${SUCCESS}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      sendingEmployeeData: false,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${UPDATE_ONE}${FAIL}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      sendingEmployeeData: false,
    }),
  };

  const formDataActionMap = {
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${PENDING}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      loadingDynamicData: true,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${SUCCESS}`]: (
      state: EmployeeStateType,
      {payload}: any
    ) => ({
      ...state,
      dynamicData: {
        ...state.dynamicData,
        ...(payload || {}).data,
      },
      loadingDynamicData: false,
    }),
    [`${rolesList.EMPLOYEEMANAGEMENT.systemRoleName}${LOAD_FORM_DATA}${FAIL}`]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      loadingDynamicData: false,
    }),
  };

  const profileAssignmentActionMap = {
    [EMPLOYEE_PROFILES_LOAD_AVAILABLE + PENDING]: (
      state: EmployeeStateType
    ) => ({
      ...state,
      availableProfiles: [],
      loadingAvailableProfiles: true,
    }),
    [EMPLOYEE_PROFILES_LOAD_AVAILABLE + SUCCESS]: (
      state: EmployeeStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      availableProfiles: data,
      loadingAvailableProfiles: false,
    }),
    [EMPLOYEE_PROFILES_LOAD_AVAILABLE + FAIL]: (state: EmployeeStateType) => ({
      ...state,
      loadingAvailableProfiles: false,
    }),
    [EMPLOYEE_PROFILES_ASSIGN + PENDING]: (state: EmployeeStateType) => ({
      ...state,
      loadingAvailableProfiles: true,
    }),
    [EMPLOYEE_PROFILES_ASSIGN + SUCCESS]: (
      state: EmployeeStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      availableProfiles: data,
      loadingAvailableProfiles: false,
    }),
    [EMPLOYEE_PROFILES_ASSIGN + FAIL]: (state: EmployeeStateType) => ({
      ...state,
      loadingAvailableProfiles: false,
    }),

    [EMPLOYEE_PROFILES_UNASSIGN + PENDING]: (state: EmployeeStateType) => ({
      ...state,
      loadingAvailableProfiles: true,
    }),
    [EMPLOYEE_PROFILES_UNASSIGN + SUCCESS]: (
      state: EmployeeStateType,
      {payload: {data}}: any
    ) => ({
      ...state,
      availableProfiles: data,
      loadingAvailableProfiles: false,
    }),
    [EMPLOYEE_PROFILES_UNASSIGN + FAIL]: (state: EmployeeStateType) => ({
      ...state,
      loadingAvailableProfiles: false,
    }),
  };

  actionsMap = {
    ...employeesActionsMap,
    ...formDataActionMap,
    ...profileAssignmentActionMap,
  };
}

export default function reducer(
  state: EmployeeStateType = initialState,
  action: Actions
) {
  const fn: (state: EmployeeStateType, action: Actions) => EmployeeStateType =
    actionsMap[action.type as keyof typeof actionsMap];
  return fn ? fn(state, action) : state;
}
