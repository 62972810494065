//@flow
export const IDENTITY_MANAGEMENT_LOAD_HISTORY = 'IDENTITY_MANAGEMENT_LOAD_HISTORY';
export const IDENTITY_MANAGEMENT_LOAD_IDENTITIES_ACTIVITY = 'IDENTITY_MANAGEMENT_LOAD_IDENTITIES_ACTIVITY';
export const IDENTITY_MANAGEMENT_LOAD_IDENTITIES = 'IDENTITY_MANAGEMENT_LOAD_IDENTITIES';
export const IDENTITY_MANAGEMENT_BLOCK_IDENTITY = 'IDENTITY_MANAGEMENT_BLOCK_IDENTITY';
export const IDENTITY_MANAGEMENT_UNBLOCK_IDENTITY = 'IDENTITY_MANAGEMENT_UNBLOCK_IDENTITY';
export const IDENTITY_MANAGEMENT_SET_IDENTITY_TO_ACT_ON = 'IDENTITY_MANAGEMENT_SET_IDENTITY_TO_ACT_ON';
export const IDENTITY_MANAGEMENT_UPDATE_HISTORY = 'IDENTITY_MANAGEMENT_UPDATE_HISTORY';
export const IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY = 'IDENTITY_MANAGEMENT_CREATE_NEW_IDENTITY';
export const IDENTITY_MANAGEMENT_LOAD_ACTIVITIES_HISTORY = 'IDENTITY_MANAGEMENT_LOAD_ACTIVITIES_HISTORY';
export const IDENTITY_MANAGEMENT_UPDATE_ACTIVITIES_HISTORY = 'IDENTITY_MANAGEMENT_UPDATE_ACTIVITIES_HISTORY';
export const IDENTITY_MANAGEMENT_LOAD_NEGATIVE_EVENTS = 'IDENTITY_MANAGEMENT_LOAD_NEGATIVE_EVENTS';
export const IDENTITY_MANAGEMENT_PERSON_SEARCH_RESULTS_DIALOG = 'IDENTITY_MANAGEMENT_PERSON_SEARCH_RESULTS_DIALOG';
export const IDENTITY_MANAGEMENT_IDENTITY_LINK_CANDIDATE = 'IDENTITY_MANAGEMENT_IDENTITY_LINK_CANDIDATE';
export const IDENTITY_MANAGEMENT_LINK_CANDIDATE_TO_IDENTITY = 'IDENTITY_MANAGEMENT_LINK_CANDIDATE_TO_IDENTITY';
export const IDENTITY_MANAGEMENT_PREPARE_IDENTITY_FOR_LINK = 'IDENTITY_MANAGEMENT_PREPARE_IDENTITY_FOR_LINK';
export const IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL = 'IDENTITY_MANAGEMENT_UPDATE_IDENTITY_EMAIL';
export const IDENTITY_MANAGEMENT_VALIDATE_LINK_CANDIDATE = 'IDENTITY_MANAGEMENT_VALIDATE_LINK_CANDIDATE';
export const IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_DELETE = 'IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_DELETE';
export const IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_SEARCH = 'IDENTITY_MANAGEMENT_ORPHAN_IDENTITY_SEARCH';
