import jwt_decode from 'jwt-decode'; //eslint-disable-line
import {
  formatEntity,
  formatHost,
} from 'Utils/dataProcessor';

export const canView = (permissionsArray, roleName) => {
  if (permissionsArray.length) {
    return !!(permissionsArray.filter((permission) => permission.role_id === roleName)[0] || {}).read;
  }

  return false;
};

export const canEdit = (permissionsArray, roleName) => {
  if (permissionsArray.length) {
    return !!(permissionsArray.filter((permission) => permission.role_id === roleName)[0] || {}).write;
  }

  return false;
};

/*
TODO: replace role_name with shortCode if it exists
permissionsArray = [
  {role_name: "Employees", view: true, edit: true},
  {role_name: "Profiles", view: true, edit: true},
  {role_name: "Legal entity bank account", view: true, edit: false},
  {role_name: "Loan funding buckets", view: true, edit: false},
  {role_name: "Portfolios", view: false, edit: false},
  {role_name: "Loan products", view: true, edit: true},
  {role_name: "Peronal management", view: true, edit: true},
  {role_name: "Referrals", view: false, edit: false},
  {role_name: "Decision system", view: true, edit: true}
]
 */

export const processJWTPermissions = (token) => {
  const permissionsArray = jwt_decode(token).permissions || [];
  const entityPermissions = {};

  const entities = [...new Set(permissionsArray.map(permission => permission[0]))];
  const roles = [...new Set(permissionsArray.map(permission => permission[2]))];

  let tempEntity = [];

  entities.forEach(entity => {
    tempEntity = [];

    roles.forEach(role => {
      permissionsArray.filter(permission => {
        return permission[0] === entity && permission[2] === role;
      }).forEach(item => {
        let newPerm;
        if (item[1] === 'R') {
          newPerm = {
            read: true,
            role_id: role,
          };
        }
        if (item[1] === 'W') {
          newPerm = {
            write: true,
            role_id: role,
          };
        }

        tempEntity[role] = {
          ...tempEntity[role],
          ...newPerm,
        };
      });
    });

    entityPermissions[entity] = Object.keys(tempEntity).map(key => tempEntity[key]);
  });

  return entityPermissions;
};

export const processJWTEntities = (token) => {
  const processedEntities = {
    entities: (jwt_decode(token).legal_entities || []).map(e => formatEntity(e)),
    hostOrganisations: (jwt_decode(token).host_organisations || []).map(h => formatHost(h)),
  };

  return processedEntities;
};

export const processJWTEmployee = (token) => {
  const employeeId = jwt_decode(token).employee_id;

  return employeeId;
};
