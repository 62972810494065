import React from "react";
import ReactDOM from "react-dom";
// Redux
import {Provider} from "react-redux";
import Footer from "Screens/App/Footer";
import configureStore from "Store/configureStore";
// Web Fonts for icons
import WebFontLoader from "webfontloader";
// App
import App from "./Screens/App";
// CSS import
import "react-toastify/dist/ReactToastify.css";
import "./scss/index.scss";
import {Router} from "react-router-dom";
import history from "RouterHistory";

// Webfonts
WebFontLoader.load({
  google: {
    families: ["Material Icons"],
  },
});

export const store = configureStore();

// Render it to DOM
const rootEl = document.getElementById("root");

ReactDOM.render(
  <Provider store={ store }>
    <Router history={ history }>
      <App />
    </Router>
    <Footer />
  </Provider>,
  rootEl
);
